import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    height: '24px',
    width: '24px',
    border: '1px solid #595959',
    'border-radius': '50%',
  },
}));

function CloseButton({ className, ...props }) {
  const classes = useStyles();

  return (
    <IconButton
      className={[className, classes.buttonWrapper].join(' ')}
      {...props}
    >
      <CloseIcon />
    </IconButton>
  );
}

CloseButton.defaultProps = {
  className: '',
};

export default CloseButton;
