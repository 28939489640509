import React from 'react';
import { get, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '../../../components/User';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '16px 0',
    backgroundColor: '#fff',
    minHeight: '50vh',
    borderTop:' 1px solid #C4C4C4'
  },
  headingText: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#C4C4C4',
    textAlign: 'left',
    margin: '0 0 20px',
  },
  listWrapper: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px',
  },
  collaboratorName: {
    paddingLeft: '10px',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#595959',

    '&.owner': {
      color: '#276EF1',
      fontWeight: '600'
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  status: {
    fontSize: '14px',
    lineHeight: '13px',
    color: '#276EF1',
    fontWeight: 600
  },
  avatar: {
    width: '30px',
    height: '30px'
  }
}));

function CollaboratorList({ collaborators, nonRegisteredUsers }) {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <ul className={classes.listWrapper}>
        {!isEmpty(collaborators) && collaborators.map((collaborator, index) => (
          <li className={classes.listItem} key={collaborator?.email + index}>
            <div className={classes.details}>
              <Avatar user={collaborator} className={classes.avatar} />
              <span
                className={[get(collaborator, 'projectOwner', '') ? 'owner' : '', classes.collaboratorName].join(' ')}
              >
                {`${get(collaborator, 'firstName', '')} ${get(collaborator, 'lastName', '')}`}{' '}
              </span>
            </div>
            {get(collaborator, 'projectOwner', '') && <span className={classes.status}>Project Owner</span>}
          </li>
        ))}
      </ul>
      <ul className={classes.listWrapper}>
        {!isEmpty(nonRegisteredUsers) && nonRegisteredUsers.map((collaborator, index) => (
          <li className={classes.listItem} key={collaborator?.email + index}>
            <div className={classes.details}>
              <Avatar user={collaborator} email={collaborator?.email} registered={false} className={classes.avatar} />
              <span
                className={`${classes.collaboratorName}`}
              >
                {get(collaborator, 'email')}{' '}
              </span>
            </div>
            <span className={classes.status}>Sent</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CollaboratorList;
