import { createSelector } from 'reselect';
import { get } from 'lodash';
import { REQUEST_STATUS } from '../../utility/constants';

export const selectUserSubstate = (state) => ({ ...get(state, 'user') });

export const selectUserData = createSelector(
  [selectUserSubstate],
  (substate) => ({ ...get(substate, 'data') })
);

export const selectUserProfileRaw = createSelector(
  [selectUserSubstate],
  (substate) => ({
    ...get(substate, 'profile'),
  })
);

export const selectUserProfile = createSelector(
  [selectUserProfileRaw, selectUserData],
  (rawProfile, userData) => ({
    ...rawProfile,
    email: userData.email,
  })
);

export const selectRequestStatus = (operation) =>
  createSelector([selectUserSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus')
      : {}
  );

export const selectIsLoading = (operation) =>
  createSelector([selectUserSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.status') === REQUEST_STATUS.PENDING
      : false
  );

export const selectIsRequestSuccessful = (operation) =>
  createSelector([selectUserSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.status') === REQUEST_STATUS.DONE
      : false
  );

export const selectOperationErrors = (operation) =>
  createSelector([selectUserSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.errors')
      : null
  );

export const selectLoginErrors = createSelector(
  [selectOperationErrors('login')],
  (operationErrors) => operationErrors
);

export const selectSignupErrors = createSelector(
  [selectOperationErrors('signup')],
  (operationErrors) => operationErrors
);

export const selectForgotPasswordErrors = createSelector(
  [selectOperationErrors('forgotPassword')],
  (operationErrors) => operationErrors
);

export const selectResetPasswordErrors = createSelector(
  [selectOperationErrors('resetPassword')],
  (operationErrors) => operationErrors
);

export const selectAuthenticationToken = createSelector(
  [selectUserData],
  (userData) => get(userData, 'authenticationToken')
);

export const selectIsUserAuthenticated = createSelector(
  [selectAuthenticationToken],
  (token) => !!token
);

export const selectApolloRequestAuthContext = createSelector(
  [selectAuthenticationToken],
  (token) =>
    !!token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}
);
