import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import Layout, { Header } from '../../components/Layout';
import Loading from '../../components/Loading/Loading';
import SomethingWentWrong from '../../components/Error/SomethingWentWrong';
import LaunchpadList from './components/LaunchpadList/LaunchpadList';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import { client, getProjects } from '../../api';
import classes from './launchpad.module.scss';

import { mockProjects } from '../../mocks/mocks';

const Launchpad = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  let parentTrackId = 0;
  if (pathname) {
    parentTrackId = pathname.charAt(11);
  }
  const [projects, setProjects] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    setLoaded(false);
    client()
      .query({
        query: getProjects(parentTrackId),
      })
      .then(response => {
        if (get(response, 'networkStatus', 7) === 8) {
          setErrorMessage('Error in fetching launchpad');
          setLoaded(true);
          setError(true);
          return;
        }
        setProjects(get(response, 'data.fetchProjects', []));
        setError(false);
      })
      .catch(e => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  }, []);

  useLayoutEffect(() => {
    setLoaded(true);
  }, [projects])

  let list = null;

  if (error) {
    list = (<SomethingWentWrong message={errorMessage} />);
  }

  if (!loaded) {
    list = (
      <Loading />
    );
  }

  if(projects?.length){
    list = (
      <LaunchpadList
        projects={projects}
        history={history}
      />
    );
  }

  return (
    <Layout>
      <div className={classes.lpPageWrapper}>
        <div className={classes.appHeader}>
          <div className={classes.appName}>
            Launchpad
          </div>
          <div className={classes.menuIcon}>
            <div onClick={e => {
              e.stopPropagation();
              history.goBack();
            }}>
              <CloseIcon />
            </div>
          </div>
        </div>
        {errorMessage === undefined && <div style={{paddingTop: 20}}>
          <div style={{ margin: '0 16px' }}>
          {/* <Button
            href="/launchpad/project/create"
            variant="contained" color='primary' className={classes.submitButton} size="large"
            startIcon={<AddIcon style={{ marginRight: '5px' }} />}
            style={{ color: '#fff' }}>
            Add Launchpad
          </Button> */}

            <Button 
              fullWidth 
              style={{
                backgroundColor: '#276EF1',
                color: '#FFFFFF',
                padding: '10px',
                textTransform: 'none'
                // borderRadius: '45px'
              }}
              onClick={() => history.push('/launchpad/project/create')}
              startIcon={<AddIcon style={{ marginRight: '5px' }} />}
            >
              Add Launchpad
            </Button>
            {/* <p className={classes.bannerText}>Conquer your next great breakthrough</p> */}
          </div>
        </div>}
        <div style={{paddingTop: 20}}>
          {list}
        </div>
      </div>
    </Layout>
  );
};


export default Launchpad;
