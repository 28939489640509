import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, TextField, CircularProgress } from '@material-ui/core';
import { get, pick } from 'lodash';
import Layout, { TitleHead } from '../../../components/Layout';
import Header from '../../Header';
import PasswordInput from '../../../components/Textbox/PasswordInput';
import CustomButton from '../../../components/Button/CustomButton';
import Toaster from '../../../components/Toaster/Toaster';
import MultiSelect from '../../../components/MultiSelect';
import JobFunctionCodes from '../../../utility/constants/jobFunctionCodes';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { client, updateProfileImage } from '../../../api';
import { toBase64 } from '../../../utility';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    minHeight: '100vh',
    backgroundColor: '#F0F0F0',
    // padding: '33px 16px',
  },
  headerSection: {
    textAlign: 'left',
    paddingBottom: '25px',
    backgroundColor: '#FFF',
    padding: '33px 16px 0 16px',
    '& h2': {
      fontWeight: '600',
      fontSize: '26px',
      lineHeight: '31px',
      color: '#276EF1',
      margin: 0,
    },
    '& p': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '16px',
      color: '#989898',
      margin: 0,
    },
  },
  formSection: {
    padding: '16px',
    backgroundColor: '#FFF'
  },
  formRow: {
    paddingBottom: '38px',
  },
  formSeparator: {
    borderColor: '#E4E4E4',
    borderStyle: 'solid',
    marginBottom: '34px',
  },
  changePasswordSection: {
    textAlign: 'left',
  },
  buttonRow: {
    padding: '0 16px 32px 16px',
    backgroundColor: '#FFF',
  },
  successToaster: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: '9999',
    textAlign: 'left',
    '& .toaster-close-btn': {
      right: '-25px !important',
    },
  },
  profilePicture: {
    boxSizing: 'border-box',
    border: '3px solid #F0F0F0',
    borderRadius: '50%',
    width: '100%',
    height: '100%'
  },
  profilePictureContainer: {
    marginBottom: '-60px',
    marginTop: '30px',
    position: 'relative'
  },
  uploader: {
    width: '40px',
    height: '40px',
    background: '#F1F2F6',
    border:' 2.4px solid #BCBCBC',
    borderRadius: '50%',
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarContainer: {
    margin: 'auto auto',
    width: '120px',
    height: '120px',
    position: 'relative'
  }
}));

function ProfileComponent({
  user,
  isLoading,
  profileFormErrors,
  shouldShowSuccessToaster,
  onCloseSuccessToaster,
  onSubmit,
  setAvatarProfile,
}) {
  const classes = useStyles();
  const [firstName, setFirstName] = useState(
    () => get(user, 'firstName') || ''
  );
  const [lastName, setLastName] = useState(() => get(user, 'lastName') || '');
  const [email, setEmail] = useState(() => get(user, 'email') || '');
  const [mobileNumber, setMobileNumber] = useState(
    () => get(user, 'mobileNumber') || ''
  );
  const [organization, setOrganization] = useState(
    () => get(user, 'organization') || ''
  );
  const [position, setPosition] = useState(() => get(user, 'position') || '');
  const [roles, setRoles] = useState(() => get(user, 'roles') || []);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [formErrors, setFormErrors] = useState({});
  const chunkSize = 64 * 1024 * 1024;
  const fileReader = new FileReader();
  let hasher = null;

  const removeErrorForField = (fieldName) => {
    if (get(formErrors, fieldName)) {
      const keysToPick = Object.keys(formErrors).filter((k) => k !== fieldName);
      setFormErrors(pick(formErrors, keysToPick));
    }
  };

  const isValidPhoneNumber = (value) => {
    const regex = new RegExp('^(0|[+91]{3})?[9-9][0-9]{9}$', 'gm');
    return regex.test(value);;
  }

  const isValidEmail = (value) => {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value)
  }

  useEffect(() => {
    setFormErrors(profileFormErrors);
  }, [profileFormErrors]);

  const uploadHandler = (file) => {
    client()
      .mutate({
        mutation: updateProfileImage(file),
      })
      .then(response => {
        console.log(response);
        const avatarUrl = get(response, 'data.attachProfileAvatar.profile.avatarUrl', '');
        avatarUrl && setAvatarProfile(avatarUrl);
      })
      .catch(e => {
        console.error(e);
      });
  }

  const onSubmitForm = () => {
    const params = {
      firstName,
      lastName,
      email,
      mobileNumber,
      organization,
      position,
      roles,
    };

    let isValid = true;

    if (!isValidPhoneNumber(mobileNumber)) {
      setFormErrors({
        ...formErrors,
        mobileNumber: 'Invalid phone number',
      });
      isValid = false;
    }


    if (!isValidEmail(email)) {
      setFormErrors({
        ...formErrors,
        email: 'Invalid email',
      });
      isValid = false;
    }

    if (!isValid) {
      return false;
    }

    if (!!password || !!confirmPassword) {
      if (password !== confirmPassword) {
        setFormErrors({
          ...formErrors,
          password: 'Must match confirm password',
          confirmPassword: 'Must match password',
        });
        return false;
      } else {
        removeErrorForField('password');
        removeErrorForField('confirmPassword');
        params.password = password;
      }
    }

    onSubmit(params);
  };

  const avatarImg = get(user, 'avatarUrl', {});

  return (
    <Layout>
      <Header />
      {shouldShowSuccessToaster && (
        <Toaster
          className={classes.successToaster}
          message="Account successfuly updated!"
          displayCloseButton
          autoCloseDuration={5000}
          onClose={onCloseSuccessToaster}
        />
      )}
      <div className={classes.mainWrapper}>
        <section className={classes.headerSection} style={{ borderTop: '1px solid #C4C4C4' }}>
          <h2>Account Information</h2>
          <p>Edit and save your profile</p>
        </section>
        <section className={classes.profilePictureContainer}>
          <div className={classes.avatarContainer}>
            <Avatar className={classes.profilePicture} src={avatarImg} />
            <div className={classes.uploader}>
              <label>
                <input 
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    const base64file = await toBase64(file);
                    await uploadHandler(base64file);
                  }}
                  style={{ display: 'none' }}
                  type="file" 
                />
                <CameraAltIcon style={{ color: '#276EF1' }} />
              </label>
            </div>
          </div>
        </section>
        <section className={classes.formSection} style={{ paddingTop: '100px' }}>
          <div className={classes.formRow}>
            <TextField
              value={firstName}
              error={!!get(formErrors, 'firstName')}
              helperText={get(formErrors, 'firstName')}
              id="profile-firstname-input"
              variant="outlined"
              label="First Name"
              disabled={isLoading}
              fullWidth
              onChange={(e) => {
                removeErrorForField('firstName');
                setFirstName(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              value={lastName}
              error={!!get(formErrors, 'lastName')}
              helperText={get(formErrors, 'lastName')}
              id="profile-lastname-input"
              variant="outlined"
              label="Last Name"
              disabled={isLoading}
              fullWidth
              onChange={(e) => {
                removeErrorForField('lastName');
                setLastName(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              value={email}
              error={!!get(formErrors, 'email')}
              helperText={get(formErrors, 'email')}
              id="profile-email-input"
              type="email"
              variant="outlined"
              label="Email address"
              disabled={isLoading}
              fullWidth
              onChange={(e) => {
                if (!isValidEmail(e?.target?.value)) {
                  setFormErrors({
                    ...formErrors,
                    email: 'Invalid email',
                  });
                } else {
                  removeErrorForField('email');
                }
                setEmail(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              value={mobileNumber}
              error={!!get(formErrors, 'mobileNumber')}
              helperText={get(formErrors, 'mobileNumber')}
              id="profile-mobilenumber-input"
              variant="outlined"
              label="Mobile Number"
              disabled={isLoading}
              fullWidth
              onChange={(e) => {
                if (!isValidPhoneNumber(e?.target?.value)) {
                  setFormErrors({
                    ...formErrors,
                    mobileNumber: 'Invalid phone number',
                  });
                } else {
                  removeErrorForField('mobileNumber');
                }
                setMobileNumber(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              value={organization}
              error={!!get(formErrors, 'organization')}
              helperText={get(formErrors, 'organization')}
              id="profile-organization-input"
              variant="outlined"
              label="Organization"
              disabled={isLoading}
              fullWidth
              onChange={(e) => {
                removeErrorForField('organization');
                setOrganization(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              value={position}
              error={!!get(formErrors, 'position')}
              helperText={get(formErrors, 'position')}
              id="profile-position-input"
              variant="outlined"
              label="Position"
              disabled={isLoading}
              fullWidth
              onChange={(e) => {
                removeErrorForField('position');
                setPosition(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <MultiSelect
              label="Roles"
              options={JobFunctionCodes}
              selectedItems={roles}
              onChange={(newRoles) => setRoles(newRoles.slice())}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
        </section>
        <section className={classes.headerSection} style={{ paddingTop: '0' }}>
          <div style={{ paddingTop: '32px', borderTop: '1px solid #C4C4C4' }}>
            <h2>Account Password</h2>
          </div>
          <p>Update you password regularly</p>
        </section>
        <section className={classes.formSection}>
          <div className={classes.formRow}>
            <PasswordInput
              value={password}
              error={!!get(formErrors, 'password')}
              helperText={get(formErrors, 'password')}
              id="profile-password-input"
              variant="outlined"
              label="Password"
              disabled={isLoading}
              onChange={(e) => {
                removeErrorForField('password');
                setPassword(e.target.value);
              }}
              autoComplete="new-password"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.formRow}>
            <PasswordInput
              value={confirmPassword}
              error={!!get(formErrors, 'confirmPassword')}
              helperText={get(formErrors, 'confirmPassword')}
              id="profile-confirm-password-input"
              variant="outlined"
              label="Confirm Password"
              disabled={isLoading}
              onChange={(e) => {
                removeErrorForField('confirmPassword');
                setConfirmPassword(e.target.value);
              }}
              autoComplete="new-password"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
        </section>
        <div className={classes.buttonRow}>
          <CustomButton
            className="primary"
            disabled={isLoading}
            fullWidth
            role="update-profile-button"
            onClick={onSubmitForm}
          >
            Save Changes
          </CustomButton>
        </div>
      </div>
    </Layout>
  );
}

ProfileComponent.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mobileNumber: PropTypes.string,
    organization: PropTypes.string,
    position: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  isLoading: PropTypes.bool,
  profileFormErrors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  shouldShowSuccessToaster: PropTypes.bool,
  onCloseSuccessToaster: PropTypes.func,
  setAvatarProfile: PropTypes.func,
};

export default ProfileComponent;
