import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import HurdleZero from './HurdleZero/HurdleZero';
import Hurdle from './Hurdle/Hurdle';
import PreWorkContainer from './SubStops/PreWork/PreWorkContainer';
import PreWorkoutContainer from './SubStops/PreWorkout/PreWorkoutContainer';
import WorkContainer from './SubStops/Work/WorkContainer';
import SoloWorkoutContainer from './SubStops/SoloWorkout/SoloWorkoutContainer';
import PeerExchangeContainer from './SubStops/PeerExchange/PeerExchangeContainer';
import HurdleConsensusContainer from './SubStops/HurdleConsensus/HurdleConsensusContainer';

import { selectParentTrack } from '../../store/selectors';

const Hurdles = ({ parent }) => {
  const parentTrack = parent && parent.find(p => p.isActive);
  const path = get(parentTrack, 'path', '/');
  const parentPath = path === '/' ? '/mission-control' : path;
  return (
    <div>
      <Switch>
        {/* <Route path="/hurdle-zero" component={HurdleZero} exact /> */}
        {/* <Route path="/hurdle/:routeId(\d+)" component={Hurdle} exact /> */}
        <Route path={`${parentPath}/:projectId/zones/:zoneId/hurdles/:hurdleId/substop/:substopId/prework`} component={PreWorkContainer} exact />
        <Route path={`${parentPath}/:projectId/zones/:zoneId/hurdles/:hurdleId/substop/:substopId/prework/work`} component={PreWorkoutContainer} exact />
        <Route path={`${parentPath}/:projectId/zones/:zoneId/hurdles/:hurdleId/substop/:substopId/solo_workout`} component={SoloWorkoutContainer} exact />
        <Route path={`${parentPath}/:projectId/zones/:zoneId/hurdles/:hurdleId/substop/:substopId/peer_exchange`} component={PeerExchangeContainer} exact />
        <Route path={`${parentPath}/:projectId/zones/:zoneId/hurdles/:hurdleId/substop/:substopId/hurdle_consensus`} component={HurdleConsensusContainer} exact />
        <Route
          path={`${parentPath}/:projectId/zones/:zoneId/hurdles/:hurdleId`}
          component={Hurdle}
          exact
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  parent: selectParentTrack,
});

export default connect(mapStateToProps)(Hurdles);
