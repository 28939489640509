import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import Launch from '@material-ui/icons/Launch';
import classes from './buttonsComponent.module.scss'


const useStyles = makeStyles({
  ul: {
    '& :first-child': {
      color: 'white',
    },
    '& .MuiPaginationItem-root': {
      color: "gray",
      background: "gray",
    },
    '& .Mui-selected': {
      color: 'rgb(182, 181, 181)',
      background: 'rgb(182, 181, 181)'
    }
  }
})


const ButtonsComponent = () => {

  const classesPagination = useStyles();

  return (
    <div className={classes.buttonsStylePage}>

      <div className={classes.buttonsStylePage__title}>
        <h1>Buttons Style Guide</h1>
        <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/buttons/" target="_blank">Buttons <Launch /></a></p>
      </div>

      <div className={classes.buttonsStylePage__container}>
        <div className={classes.buttonsStylePage__buttons1}>

          <Button variant="contained">Default</Button>
          <Button variant="contained" color="primary">Primary</Button>
          <Button variant="contained" color="secondary">Secondary</Button>
          <Button variant="contained" disabled>Disabled</Button>
          <Button variant="contained" color="primary" href="#">Link</Button>

          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<CloudUploadIcon />}
          >
            Upload
        </Button>

        </div>

        <div className={classes.buttonsStylePage__buttons2}>
          <div className={classes.buttonsStylePage__primaryButton}>
            <Button
              color='primary'
              variant="contained"
              className={classes.button}
              endIcon={<ArrowForwardIosIcon />}
            >
              Launch New Breakthrough
          </Button>
          </div>
          <div className={classes.buttonStylePage__pagination}>

            <Pagination
              classes={{ ul: classesPagination.ul }}
              count={3}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                />
              )}
            />

          </div>

        </div>

        <div className={classes.buttonStylePage__buttons3}>
          <div className={classes.buttons3__buttonSizes}>
            <Button variant="contained" size="small" color="primary" className={classes.margin}>
              Small
        </Button>
          &nbsp;
          <Button variant="contained" size="medium" color="primary" className={classes.margin}>
              Medium
        </Button>
        &nbsp;
          <Button variant="contained" size="large" color="primary" className={classes.margin}>
              Large
        </Button>
          </div>
          <div>
            <Button variant="contained" color="primary" className={classes.margin}>
              Next
        </Button>
        &nbsp;
          <Button variant="contained" color="primary" className={classes.margin}>
              Next
        </Button>
          </div>
        </div>

      </div>
    </div>

  );
};

export default ButtonsComponent;
