import { createReducer } from '@reduxjs/toolkit';
import { clearParent, setParent } from '../actions';

import { REQUEST_STATUS } from '../../utility/constants';

const initialState = {
  isLoading: false,
  requestStatus: {
    status: REQUEST_STATUS.NOT_STARTED,
    operation: '',
    errors: null,
  },
  data: [],
};

const parentTrackReducer = createReducer(initialState, (builder) => {
  builder.addCase(setParent, (state, action) => ({
    ...state,
    data: action.payload,
  }));
  builder.addCase(clearParent, () => initialState);
});

export default parentTrackReducer;
