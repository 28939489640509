import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// actions
import {
  fetchUserProfile,
  updateUserProfile,
  resetOperationStatus,
  setUserProfile,
} from '../../../store/actions/userActions';
// selectors
import {
  selectIsUserAuthenticated,
  selectUserProfile,
  selectIsRequestSuccessful,
  selectIsLoading,
} from '../../../store/selectors/userSelectors';

import ProfileComponent from './ProfileComponent';

function ProfileContainer({
  isUserAuthenticated,
  user,
  isSaveSuccess,
  isLoading,
  fetchUserProfile,
  updateUserProfile,
  resetOperationStatus,
  setUserProfile,
}) {
  const history = useHistory();

  useEffect(() => {
    if (!isUserAuthenticated) {
      history.push('/login');
    } else {
      fetchUserProfile();
    }
  }, [isUserAuthenticated, fetchUserProfile, history]);

  const onSubmitForm = (userData) => {
    updateUserProfile(userData);
  };

  const setAvatarProfile = (avatarUrl) => {
    const profile = {
      ...user,
      avatarUrl,
    };
    setUserProfile(profile);
  }

  return (
    <ProfileComponent
      user={user}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
      shouldShowSuccessToaster={isSaveSuccess}
      onCloseSuccessToaster={() => resetOperationStatus()}
      setAvatarProfile={setAvatarProfile}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  isUserAuthenticated: selectIsUserAuthenticated,
  user: selectUserProfile,
  isSaveSuccess: selectIsRequestSuccessful('updateProfile'),
  isLoading: selectIsLoading('updateProfile'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserProfile,
      updateUserProfile,
      resetOperationStatus,
      setUserProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
