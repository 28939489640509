import { gql, useQuery } from "@apollo/client";

const FETCH_PROJECT_DETAIL = gql`
  query fetchProjectOwner($projectId: ID!) {
    projectDetail: fetchProjectDetail(projectId: $projectId) {
      project {
        isProjectOwner
      }
      hurdleConsensus
      user {
        id
        projectOwner
        firstName
      }
    }
  }
`;

export default function useProjectDetails(projectId) {
  const { data, loading, error } = useQuery(FETCH_PROJECT_DETAIL, {
    variables: {
      projectId,
    },
  });

  return {
    projectDetails: data?.projectDetail,
    loading,
    error,
  };
}
