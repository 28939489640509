import { createReducer } from '@reduxjs/toolkit';
import { menuSetData, resetMenu } from '../actions/menuActions';

const initialState = {
  open: false,
};

const menuReducer = createReducer(initialState, (builder) => {
  builder.addCase(menuSetData, (state, action) => ({
    ...state,
    open: action.payload,
  }));
  builder.addCase(resetMenu, () => initialState);
});

export default menuReducer;
