import React from "react";
import { get, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MultiSelect from "../../../../../components/MultiSelect";

import classes from "./questionTypes.module.scss";

const useStyles = makeStyles({
  label: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "17px",
  },
});

const QuestionTypes = ({
  keyId,
  answer,
  answerOptions,
  label,
  placeHolder,
  questionId,
  questionType,
  fn,
  name,
  disabled,
  readOnly,
}) => {
  const styleClass = useStyles();
  const inputField = () => {
    switch (questionType) {
      case "Short Text": {
        return (
          <TextField
            fullWidth
            label={label}
            variant="outlined"
            placeholder={placeHolder}
            onChange={(e) => fn(e, questionId)}
            value={answer}
            name={name}
            disabled={disabled}
            inputProps={{ readOnly: readOnly }}
          />
        );
      }
      case "Long Text": {
        return (
          <TextField
            fullWidth
            label={label}
            variant="outlined"
            placeholder={placeHolder}
            multiline
            minRows={7}
            maxRows={7}
            value={answer}
            onChange={(e) => fn(e, questionId)}
            name={name}
            disabled={disabled}
            inputProps={{ readOnly: readOnly }}
          />
        );
      }
      case "Dropdown": {
        return (
          <Select
            value={answer}
            //label={label ? null : label}
            placeholder={placeHolder}
            fullWidth
            variant="outlined"
            onChange={(e) => fn(e, questionId)}
            inputlabelprops={{ shrink: false }}
            style={{ textAlign: `left` }}
            name={name}
            disabled={disabled}
            inputProps={{ readOnly: readOnly }}
          >
            {!isEmpty(answerOptions) &&
              answerOptions.map((menu, index) => {
                return (
                  <MenuItem key={uuidv4()} value={get(menu, "id", "")}>
                    {get(menu, "title", "")}
                  </MenuItem>
                );
              })}
          </Select>
        );
      }
      case "Modal Checkbox":
      case "Multi-select": {
        return (
          <MultiSelect
            label={label}
            options={answerOptions.map((opt) => ({
              key: get(opt, "id"),
              value: get(opt, "title"),
            }))}
            selectedItems={answer}
            onChange={(value) => {
              fn(value, questionId);
            }}
            name={name}
            disabled={disabled}
            inputProps={{ readOnly: readOnly }}
          />
        );
        // return (
        //   <Select
        //     value={answer}
        //     label={label}
        //     placeholder={placeHolder}
        //     fullWidth
        //     variant="outlined"
        //     multiple
        //     // onChange={e => fn(e, questionId)}
        //   >
        //     {(!isEmpty(answerOptions)) && answerOptions.map((menu, index) => {
        //       return (
        //         <div key={uuidv4()}>
        //           <FormControlLabel
        //             control={<Checkbox checked={answer} onChange={e => fn(e, questionId)} name={get(menu, 'title', '')} color="primary" />}
        //             label={get(menu, 'title', '')}
        //           />
        //         </div>
        //       );
        //     })}
        //   </Select>
        // );
      }
      case "Radio Buttons": {
        return (
          <FormControl
            component="fieldset"
            style={{ display: `flex`, textAlign: `left` }}
          >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              aria-label={label}
              name={name ?? label}
              value={answer}
              onChange={readOnly ? null : (e) => fn(e, questionId)}
            >
              {!isEmpty(answerOptions) &&
                answerOptions.map((menu, index) => {
                  const isSelected = menu.id === answer;
                  {
                    isSelected && (
                      <input
                        type="hidden"
                        name={name ?? get(menu, "title", "")}
                        value={menu?.id}
                      />
                    );
                  }
                  return (
                    <FormControlLabel
                      key={uuidv4()}
                      value={get(menu, "id", "")}
                      control={<Radio color="primary" />}
                      label={get(menu, "title", "")}
                      classes={{
                        label: styleClass.label,
                      }}
                      disabled={disabled}
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        );
      }
      case "Inline Checkbox": {
        return (
          <FormControl
            component="fieldset"
            style={{ display: `flex`, textAlign: `left` }}
          >
            <FormLabel component="legend">{label}</FormLabel>
            {!isEmpty(answer) &&
              answer?.map((menu, index) => {
                return (
                  <FormControlLabel
                    key={`ic${questionId}-answerOption-${menu?.id}`}
                    control={
                      <Checkbox
                        id={`ic${questionId}-answerOption-${menu?.id}`}
                        checked={menu?.selected}
                        onChange={readOnly ? null : (e) => fn(e, questionId)}
                        name={name ?? get(menu, "title", "")}
                        color="primary"
                        value={menu?.value}
                        disabled={disabled}
                      />
                    }
                    label={get(menu, "title", "")}
                    classes={{
                      label: styleClass.label,
                    }}
                  />
                );
              })}
          </FormControl>
        );
      }
      case "Horizontal Rate": {
        return (
          <div style={{ width: `100%`, padding: "0px 0px 50px" }}>
            <div style={{ display: `flex`, margin: `0 auto`, width: `100%` }}>
              {!isEmpty(answer) &&
                answer.map((menu, index) => (
                  <div
                    key={`hr${questionId}-answerOption-${menu?.id}`}
                    data-value={menu?.id}
                    onClick={(e) => fn(e, questionId)}
                    className={classes.horizontalRate}
                    style={{
                      backgroundColor: menu?.selected ? `#276EF1` : `#FFFFFF`,
                      pointerEvents: disabled || readOnly ? `none` : `auto`,
                    }}
                  >
                    {menu.selected && (
                      <input
                        type="hidden"
                        name={name ?? get(menu, "title", "")}
                        value={menu?.id}
                      />
                    )}
                    <div
                      className={classes.horizontalRateValue}
                      id={`hr${questionId}-answerOption-${menu?.id}`}
                      style={{
                        color: menu?.selected ? `#FFFFFF` : `#595959`,
                        padding: "3px 0px 0px",
                      }}
                    >
                      {get(menu, "title", "")}
                    </div>
                    {index === 0 ? (
                      <p style={{ color: "#595959" }}>
                        {get(menu, "description", "")}
                      </p>
                    ) : index === answer.length - 1 ? (
                      <p style={{ color: "#595959" }}>
                        {get(menu, "description", "")}
                      </p>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
        );
      }
      case "Read-only": {
        return <div dangerouslySetInnerHTML={{ __html: label }} />;
      }
      default: {
        return null;
      }
    }
  };

  return <div>{inputField()}</div>;
};

QuestionTypes.defaultProps = {
  answerOptions: [],
};

export default QuestionTypes;
