import { createAction } from '@reduxjs/toolkit';

export const clearProject = createAction('project/clear');
export const setProjectData = createAction(
  'project/setProjectData',
  (values) => {
    return {
      payload: values,
    };
  }
);

export const setOperationStatus = createAction(
  'project/setOperationStatus',
  (status, operation, errors) => ({
    payload: {
      status,
      operation,
      errors,
    },
  })
);

export const createProject = createAction('project/create_project');
export const updateProject = createAction(
  'project/update_project',
  (values) => {
    return {
      payload: values,
    };
  }
);
export const resetOperationStatus = createAction(
  'project/resetOperationStatus'
);

export const sendCollaboratorInvites = createAction(
  'project/send_collaborator_invites',
  (projectId, emails) => ({
    payload: {
      projectId,
      emails,
    },
  })
);

export const fetchCollaborators = createAction(
  'project/fetch_collaborators',
  (projectId) => ({
    payload: {
      projectId,
    },
  })
);

export const setProjectCollaborators = createAction(
  'project/set_project_collaborators',
  (collaborators) => ({
    payload: {
      collaborators,
    },
  })
);

export const setProjectNonRegisteredUsers = createAction(
  'project/set_project_nonregisteredusers',
  (nonRegisteredUsers) => ({
    payload: {
      nonRegisteredUsers,
    },
  })
);

export const setWorkData = createAction('setWorkData');
export const setSoloWorkoutData = createAction('setSoloWorkoutData');
export const setPeerExchangeData = createAction('setPeerExchangeData');
export const setHurdleConsensusData = createAction('setHurdleConsensusData');
export const setOpenSnackbar = createAction('setOpenSnackbar');
