import CustomButton from './CustomButton';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    border: '1px solid #276EF1',
    borderRadius: '8px',
    backgroundColor: 'white',
    height: '57px',
    color: '#276EF1',
  },
  icon: {
    transform: 'rotate(-45deg)',
    color: '#276EF1',
  },
}));

const CopyLinkButton = ({ className, children, ...props }) => {
  const classes = useStyles();
  return (
    <CustomButton className={[classes.button, className].join(' ')} {...props}>
      <LinkIcon className={classes.icon} />
      {children}
    </CustomButton>
  );
};

CopyLinkButton.defaultProps = {
  className: '',
};

export default CopyLinkButton;
