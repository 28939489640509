import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorIcon from '@material-ui/icons/Error';
import Launch from '@material-ui/icons/Launch';
import classes from './textfieldStateComponent.module.scss';


const TextFieldStateComponent = () => {

  const [value, setValue] = useState('');
  const [currency, setCurrency] = React.useState('EUR');
  let icon = <ErrorIcon style={{ color: 'red' }} />

  const handleChange = (event) => {
    console.log(event.target.value);
  };

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const currencies = [
    {
      value: 'USD',
      label: '$ (USD)',
    },
    {
      value: 'EUR',
      label: '€ (EUR)',
    },
    {
      value: 'BTC',
      label: '฿ (BTC)',
    },
    {
      value: 'JPY',
      label: '¥ (JPY)',
    },
  ];

  return (
    <div className={classes.textfieldStatePage}>
      <div className={classes.textfieldStatePage__title}>
        <h1>Text Fields - State</h1>
        <p className={classes.styleInstruction}>
          For usage, kindly refer to the Material UI{" "}
          <a
            href="https://material-ui.com/components/text-fields/"
            target="_blank"
          >
            Text Fields <Launch />
          </a>
        </p>
      </div>
      <div className={classes.textfieldStatePage__container}>
        <div className={classes.textfieldStatePage__section1}>
          <div className={classes.textfieldStatePage__section1__field1}>
            <h2>Normal</h2>
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={4}
              value={value}
              onChange={handleChange}
              variant="outlined"
              placeholder="Label"
            />
          </div>
          <div className={classes.textfieldStatePage__section1__field2}>
            <h2>Focus</h2>
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={4}
              value={value}
              onChange={handleChange}
              variant="outlined"
              label="Label name"
              helperText="Helper Text"
              autoFocus
            />
          </div>
          <div className={classes.textfieldStatePage__section1__field3}>
            <h2>Error</h2>
            <TextField
              error
              id="filled-error-helper-text"
              multiline
              maxRows={4}
              value="Text"
              onChange={handleChange}
              variant="outlined"
              label="Label name"
              helperText="Error Text"
              InputProps={{
                endAdornment: icon,
              }}
            />
          </div>
        </div>

        <div className={classes.textfieldStatePage__section2}>
          <div className={classes.textfieldStatePage__section2__field4}>
            <h2>Disabled</h2>
            <TextField
              id="outlined-multiline-flexible"
              multiline
              disabled
              maxRows={4}
              value={value}
              onChange={handleChange}
              variant="outlined"
              label="Label"
              placeholder="Label"
            />
          </div>
          <div className={classes.textfieldStatePage__section2__field5}>
            <h2>Normal</h2>
            <TextField
              id="outlined-select-currency"
              select
              label="Select Text"
              value={currency}
              onChange={handleChangeCurrency}
              helperText="Please select your currency"
              variant="outlined"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.textfieldStatePage__section2__field6}>
            <h2>Help Text</h2>
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={4}
              label="Label Name"
              value={value}
              onChange={handleChange}
              variant="outlined"
              placeholder="Text"
              helperText="Helper Text"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextFieldStateComponent;
