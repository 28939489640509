import { createReducer } from '@reduxjs/toolkit';
import {
  clearProject,
  setOperationStatus,
  setProjectData,
  resetOperationStatus,
  setWorkData,
  setSoloWorkoutData,
  setPeerExchangeData,
  setHurdleConsensusData,
  setProjectCollaborators,
  setOpenSnackbar,
  setProjectNonRegisteredUsers,
} from '../actions/projectActions';

import { REQUEST_STATUS } from '../../utility/constants';
import { CallToActionSharp } from '@material-ui/icons';

const initialState = {
  isLoading: false,
  snackbar: {
    subStop: '',
    switch: false
  },
  requestStatus: {
    status: REQUEST_STATUS.NOT_STARTED,
    operation: '',
    errors: null,
  },
  formData: {},
  collaborators: [],
  data: null,
  substops: [],
  workSubStopData: [],
  nonRegisteredUsers: [],
}

const projectReducer = createReducer(initialState, (builder) => {

  builder.addCase(setProjectData, (state, action) => ({
    ...state,
    data: { ...action.payload },
  }));
  builder.addCase(clearProject, () => initialState);
  builder.addCase(setOperationStatus, (state, action) => ({
    ...state,
    requestStatus: {
      status: action.payload.status,
      operation: action.payload.operation,
      errors: action.payload.errors || null,
    },
  }));
  builder.addCase(resetOperationStatus, (state, action) => ({
    ...state,
    requestStatus: { ...initialState.requestStatus },
  }));
  builder.addCase(setWorkData, (state, action) => ({
    ...state,
    workSubStopData: [...action.payload],
  }));

  builder.addCase(setSoloWorkoutData, (state, action) => ({

    ...state,
    substops: [...state.substops, ...action.payload],
  }));

  builder.addCase(setPeerExchangeData, (state, action) => ({
    ...state,
    substops: [...state.substops, ...action.payload],
  }));

  builder.addCase(setHurdleConsensusData, (state, action) => ({
    ...state,
    hurdleConsensusData: {
      ...action.payload
    },
  }));

  builder.addCase(setOpenSnackbar, (state, action) => (
    console.log(action.payload.snackbar), {
      ...state,
      snackbar: {
        ...state.snackbar.subStop = action.payload.snackbar.subStop,
        ...state.snackbar.switch = action.payload.snackbar.switch
      }
    }, console.log(state.snackbar.subStop, state.snackbar.switch)));

  builder.addCase(setProjectCollaborators, (state, action) => {
    return {
      ...state,
      collaborators: action.payload.collaborators.slice(),
    };
  });
  builder.addCase(setProjectNonRegisteredUsers, (state, action) => {
    return {
      ...state,
      nonRegisteredUsers: action.payload.nonRegisteredUsers,
    };
  });

});

export default projectReducer;
