import { gql } from '@apollo/client';

export const getCollaborators = (id) => gql`
  query {
    fetchCollabs(id: ${id}) {
      registeredUsers{
        id
        email
        firstName
        lastName
        projectOwner
        avatarUrl
      }
      nonRegisteredUsers{
        email
      }
    }
  }
`;

export const inviteCollaborators = (projectId, emails = []) => {
  const emailsStr = emails.map((email) => `"${email}"`).join(',');
  return gql`
    query{
      inviteCollabs(
        id: ${projectId}
        emails: [${emailsStr}]
      ) {
        id
        name
        description
        startDate
        registeredUsers {
          id
          email
          firstName
          lastName
          projectOwner
          avatarUrl
        }
        nonRegisteredUsers {
          email
        }
      }
    }
  `;
};
