import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { emphasize, withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import {
  selectWorkSubStopData,
  selectParentTrack,
} from '../../../../store/selectors';
import Chip from '@material-ui/core/Chip';
import Header from '../../../Header';
import { BackLink, NextLink } from '../../../../components/Links';
import Button from '@material-ui/core/Button';
import * as HurdlesQueries from '../../../../api/Hurdles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CustomButton from '../../../../components/Button/CustomButton';
import WorkContainer from '../Work/WorkContainer';
import classes from './preWork.module.scss';
import HurdleResource from '../../components/HurdleResource';

const hurdleMockData = {
  id: '1',
  name: 'Flight Path',
  caption: 'Intuition Map based on quick scan of Hurdles 1-8.',
};

const subStopMockData = {
  id: '2',
  name: 'Prework',
  caption: 'This is the caption for Prework',
};

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(104, 154, 245, 0.6);',
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const PreWorkComponent = ({
  parent,
  workSubStopData,
  projectId,
  zoneId,
  hurdleId,
  substopId,
  hurdleName,
  hurdleEyeBrow,
  subStopInfo,
}) => {
  const parentTrack = parent && parent.find((p) => p.isActive);
  const path = get(parentTrack, 'path', '/');
  const parentPath = path === '/' ? '/mission-control' : path;
  const isActive = workSubStopData.isActive;
  const [initialAnswer, setInitialAnswer] = useState('');
  const [radioValue, setRadioValue] = useState('');
  const [age, setAge] = useState(0);
  const hurdleResources = get(subStopInfo, 'hurdleResources', []);
  const hurdleData = {
    parentPath,
    projectId,
    zoneId,
    hurdleId,
    substopId,
  };

  return (
    <div className={classes.preWorkPageWrapper}>
      <div className={classes.preWorkPage__header}>
        <div className={classes.header__links}>
          <Header>
            <BackLink
              to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`}
            >
              Back
            </BackLink>
            {/* {isActive === true ? <NextLink to="/">Continue</NextLink> : null} */}
          </Header>
        </div>

        <div className={classes.header__breadcrumbs}>
          <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
            <StyledBreadcrumb
              component={Link}
              to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`}
              label={`${hurdleEyeBrow} | ${hurdleName}`}
              className={classes.breadcrumbs__firstDegree}
            />
            <StyledBreadcrumb
              component={Link}
              to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/prework`}
              label={`${get(subStopInfo, 'name')}`}
              className={classes.breadcrumbs__secondDegree}
            />
          </Breadcrumbs>
        </div>

        <div className={classes.header__title}>
          <h1>{`${get(subStopInfo, 'name')}`}</h1>
          <p>{`${get(subStopInfo, 'caption')}`}</p>
        </div>
      </div>

      <div className={classes.preWorkPage__content}>
        {hurdleResources.map(({ id, hurdleResourceType, ...otherData }) => (
          <HurdleResource
            key={id}
            type={hurdleResourceType}
            data={otherData}
            parentData={hurdleData}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  workSubStopData: selectWorkSubStopData,
  parent: selectParentTrack,
});

export default connect(mapStateToProps, null)(PreWorkComponent);
