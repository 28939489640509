import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { get, uniqBy } from 'lodash';
import { selectSoloWorkoutProjectData, selectSnackbar, selectParentTrack } from '../../../../store/selectors';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { setSoloWorkoutData, setOpenSnackbar } from '../../../../store/actions/projectActions';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../Header';
import { BackLink, NextLink } from '../../../../components/Links';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { emphasize, withStyles } from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Loading from '../../../../components/Loading/Loading';
import Section from '../../../Substops/components/Section/Section';
import classes from './soloWorkout.module.scss';
import SomethingWentWrong from '../../../../components/Error/SomethingWentWrong';

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(104, 154, 245, 0.6);',
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const ToolTipTheme = withStyles({
    tooltip: {
        color: "white",
        background: "#595959"
    }
})(Tooltip);

const SoloWorkoutComponent = ({
    parent,
    setSoloWorkoutData,
    projectDataFromSoloWorkout,
    snackbar,
    setOpenSnackbar,
    projectId,
    zoneId,
    hurdleId,
    substopId,
    hurdleName,
    hurdleBrow,
    subStopInfo,
    sortedQuestions,
    sortedAnswers,
    fn,
    notesFn,
    submitProjectDetails,
    skipPath,
    error,
    errorMessage,
}) => {
    const parentTrack = parent && parent.find(p => p.isActive);
    const path = get(parentTrack, 'path', '/');
    const parentPath = path === '/' ? '/mission-control' : path;
    const projectData = { ...projectDataFromSoloWorkout }
    const history = useHistory();
    const [openSB, setOpenSB] = useState(snackbar.switch);

    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    const handleClick = () => {
        submitProjectDetails();
    };

    return (

        <div div className={classes.soloWorkoutPageWrapper} >
            <div>
                <div className={classes.soloWorkoutPage__header}>
                    <div className={classes.header__links}>
                        <Header>
                            <BackLink to={
                              skipPath
                                ? skipPath
                                : `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`
                              }
                            >
                              Back
                            </BackLink>
                            {/* <NextLink to={'#'}>Continue</NextLink> */}
                        </Header>
                    </div>
                    {!error && (<>
                      <div className={classes.header__breadcrumbs}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                          <StyledBreadcrumb component={Link} to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`} label={`${hurdleBrow} | ${hurdleName}`} className={classes.breadcrumbs__firstDegree} />
                          <StyledBreadcrumb component={Link} to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/solo_workout`} label={`${get(subStopInfo, 'name')}`} className={classes.breadcrumbs__secondDegree} />
                        </Breadcrumbs>
                      </div>
                      <div className={classes.header__title}>
                        <h1>{`${get(subStopInfo, 'name', '')}`}</h1>
                        <p>{`${get(subStopInfo, 'caption', '')}`}</p>
                      </div>
                    </>)
                    }
                </div>

                <div className={classes.soloWorkoutPage__content} >
                    {!error ? (
                      <form onSubmit={handleFormSubmit}>
                        <div className={classes.questionnaire}>
                            {sortedQuestions.map((question, index) => {
                                const idKey = get(question, 'customSectionId', '');
                                return (
                                    <div key={`csId${idKey}`} style={{ backgroundColor: `#FFFFFF`, margin: `8px 0px` }}>
                                        <Section
                                            keyId={`csId${idKey}`}
                                            sortedQuestions={question}
                                            sortedAnswers={sortedAnswers}
                                            fn={fn}
                                            notesFn={notesFn}
                                        />
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className={classes.submitButton__section}>
                            <div style={{ margin: `20px auto`, width: `90%`, paddingBottom: `20px` }}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    onClick={handleClick}
                                    variant="contained"
                                    color='primary'
                                    className={classes.submitButton}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                      </form>
                      ) : (<SomethingWentWrong message={errorMessage} link={false} />)
                    }
                </div>
            </div>
        </div >
    )
}

SoloWorkoutComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    handleAnswerChange: PropTypes.func,
    snackbar: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    projectDataFromSoloWorkout: selectSoloWorkoutProjectData,
    snackbar: selectSnackbar,
    parent: selectParentTrack,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setSoloWorkoutData,
            setOpenSnackbar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SoloWorkoutComponent)
