import { gql } from '@apollo/client';
import { get } from 'lodash';

export const fetchHurdleConsensus = (projectId, hurdleId) => gql`
  query {
    fetchHurdleConsensus (projectId: "${projectId}", hurdleId: "${hurdleId}") {
      id
      projectId
      name
      caption
      displayOrder
      duration
      substopType
      tierType
      status
      hurdleResources {
        id
        name
        hurdleResourceType
        copyTextArea
        mustReadUrl
        postcardUrl
        imageUrl
        tepUrl
        externalLink
        externalLinkButtonText
        youtubeUrl
        googleMeetUrl
        googleMeetButtonText
        questions {
          id
          title
          description
          displayOrder
          questionTypeId
          questionType
          allowNotes
          placeholder
          maxOptionSelection
          customSectionId
          clusterId
          customSectionDisplayOrder
          clusterDisplayOrder
          answer
          preferedAnswer
          hcAnswer
          subQuestions {
            id
            title
            description
            displayOrder
            questionTypeId
            questionType
            allowNotes
            placeholder
            status
            maxOptionSelection
            customSectionId
            clusterId
          }
          answerOptions {
            id
            title
            value
            description
            displayOrder
            status
          }
        }
      }
      questions {
        id
        title
        description
        displayOrder
        questionTypeId
        questionType
        allowNotes
        placeholder
        maxOptionSelection
        customSectionId
        clusterId
        customSectionDisplayOrder
        clusterDisplayOrder
        answer
        preferedAnswer
        hcAnswer
        subQuestions {
          id
          title
          description
          displayOrder
          questionTypeId
          questionType
          allowNotes
          placeholder
          status
          maxOptionSelection
          customSectionId
          clusterId
        }
        answerOptions {
          id
          title
          value
          description
          displayOrder
          status
        }
      }
    }
  }
`;

export const consensusProjectDetailFragment = gql`
  fragment projectDetail on SubmitHurdleConsensusPayload {
    projectDetail {
      id
      hurdleConsensus
      status
    }
  }
`;

export const postHurdleConsensusDetail = (params) => {
  const projectId = get(params, 'projectId', '');
  const substopId = get(params, 'substopId', '');
  const details = get(params, 'details', '');
  let formattedAttachments = '';
  details.map(item => {
    formattedAttachments += `{ question_id: "${item.question_id}", answer: """${item.answer}""", notes: """${item.notes}""" },`;
  });
  try {
    return gql`
      mutation {
        submitHurdleConsensus (
          input: {
            params: {
              projectId: "${projectId}",
              hurdleConsensusData: {
                substop_id: "${substopId}",
                details: [
                  ${formattedAttachments}
                ] 
              }
            }
          }
        ) {
          errors
          ...projectDetail
          success
        }
      }
      ${consensusProjectDetailFragment}
    `;
  } catch (e) {
    console.error(e);
  }
}