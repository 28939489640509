import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReplayIcon from '@material-ui/icons/Replay';

import { get } from 'lodash';
import classes from './SubStop.module.scss';

function ActiveSubstop({data}) {
  const score = `${get(data, 'earnedPoints', 0) || 0}/${get(data, 'totalPoints', 0) || 100}`;
  return (
    <div className={classes.activeSubStop}>
      {/* NOTE: Put back when BE is done */}
      {/* <ReplayIcon style={{ transform: 'scaleX(-1) rotate(-25deg)' }} /> */}
      {/* <span>Ongoing</span> */}
      {get(data, 'totalPoints', 0) !== 0 ? score : null}
    </div>
  );
}

function DoneSubStop() {
  return (
    <div className={classes.doneSubStop}>
      <CheckCircleOutlineIcon />
      <span>Done</span>
    </div>
  );
}

function NotStartedSubstop() {
  return (
    <div className={classes.notStartedSubStop}>
      <span>Get started</span>
    </div>
  );
}

function SubStopStatus({ value, data }) {
  switch (value) {
    case 'active':
      return <ActiveSubstop data={data} />;
    case 'completed':
      return <DoneSubStop />;
    default:
      return <NotStartedSubstop />;
  }
}

SubStopStatus.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object
};

export default SubStopStatus;
