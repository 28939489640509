import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { selectSnackbar } from '../../../../store/selectors/projectSelectors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Layout from '../../../../components/Layout';
import { client, fetchHurdle, fetchSubstop } from '../../../../api';
import PreWorkComponent from './PreWorkComponent';
import Loading from '../../../../components/Loading/Loading';
import { DataUsage } from '@material-ui/icons';

const PreWorkContainer = ({ openSnackbar }) => {
  let { projectId, zoneId, hurdleId, substopId } = useParams();
  //const [projectInfo, setProjectInfo] = useState({});
  const [hurdleEyeBrow, setHurdleEyeBrow] = useState('');
  const [hurdleName, setHurdleName] = useState('');
  const [subStopInfo, setSubStopInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [openSB, setOpenSB] = useState(openSnackbar.active);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const fetchHurdleDetail = (id, projectid) => {
    client()
      .query({
        query: fetchHurdle(id, projectid),
      })
      .then((result) => {
        const hurdle = get(result, 'data.fetchHurdle', null);
        const hurdleName = get(hurdle, 'name', null);
        const substop = get(hurdle, 'substops', []);
        const hurdleEyeBrow = get(hurdle, 'hurdleEyebrow', '');
        //   const substopId = substop.find()
        setHurdleName(hurdleName);
        setLoaded(true);
        setHurdleEyeBrow(hurdleEyeBrow);
      })
      .catch((e) => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  const fetchSubstopDetail = (id, projectid) => {
    client()
      .query({
        query: fetchSubstop(id, projectid),
      })
      .then((result) => {
        const subStopData = get(result, 'data.fetchSubstop', null);
        setSubStopInfo(subStopData);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  useEffect(() => {
    fetchHurdleDetail(hurdleId, projectId);
    fetchSubstopDetail(substopId, projectId);
  }, []);

  useLayoutEffect(() => {}, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSB(!openSnackbar.active);
  };

  return (
    <Layout>
      <Snackbar
        open={openSB}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          You have successfully submitted the form
        </Alert>
      </Snackbar>
      {loaded ? (
        <PreWorkComponent
          projectId={projectId}
          zoneId={zoneId}
          hurdleId={hurdleId}
          substopId={substopId}
          hurdleName={hurdleName}
          hurdleEyeBrow={hurdleEyeBrow}
          subStopInfo={subStopInfo}
        />
      ) : (
        <div style={{ marginTop: 50 }}>
          <Loading size="5rem" />
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  openSnackbar: selectSnackbar,
});

export default connect(mapStateToProps, null)(PreWorkContainer);
