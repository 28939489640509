import { useMutation, useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  ANSWER_TYPES,
  createPayload,
  FETCH_PROJECT_DETAIL_V2,
  SUBMIT_ANSWERS,
} from "./QueriesV2";
import { useController, useForm } from "react-hook-form";
import { QuestionTitle } from "./TrackView";
import { useLocation } from "react-router-dom";
import classes from "../../../Hurdles/SubStops/SoloWorkout/soloWorkout.module.scss";
import qClasses from "../../../Substops/components/Section/QuestionTypes/questionTypes.module.scss";
import { toast } from "react-toastify";

const DEBUG_MODE = false;

const QUESTION_TYPES = {
  KEYWORDS: "Keywords",
  INLINE_CHECKBOX: "Inline Checkbox",
  LONG_TEXT: "Long Text",
  SHORT_TEXT: "Short Text",
  DROPDOWN: "Dropdown",
  RADIO_BUTTONS: "Radio Buttons",
  HORIZONTAL_RATE: "Horizontal Rate",
  READ_ONLY: "Read-only",
};

const labelStyles = {
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 14,
  lineHeight: "17px",
};

function HorizontalOption({
  menu,
  title,
  isFirst,
  isLast,
  description,
  id,
  control,
  readOnly,
}) {
  const { field } = useController({ name: id, control });
  const htmlFor = `${menu.id}-option`;
  function handleChange(e) {
    field.onChange(e.target.value);
  }

  return (
    <Fragment>
      <input
        id={htmlFor}
        type="radio"
        className={qClasses.radiogroup__input}
        value={menu?.id}
        style={{
          visibility: "hidden",
        }}
        name={field.name}
        onChange={handleChange}
        onBlur={field.onBlur}
        checked={field.value === menu?.id}
      />
      <label
        htmlFor={htmlFor}
        data-value={menu?.id}
        className={qClasses.horizontalRate}
        style={readOnly ? { pointerEvents: "none" } : {}}
      >
        <div className={qClasses.horizontalRateValue}>{title}</div>
        {isFirst || isLast ? (
          <p style={{ color: "#595959" }}>{description}</p>
        ) : null}
      </label>
    </Fragment>
  );
}

function HorizontalRate({ options, control, id, readOnly }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        paddingTop: "1rem",
        paddingBottom: "6rem",
      }}
    >
      {options.map((menu, index) => (
        <HorizontalOption
          key={index}
          menu={menu}
          isFirst={index === 0}
          isLast={index === options.length - 1}
          description={menu.description}
          title={menu.title}
          id={id}
          control={control}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
}

function Dropdown({ options, control, id, placeholder, readOnly }) {
  const { field } = useController({ name: id, control });
  return (
    <Select
      fullWidth
      placeholder={placeholder}
      variant="outlined"
      inputlabelprops={{ shrink: false }}
      style={{ textAlign: "left" }}
      onChange={(e) => field.onChange(e.target.value)}
      onBlur={field.onBlur}
      name={field.name}
      value={field.value}
      inputProps={{ readOnly: readOnly }}
    >
      {options.map((menu, index) => {
        return (
          <MenuItem key={index} value={menu.id}>
            {menu.title}
          </MenuItem>
        );
      })}
    </Select>
  );
}

function RadioButtons({ label, options, control, id, readOnly }) {
  const { field } = useController({ name: id, control });
  return (
    <FormControl
      component="fieldset"
      style={{ display: `flex`, textAlign: `left` }}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={label}>
        {options.map((menu, index) => {
          return (
            <FormControlLabel
              key={index}
              onChange={(e) => field.onChange(e.target.value)}
              onBlur={field.onBlur}
              name={field.name}
              control={
                <Radio
                  color="primary"
                  value={menu.id}
                  checked={field.value === menu.id}
                />
              }
              label={menu.title}
              style={{
                ...labelStyles,
                ...(readOnly ? { pointerEvents: "none" } : {}),
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

function InlineCheckbox({ label, options, readOnly, id, control }) {
  const { field } = useController({ name: id, control });

  function handleChange(e) {
    const isPresent = field.value.includes(e.target.value);
    if (isPresent) {
      const newValue = field.value.filter((item) => item !== e.target.value);

      field.onChange(newValue);
    } else {
      field.onChange([...field.value, e.target.value]);
    }
  }

  return (
    <FormControl
      component="fieldset"
      style={{ display: `flex`, textAlign: `left` }}
    >
      <FormLabel component="legend">{label}</FormLabel>
      {options.map((o) => {
        return (
          <div
            key={o.value}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  type="checkbox"
                  color="primary"
                  checked={field.value.includes(o.value)}
                />
              }
              label={o.title}
              value={o.value}
              style={labelStyles}
              onChange={handleChange}
              onBlur={field.onBlur}
              name={field.name}
              style={readOnly ? { pointerEvents: "none" } : {}}
            />
          </div>
        );
      })}
    </FormControl>
  );
}

function QuestionV2({
  id,
  type,
  title,
  label,
  placeholder,
  description,
  options,
  register,
  control,
  readOnly,
}) {
  if (type.includes(QUESTION_TYPES.KEYWORDS)) {
    return null;
  }

  switch (type) {
    case QUESTION_TYPES.HORIZONTAL_RATE:
      return (
        <HorizontalRate
          options={options}
          id={id}
          control={control}
          readOnly={readOnly}
        />
      );
    case QUESTION_TYPES.INLINE_CHECKBOX:
      return (
        <InlineCheckbox
          label={description}
          options={options}
          id={id}
          control={control}
          readOnly={readOnly}
        />
      );
    case QUESTION_TYPES.RADIO_BUTTONS:
      return (
        <RadioButtons
          label={description}
          options={options}
          id={id}
          control={control}
          readOnly={readOnly}
        />
      );
    case QUESTION_TYPES.DROPDOWN:
      return (
        <Dropdown
          label={title}
          placeholder={placeholder}
          options={options}
          id={id}
          control={control}
          readOnly={readOnly}
        />
      );
    case QUESTION_TYPES.LONG_TEXT:
    case QUESTION_TYPES.SHORT_TEXT:
      const isShortText = type === QUESTION_TYPES.SHORT_TEXT;
      return (
        <TextField
          variant="outlined"
          {...register(id)}
          placeholder={placeholder}
          label={label}
          minRows={7}
          maxRows={7}
          multiline={!isShortText}
          style={{ width: "100%" }}
          inputProps={{ readOnly: readOnly }}
        />
      );
    case QUESTION_TYPES.READ_ONLY:
      return <div dangerouslySetInnerHTML={{ __html: description }} />;
    default:
      return <div style={{ color: "red", fontSize: "44px" }}>{type}</div>;
  }
}

function Notes({ register, id }) {
  return (
    <TextField
      variant="outlined"
      label="Notes"
      {...register(id)}
      minRows={7}
      maxRows={7}
      multiline
      style={{ width: "100%" }}
    />
  );
}

function loadAnswers(answers, questions) {
  const savedAnswers = answers ?? [];
  const values = questions.reduce((acc, q) => {
    const allowNotes = q.allowNotes;
    const savedAnswer = savedAnswers.find((a) => a.questionId === q.id) ?? {};
    const { value, notes } = savedAnswer;

    const initialValue = getInitialValue(q.questionType, value);
    return {
      ...acc,
      [q.id]: initialValue,
      ...(allowNotes ? { [q.id + "-notes"]: notes ?? "" } : {}),
    };
  }, {});
  return values;
}

function getInitialValue(type, value) {
  switch (type) {
    case QUESTION_TYPES.INLINE_CHECKBOX:
      const v = typeof value === "string" ? value.split(",") : value;
      return v ?? [];
    default:
      return value ?? "";
  }
}

function SoloWorkoutsV2({
  projectId,
  soloWorkouts,
  isConsensus,
  hurdleZone,
  isOwner,
}) {
  const questions = soloWorkouts.flatMap((s) => s.questions);
  const { pathname } = useLocation();
  const { data, loading } = useQuery(FETCH_PROJECT_DETAIL_V2, {
    variables: {
      projectId,
    },
    pollInterval: isOwner ? 0 : 5000,
  });

  const [submitAnswers, { loading: mutationLoading }] =
    useMutation(SUBMIT_ANSWERS);
  let answers = [];
  if (isConsensus) {
    answers = data?.project?.consensusAnswers ?? [];
  } else {
    answers = data?.project?.answers ?? [];
  }
  const values = loadAnswers(answers, questions);

  const { register, handleSubmit, control } = useForm({
    values,
  });

  const showCTA = (isOwner && isConsensus) || !isConsensus;

  async function onSubmit(values) {
    const answers = Object.keys(values)
      .filter((key) => !key.includes("-notes"))
      .map((key) => {
        const value = values[key];
        return {
          questionId: key,
          value: Array.isArray(value) ? value.join(",") : (value ?? ""),
          notes: values[key + "-notes"] ?? "",
        };
      });
    const res = await submitAnswers({
      variables: createPayload(
        projectId,
        isConsensus ? ANSWER_TYPES.HURDLE_CONSENSUS : ANSWER_TYPES.SOLO_WORKOUT,
        answers,
      ),
    });
    console.log(res);
    if (res.data.submitAnswers.success) {
      toast.success("Answers saved.");
    }
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      className={classes.questionnaire}
    >
      <h3>{hurdleZone.name}</h3>
      {questions
        .filter((q) => !q.questionType.includes(QUESTION_TYPES.KEYWORDS))
        .map((q) => (
          <Fragment key={q.id}>
            <QuestionTitle title={q.title} pathname={pathname} />
            {DEBUG_MODE && (
              <div style={{ color: "red", fontSize: "22px" }}>
                {q.id} {q.questionType}
              </div>
            )}
            <QuestionV2
              id={q.id}
              key={q.id}
              type={q.questionType}
              title={q.title}
              label={q.description}
              placeholder={q.placeholder}
              description={q.description}
              options={q.answerOptions}
              register={register}
              control={control}
              readOnly={!showCTA}
            />
            {q.allowNotes && (
              <Notes
                register={register}
                id={`${q.id}-notes`}
                readOnly={!showCTA}
              />
            )}
          </Fragment>
        ))}
      {showCTA ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "sticky",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            backgroundColor: "white",
            padding: "0.5rem",
          }}
        >
          <p style={{ marginRight: "1rem" }}>{hurdleZone.name}</p>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || mutationLoading}
          >
            Save
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

export default SoloWorkoutsV2;
