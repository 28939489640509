import React from "react";
import classes from './boxBorderRadiusComponent.module.scss'

const BoxBorderRadiusComponent = () => {
  return (
    <div className={classes.borderRadiusPage}>
      <div className={classes.borderRadiusPage__title}>
        <h1>Box Container Spacing</h1>
        <h3>Border Radius Guide</h3>
        <p className={classes.styleInstruction}>For usage, kindly check the Styles Usage Section</p>
      </div>
      <div className={classes.borderRadiusPage__container}>
        <div className={classes.borderRadiusPage__size}>
          <h2>18px</h2>
          <h2>14px</h2>
          <h2>24px</h2>
          <h2>24px</h2>
          <h2>32px</h2>
        </div>
        <div className={classes.borderRadiusPage__boxContainer}>
          <div className={classes.boxContainer1}>
            <div className={classes.box1}>&nbsp;</div>
          </div>
          <div className={classes.boxContainer2}>
            <div className={classes.box2}>&nbsp;</div>
            <div className={classes.box2}>&nbsp;</div>
            <div className={classes.box2}>&nbsp;</div>
            <div className={classes.box2}>&nbsp;</div>
          </div>
          <div className={classes.boxContainer3}>
            <div className={classes.box3}>&nbsp;</div>
          </div>
          <div className={classes.boxContainer4}>
            <div className={classes.box4}>&nbsp;</div>
            <div className={classes.box4}>&nbsp;</div>
          </div>
          <div className={classes.boxContainer5}>
            <div className={classes.box5}>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxBorderRadiusComponent;
