import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import reducers here
import userReducer from './userReducer';
import projectReducer from './projectReducer';
import themeReducer from './themeReducer';
import menuReducer from './menuReducer';
import parentTrackReducer from './parentTrackReducer';
import rootSaga from '../sagas';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['project', 'theme', 'menu'],
};

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

//add more reducer in case of refactor or additional feature later
export const rootReducer = combineReducers({
  //register reducers
  user: userReducer,
  project: projectReducer,
  theme: themeReducer,
  menu: menuReducer,
  parentTrack: parentTrackReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initStore = (apolloClient) => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga, apolloClient);

  return {
    store,
    persistor,
  };
};

export default initStore;
