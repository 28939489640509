import { createAction } from '@reduxjs/toolkit';

export const clearParent = createAction('parent/clear');

export const setParent = createAction(
  'parent/setParent',
  (values) => {
    return {
      payload: values,
    };
  }
);

export const setOperationStatus = createAction(
  'parent/setOperationStatus',
  (status, operation, errors) => ({
    payload: {
      status,
      operation,
      errors,
    },
  })
);

export const resetOperationStatus = createAction(
  'parent/resetOperationStatus'
);

