import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { get, pick } from 'lodash';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '../../../components/Checkbox';
import PasswordInput from '../../../components/Textbox/PasswordInput';
import CustomButton from '../../../components/Button/CustomButton';
import FBLoginButton from '../../../components/Button/FBLoginButton';
import Toaster from '../../../components/Toaster/Toaster';
import LoginIcon from '../../../assets/images/login.png';

function SignupComponent({
  onSubmit,
  isLoading,
  signupErrors,
  signupWithFacebook,
  shouldShowSuccessToaster,
  onCloseSuccessToaster,
}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormErrors(signupErrors);
  }, [signupErrors]);

  const removeErrorForField = (fieldName) => {
    if (get(formErrors, fieldName)) {
      const keysToPick = Object.keys(formErrors).filter((k) => k !== fieldName);
      setFormErrors(pick(formErrors, keysToPick));
    }
  };

  const handleSubmit = (e) => {
    //add validation here maybe?
    onSubmit(
      {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
      },
      agreeToTerms
    );

    e.preventDefault();
  };

  return (
    <form className="signup-form-wrapper" onSubmit={handleSubmit}>
      {shouldShowSuccessToaster && (
        <Toaster
          className="success-toaster"
          message="Account successfully created. Please check your email."
          autoCloseDuration={5000}
          displayCloseButton
          onClose={onCloseSuccessToaster}
        />
      )}
      <div className="form-row">
        <TextField
          value={firstName}
          error={!!get(formErrors, 'firstName')}
          helperText={get(formErrors, 'firstName')}
          id="signup-firstname-input"
          variant="outlined"
          label="First Name"
          disabled={isLoading}
          onChange={(e) => {
            removeErrorForField('firstName');
            setFirstName(e.target.value);
          }}
        />
      </div>
      <div className="form-row">
        <TextField
          value={lastName}
          error={!!get(formErrors, 'lastName')}
          helperText={get(formErrors, 'lastName')}
          id="signup-lastname-input"
          variant="outlined"
          label="Last Name"
          disabled={isLoading}
          onChange={(e) => {
            removeErrorForField('lastName');
            setLastName(e.target.value);
          }}
        />
      </div>
      <div className="form-row">
        <TextField
          value={email}
          error={!!get(formErrors, 'email')}
          helperText={get(formErrors, 'email')}
          id="signup-email-input"
          type="email"
          variant="outlined"
          label="Email address"
          disabled={isLoading}
          onChange={(e) => {
            removeErrorForField('email');
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="form-row">
        <PasswordInput
          value={password}
          error={!!get(formErrors, 'password')}
          helperText={get(formErrors, 'password')}
          id="signup-password-input"
          variant="outlined"
          label="Password"
          disabled={isLoading}
          onChange={(e) => {
            removeErrorForField('password');
            setPassword(e.target.value);
          }}
          autoComplete="new-password"
        />
      </div>
      <div className="form-row">
        <PasswordInput
          value={confirmPassword}
          error={!!get(formErrors, 'password_confirmation')}
          helperText={get(formErrors, 'password_confirmation')}
          id="signup-password-confirmation-input"
          variant="outlined"
          label="Confirm Password"
          disabled={isLoading}
          onChange={(e) => {
            removeErrorForField('password_confirmation');
            setConfirmPassword(e.target.value);
          }}
          autoComplete="new-password"
        />
      </div>
      <div
        className={`form-row checkbox-input-wrapper ${
          get(formErrors, 'terms') && 'error'
        }`}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeToTerms}
              onChange={(e) => {
                removeErrorForField('terms');
                setAgreeToTerms(e.target.checked);
              }}
            />
          }
          label={
            <span>
              I accept the <Link to="/signup/terms-of-use">Terms of Use</Link>{' '}
              and <Link to="/signup/privacy-policy">Privacy Policy</Link>
            </span>
          }
          labelPlacement="end"
        />
        {get(formErrors, 'terms') && (
          <div className="error-message">{get(formErrors, 'terms')}</div>
        )}
      </div>
      <div className="form-row">
        {/* <CustomButton
          className="primary"
          type="submit"
          disabled={isLoading}
          fullWidth
          role="signup-button"
        >
          Sign Up
        </CustomButton> */}
        <Button
          fullWidth
          type="submit"
          disabled={isLoading}
          variant="contained"
          startIcon={<img src={LoginIcon} />}
          size="large"
          style={{
            background: '#276EF1',
            color: '#fff',
            borderRadius: '8px',
            marginTop: '32px',
            marginBottom: '16px',
            boxShadow: 'none',
            height: '56px',
            textTransform: 'none'
          }}
        >
          Sign Up
        </Button>
      </div>
      <div className="form-row">
        <FBLoginButton className="custom-button" callback={(result) => signupWithFacebook(result)}>
          Continue with Facebook
        </FBLoginButton>
      </div>

      <div className="other-options-section">
        <div className="separator" style={{ color: '#595959', marginTop: '32px' }}>Already have an account?</div>
        <div className="sign-up-link-wrapper">
          <Link to="/login">
            <b>LOG IN</b>
          </Link>
        </div>
      </div>

      <div className="loading-wrapper">{isLoading && <CircularProgress />}</div>
    </form>
  );
}

SignupComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  signupErrors: PropTypes.object,
  signupWithFacebook: PropTypes.func,
  shouldShowSuccessToaster: PropTypes.bool,
  onCloseSuccessToaster: PropTypes.func,
};

export default SignupComponent;
