import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/Add';
import classes from './launchpadComponents.module.scss';
import PremiumBanner from '../../../../components/Layout/PremiumBanner/PremiumBanner';

const LaunchpadBanner = ({ id, style = {} }) => {
  const history = useHistory();

  return (
    <div>
      <div className={classes.contentContainer} style={style}>
        <PremiumBanner />
        <div className={classes.contentHead}>
          <div className={classes.contentItem}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: '#276EF1',
                    textTransform: 'none',
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/launchpad/${id}/list`)}
                >
                  <FormatListBulletedIcon style={{ marginRight: '5px' }} />
                  Launchpad
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    color: '#276EF1',
                    cursor: 'pointer',
                    textTransform: 'none'
                  }}
                  onClick={() => {
                    history.push('/launchpad/project/create');
                  }}
                >
                  Add Launchpad
                  <AddIcon style={{ marginLeft: '5px' }} />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchpadBanner;
