import React from 'react';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash';

const AuthWrapper = ({ children }) => {
  const root = localStorage.getItem('persist:root') || null;
  const user = get(JSON.parse(root), 'user', null);
  const bearer =
    get(JSON.parse(user), 'data.authenticationToken', null) ||
    localStorage.getItem('igniteToken') ||
    null;

  if (!bearer) {
    return <Redirect to="/login" />;
  }
  return children;
};

export default AuthWrapper;
