import React from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Launch from '@material-ui/icons/Launch';
import classes from './boxSpacingComponent.module.scss';

const BoxSpacingComponent = () => {
  const title1 = '<Grid container spacing={1}>';
  const title2 = '<Grid container spacing={5}>';
  const title3 = '<Grid container spacing={10}>';
  return (
    <div className={classes.boxSpacingPage}>
      <div className={classes.boxSpacingPage__title}>
        <h1>Box Spacing Style Guide</h1>
        <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/buttons/" target="_blank">Box Spacing<Launch /></a></p>
      </div>
      <div className={classes.boxSpacingPage__container}>
        <h2>{title1}</h2>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
              primary.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
              secondary.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              error.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
              warning.main
        </Box>
          </Grid>
        </Grid>

        <h2>{title2}</h2>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
              primary.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
              secondary.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              error.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
              warning.main
        </Box>
          </Grid>
        </Grid>

        <h2>{title3}</h2>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
              primary.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
              secondary.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="error.main" color="error.contrastText" p={2}>
              error.main
        </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
              warning.main
        </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BoxSpacingComponent;
