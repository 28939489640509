import React from "react";
import classes from './backgroundColorComponent.module.scss'

const BackgroundColorComponent = () => {
  return (
    <div className={classes.bgColorPage}>
      <div className={classes.bgColorPage__title}>
        <h1>Background Color Guide</h1>
        <p>Background colors of the body, insets, and panels</p>
        <p className={classes.styleInstruction}>For usage, kindly check the Styles Usage Section</p>
      </div>

      <div className={classes.bgColorPage__container}>

        <div className={classes.bgColorPage__row1}>

          <div className={classes.bgColorPage__row1col1}>
            <div className={classes.bgColorPage__box1}></div>
            <h4>White BG</h4>
            <p>FFFFFF</p>
          </div>

          <div className={classes.bgColorPage__row1col2}>
            <div className={classes.bgColorPage__box2}></div>
            <h4>Lighter Gray BG</h4>
            <p>FBFBFB</p>
          </div>

        </div>

        <div className={classes.bgColorPage__row2}>

          <div className={classes.bgColorPage__row2col1}>
            <div className={classes.bgColorPage__box3}></div>
            <h4>Dark BG</h4>
            <p>595959</p>
          </div>

          <div className={classes.bgColorPage__row2col2}>
            <div className={classes.bgColorPage__box4}></div>
            <h4>Dark BG</h4>
            <p>1D1D1D</p>
          </div>

        </div>

      </div>
    </div>
  );
};

export default BackgroundColorComponent;
