import React from 'react';
import CustomButton from '../../Button/CustomButton';
import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';

import classes from './bannerHead.module.scss';
import contentHeadImage from '../../../assets/images/contentHead.png';

const useStyles = makeStyles((theme) => ({
  padNav: {
    margin: '16px auto',
    paddingLeft: '15px;',
    textAlign: 'left',
  },
  contentHeadButtonStyle: {
    background: '#276EF1',
    borderRadius: '8px',
  }
}));

const BannerHead = () => {
  const customStyle = useStyles();
  return (
    <div>
      <div className={classes.contentContainer}>
        <div className={classes.contentHead}>
          {/* <LoginContainer /> */}
          <div>
            <h2>Launch your superheroes</h2>
            <p>Conquer your next great breakthrough</p>
            <CustomButton
              className={customStyle.contentHeadButtonStyle}
            >
              <span className={classes.contentHeadBtn}>
                Launch New breakthrough
              </span>
            </CustomButton>
            <img src={contentHeadImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BannerHead;