import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useParams, useHistory } from 'react-router-dom';
import ResetPasswordComponent from './ResetPasswordComponent';
import SigninSignupWrapper from '../../../components/User/SigninSignupWrapper';
import './ResetPassword.scss';

import {
  selectIsLoading,
  selectResetPasswordErrors,
  selectIsUserAuthenticated,
} from '../../../store/selectors/userSelectors';
import { resetPassword, clear } from '../../../store/actions/userActions';

function ResetPasswordContainer({
  isLoading,
  formErrors,
  resetPassword,
  isAuthenticated,
  clear,
}) {
  const { resetToken } = useParams();
  const history = useHistory();

  useLayoutEffect(() => {
    clear();
  }, [])

  const onSubmit = (password, confirmPassword) => {
    resetPassword(password, confirmPassword, resetToken);
  };

  if (isAuthenticated) {
    history.push('/');
  }

  return (
    <SigninSignupWrapper>
      <ResetPasswordComponent
        isVisible
        onSubmit={onSubmit}
        formErrors={formErrors}
        onClose={() => history.push('/')}
      />
    </SigninSignupWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  formErrors: selectResetPasswordErrors,
  isAuthenticated: selectIsUserAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
      clear,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
