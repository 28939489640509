import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PremiumBanner from '../../../../components/Layout/PremiumBanner/PremiumBanner';

import classes from './zeroState.module.scss';
import zeroStateImg from '../../../../assets/images/launchpad-zero-state.png';

const ZeroState = () => {
  const history = useHistory();

  let cost = "{X}{R}";
  const array = cost.split('');
  let icons = [];

  while(cost.length > 0){
    const first = cost.charAt(0);
    if(first === '{'){
      const position = cost.indexOf('}');
      icons.push(cost.substring(1, position));
      cost = cost.substring((position + 1), cost.length);
    } else {
      break;
    }
  }
  console.log('ICONS: ', icons);
  

  return (
    <div className={classes.zeroState}>
      <PremiumBanner />
      <div style={{paddingTop: `20vh`}}>
        <div>
          <img src={zeroStateImg} alt="" />
        </div>
        <div style={{ marginTop: `48px` }}>
          <p className={classes.zeroSubtext}>
            Start Launchpad today
          </p>
        </div>
        <div className={classes.submitButton__section}>
          <Button
            href="/launchpad/project/create"
            variant="contained" color='primary' className={classes.submitButton} size="large"
            startIcon={<AddIcon style={{ marginRight: '5px' }} />}
            style={{ color: '#fff' }}>
            Add Launchpad
          </Button>
        </div>
      </div>
  </div>
  );
};


export default ZeroState;
