import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import useStyles from "../component-style/styleGuide-style";
import { useTheme } from "@material-ui/core/styles";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";

// import Style Guide Components

// Usage Guide
import StylesUsageComponent from './sub/styles-usage/StylesUsageComponent';
// Typography
import TypographyComponent from "./sub/typography/TypographyComponent";

// Brand Color Components
import BrandColorComponent from "./sub/branding-color/BrandColorComponent";

// Background Color Components
import BackgroundColorComponent from "./sub/background-color/BackgroundColorComponent";
import AuxilliaryColorComponent from "./sub/background-color/AuxilliaryColorComponent";
import LabelColorsComponent from "./sub/background-color/LabelColorsComponent";
import TextColorComponent from "./sub/background-color/TextColorComponent";

// Color Palettes Components
import ButtonsComponent from "./sub/color-palettes/ButtonsComponent";
import CardsComponent from "./sub/color-palettes/CardsComponent";
import IconsComponent from "./sub/color-palettes/IconsComponent";
import ProgressComponent from "./sub/color-palettes/ProgressComponent";
import SelectionControlsComponent from "./sub/color-palettes/SelectionControlsComponent";
import SnackBarComponent from "./sub/color-palettes/SnackBarComponent";
import TextFieldTypesComponent from "./sub/color-palettes/TextFieldTypesComponent";
import TextFieldStateComponent from "./sub/color-palettes/TextFieldStateComponent";

// Box Container Components
import BoxSpacingComponent from "./sub/box-container/BoxSpacingComponent";
import ImageSpacingComponent from "./sub/box-container/ImageSpacingComponent";
import BoxContainerComponent from "./sub/box-container/BoxContainerComponent";
import BoxBorderRadiusComponent from "./sub/box-container/BoxBorderRadiusComponent";

// import material-ui icons
import {
  FontDownload,
  FormatPaint,
  Wallpaper,
  Palette,
  BorderAll,
  Style
} from "@material-ui/icons";

const StyleGuideComponent = (props) => {
  const { container } = props;
  console.log(container);
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [toggleBrandingColor, setToggleBrandingColor] = useState(false);
  const [toggleBackgroundColor, setToggleBackgroundColor] = useState(false);
  const [toggleColorPalettes, setToggleColorPalettes] = useState(false);
  const [toggleBoxContainer, setToggleBoxContainer] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (name) => {
    if (name === "BrandingColor") {
      setToggleBrandingColor(!toggleBrandingColor);
    }
    if (name === "BackgroundColor") {
      setToggleBackgroundColor(!toggleBackgroundColor);
    }
    if (name === "ColorPalettes") {
      setToggleColorPalettes(!toggleColorPalettes);
    }
    if (name === "BoxContainer") {
      setToggleBoxContainer(!toggleBoxContainer);
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />

      <Divider />
      <List>
        {/* Typograpy Selection */}
        <ListItem button component={Link} to='/styleguide/typography'>
          <ListItemIcon>
            <FontDownload />
          </ListItemIcon>
          <ListItemText primary='Typography' />
        </ListItem>

        {/* Branding Color Selection */}
        <ListItem
          button
          name='BrandingColor'
          onClick={(event) =>
            handleClick(event.currentTarget.getAttribute("name"))
          }
        >
          <ListItemIcon>
            <FormatPaint />
          </ListItemIcon>
          <ListItemText primary='Branding Color' />
          {toggleBrandingColor ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={toggleBrandingColor} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to='/styleguide/brand-color'
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary='Brand Color' />
            </ListItem>
          </List>
        </Collapse>

        {/* Background Color Selection */}
        <ListItem
          button
          name='BackgroundColor'
          onClick={(event) =>
            handleClick(event.currentTarget.getAttribute("name"))
          }
        >
          <ListItemIcon>
            <Wallpaper />
          </ListItemIcon>
          <ListItemText primary='Background Color' />
          {toggleBackgroundColor ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={toggleBackgroundColor} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {[
              { text: 'Background Color', url: '/styleguide/background-color' },
              { text: 'Text Color', url: '/styleguide/text-color' },
              { text: 'Auxilliary Color', url: '/styleguide/auxilliary-color' },
              { text: 'Label Colors', url: '/styleguide/label-color' }].map((item, index) => (
                <ListItem button component={Link} className={classes.nested} key={item.text} to={item.url}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
          </List>
        </Collapse>

        {/* Color Palettes Selection */}
        <ListItem
          button
          name='ColorPalettes'
          onClick={(event) =>
            handleClick(event.currentTarget.getAttribute("name"))
          }
        >
          <ListItemIcon>
            <Palette />
          </ListItemIcon>
          <ListItemText primary='Color Palettes' />
          {toggleColorPalettes ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={toggleColorPalettes} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>

            <List component='div' disablePadding>
              {[
                { text: 'Text Field Types', url: '/styleguide/text-field-types' },
                { text: 'Text Field State', url: '/styleguide/text-field-state' },
                { text: 'Selection Controls', url: '/styleguide/selection-controls' },
                { text: 'Buttons', url: '/styleguide/buttons' },
                { text: 'Progress', url: '/styleguide/progress' },
                { text: 'Snackbar', url: '/styleguide/snackbar' },
                { text: 'Icons', url: '/styleguide/icons' },
                { text: 'Cards', url: '/styleguide/cards' },].map((item, index) => (
                  <ListItem button component={Link} className={classes.nested} key={item.text} to={item.url}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
            </List>

          </List>
        </Collapse>

        {/* Box Container Selection */}
        <ListItem
          button
          name='BoxContainer'
          onClick={(event) =>
            handleClick(event.currentTarget.getAttribute("name"))
          }
        >
          <ListItemIcon>
            <BorderAll />
          </ListItemIcon>
          <ListItemText primary='Box Container' />
          {toggleBoxContainer ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={toggleBoxContainer} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>

            {[
              { text: 'Box Container', url: '/styleguide/box-container' },
              { text: 'Box Spacing', url: '/styleguide/box-spacing' },
              { text: 'Box Border Radius', url: '/styleguide/box-border-radius' },
              { text: 'Image Spacing', url: '/styleguide/image-spacing' },].map((item, index) => (
                <ListItem button component={Link} className={classes.nested} key={item.text} to={item.url}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
          </List>
        </Collapse>
        {/* Styles Usage */}
        <ListItem button component={Link} to='/styleguide/styles-usage'>
          <ListItemIcon>
            <Style />
          </ListItemIcon>
          <ListItemText primary='Styles Usage' />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant='h6' noWrap>
            Ni2 App Style Guides
          </Typography>
        </Toolbar>
      </AppBar>
      <BrowserRouter>
        <nav className={classes.drawer} aria-label='mailbox folders'>
          <Hidden smUp implementation='css'>
            <Drawer
              container={container}
              variant='temporary'
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation='css'>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant='permanent'
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Switch>
            <Route exact path='/styleguide/styles-usage' render={() => <StylesUsageComponent />} />
            {/* Typography Routes */}
            <Route exact path='/styleguide/typography' render={() => <TypographyComponent />} />

            {/* Brand Color Routes */}
            <Route exact path='/styleguide/brand-color' render={() => <BrandColorComponent />}
            />

            {/* Background Color Routes */}
            <Route exact path='/styleguide/background-color' render={() => <BackgroundColorComponent />} />
            <Route exact path='/styleguide/label-color' render={() => <LabelColorsComponent />} />
            <Route exact path='/styleguide/auxilliary-color' render={() => <AuxilliaryColorComponent />} />
            <Route exact path='/styleguide/text-color' render={() => <TextColorComponent />} />

            {/* Color Palette Routes */}
            <Route exact path='/styleguide/buttons' render={() => <ButtonsComponent />} />
            <Route exact path='/styleguide/cards' render={() => <CardsComponent />} />
            <Route exact path='/styleguide/icons' render={() => <IconsComponent />} />
            <Route exact path='/styleguide/progress' render={() => <ProgressComponent />} />
            <Route exact path='/styleguide/selection-controls' render={() => <SelectionControlsComponent />} />
            <Route exact path='/styleguide/snackbar' render={() => <SnackBarComponent />} />
            <Route exact path='/styleguide/text-field-state' render={() => <TextFieldStateComponent />} />
            <Route exact path='/styleguide/text-field-types' render={() => <TextFieldTypesComponent />} />

            {/* Box Container Routes */}
            <Route exact path='/styleguide/box-border-radius' render={() => <BoxBorderRadiusComponent />} />
            <Route exact path='/styleguide/box-container' render={() => <BoxContainerComponent />} />
            <Route exact path='/styleguide/image-spacing' render={() => <ImageSpacingComponent />} />
            <Route exact path='/styleguide/box-spacing' render={() => <BoxSpacingComponent />} />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
};

export default StyleGuideComponent;
