import { gql } from '@apollo/client';
import { get } from 'lodash';

export const SigninPayloadFragment = gql`
  fragment signinUser on SignInPayload {
    user {
      id
      email
      authenticationToken
    }
    errors
  }
`;

export const SignUpPayloadFragment = gql`
  fragment signupUser on SignUpPayload {
    user {
      id
      email
      authenticationToken
    }
    errors
  }
`;

export const ForgotPasswordPayloadFragment = gql`
  fragment forgotPasswordUser on ForgotPasswordPayload {
    user {
      id
      email
      resetPasswordToken
    }
  }
`;

export const ResetPasswordPayloadFragment = gql`
  fragment user on ResetPasswordPayload {
    user {
      id
      email
      authenticationToken
    }
  }
`;

export function loginMutation(email, password) {
  return gql`
    mutation SignIn {
      signIn(
        input: {
          params: { email: "${email}", password: "${password}" }
        }
      ) {
        ...signinUser
        success
        errors
      }
    }
    ${SigninPayloadFragment}
  `;
}

export function signinWithFBMutation(
  email,
  providerId,
  firstName,
  lastName,
  image
) {
  return gql`
    mutation {
      facebookSignIn(
        input: {
          args: {
            provider: "facebook", 
            providerId: "${providerId}", 
            firstName: "${firstName}", 
            lastName: "${lastName}", 
            email: "${email}", 
            fbImageUrl: "${image}"} 
          }
        )
      {
        ...user
      }
    }
    fragment user on FacebookSignInPayload{
      user{
        id
        email
        firstName
        lastName
        subscription
        authenticationToken
      }
      errors
    }`;
}

export function signupMutation({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
}) {
  return gql`
    mutation {
      signUp(
        input: {
          params: {
            firstName: "${firstName}",
            lastName: "${lastName}",
            email: "${email}",
            password: "${password}",
            passwordConfirmation: "${confirmPassword}"
          }
        }
      ) {
        ...signupUser
        success
        errors
      }
    }
    ${SignUpPayloadFragment}
  `;
}

export function forgotPasswordMutation({ email }) {
  return gql`
    mutation {
      forgotPassword(
        input: {
          params: {
            email: "${email}"            
          }
        }
      ) {
        ...forgotPasswordUser
        success
        errors        
      }
    }
    ${ForgotPasswordPayloadFragment}
  `;
}

export function resetPasswordMutation({
  password,
  passwordConfirmation,
  resetPasswordToken,
}) {
  return gql`
    mutation {
      resetPassword(
        input: {
          params: {
            password: "${password}",
            passwordConfirmation: "${passwordConfirmation}",
            resetPasswordToken: "${resetPasswordToken}"
          }
        }
      ){
        ...user
      }
    }
    ${ResetPasswordPayloadFragment}
  `;
}

export function getUserProfile() {
  return gql`
    query {
      showProfile {
        id
        firstName
        lastName
        mobileNumber
        organization
        position
        roles
        avatarUrl
      }
    }
  `;
}

export const UpdateProfilePayload = gql`
  fragment profile on UpdateProfilePayload {
    profile {
      id
      firstName
      lastName
      mobileNumber
      organization
      position
      roles
    }
  }
`;

export function updateProfile({
  firstName,
  lastName,
  mobileNumber,
  organization,
  position,
  roles = [],
  password,
}) {
  const rolesStr = roles.map((role) => `"${role}"`).join(', ');
  const passwordParam = !!password ? `password: "${password}"` : '';
  // roles:[${rolesStr}],
  return gql`
    mutation UpdateProfile{
      updateProfile(
        input: {
          params: {
            firstName: "${firstName}",
            lastName: "${lastName}",
            mobileNumber: "${mobileNumber}",
            organization: "${organization}",
            position: "${position}",
            roles:[${rolesStr}],
            ${passwordParam}
          }
        }
      ){
        ...profile
        success
        errors
      }      
    }
    ${UpdateProfilePayload}
  `;
}

export const updateProfileImage = (params) => {
  return gql`
    mutation {
      attachProfileAvatar(input: { 
        avatar: "${params}"
      }) {
        user {
          id
          email
          firstName
          lastName
          subscription
        }
        profile {
          id
          mobileNumber
          avatarUrl
          fbImageUrl
        }
      }
    }
  `;
}

export const attachProfileAvatar = (params, hash) => {
  return gql`
    mutation {
      attachProfileAvatar(input: { 
        blobId: "eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBMZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b5a8bd5b4a08763eb4bdeac58dda8afe70f14609"
    }) {
        user {
          id
          email
          firstName
          lastName
          paid
        }
        profile {
          id
          mobileNumber
          avatarUrl
          fbImageUrl
        }
      }
    }`;
}

export const fetchUserSubscription = () => {
  return gql`
    query {
      fetchUserSubscription {
        subscription
      }
    }
  `;
}
 
 
 
