import React from "react";
import { Link } from 'react-router-dom';
import FontDownload from '@material-ui/icons/FontDownload';
import ColorLens from '@material-ui/icons/ColorLens';
import Apps from '@material-ui/icons/Apps';
import classes from './stylesUsageComponent.module.scss';

const StylesUsageComponent = () => {
    const typographySample1 = `Using hi2h1 = <div className={ni2style.ni2h1}>Sample</div>`;
    const typographySample2 = 'Using hi2h1 with added classes = <div className={${ni2style.ni2h1} ${span}}>Sample</div>';

    return (
        <div className={classes.stylesUsagePage}>
            <div className={classes.stylesUsagePage__title}>
                <h1>Styles Usage Guide</h1>
            </div>
            <div className={classes.stylesUsagePage__container}>
                <div className={classes.warningHeader}><h3>ATTENTION:</h3><h4>Make sure that you import the ni2style module from <span>'./stylesUsageComponent.module.scss'</span> to your project component</h4></div>
                <div className={classes.stylesUsagePage__typography}>
                    <h2><FontDownload />&nbsp;&nbsp;Using Typography</h2>
                    <p><span>When using className's  header and h1 to h3 tags: Use ni2header, ni2h1, ni2h2, ni2h3 instead</span></p>
                    <p>The font family for this is already set to 'Crimson Text'</p>
                    <p>Example: {typographySample1}</p>
                    <p>Example: {typographySample2}</p>
                    <br />
                    <p><span>When using className's h4 to h6, body, subheader, button, caption1, overline tags: Use ni2h4, ni2h5, ni2h6, ni2body, ni2subheader, ni2button, ni2caption1, ni2overline instead</span></p>
                    <p>The font family for this is already set to 'Open Sans'</p>
                    <p>Example: {typographySample1}</p>
                    <p>Example: {typographySample2}</p>
                </div>

                <div className={classes.stylesUsagePage__colors}>
                    <h2><ColorLens />&nbsp;&nbsp;Using Colors</h2>
                    <p><span>When using font colors: Use these values instead</span></p>
                    <p>Example: {`<div className={ni2style.ni2Blue}> Test Div </div>`}</p>
                    <p>Example when inserting more than 1 className's: {'<div className={${ni2style.ni2Blue} ${ni2style.ni2h1}> Test Div </div>'}</p>
                    <div className={classes.brandColors__section}>
                        <h3>Branding Colors</h3>
                        <h4>Main Color: ni2Blue</h4>
                        <h4>Darker Color: ni2DarkBlue</h4>
                        <h4>Lighter Color: ni2LightBlue</h4>
                        <h4>Subtle Color: ni2SubtleBlue</h4>
                    </div>

                    <div className={classes.bgColors__section}>
                        <h3>Background Colors</h3>
                        <h4>White Background: ni2WhiteBG</h4>
                        <h4>Lighter Gray Background: ni2LightGrayBG</h4>
                        <h4>Dark Background: ni2DarkBG</h4>
                        <h4>Darker Background: ni2DarkerBG</h4>
                    </div>

                    <div className={classes.textColors__section}>
                        <h3>Text Color - Used For Body Text</h3>
                        <h4>Main: ni2TextColor</h4>
                        <h4>Gray: ni2TextGray</h4>
                        <h4>LightGray: ni2LightTextGray</h4>
                    </div>

                    <div className={classes.auxColors__section}>
                        <h4>Auxilliary Color - Used For Alerts</h4>
                        <h4>Success: ni2Success</h4>
                        <h4>Warning: ni2Warning</h4>
                        <h4>Error: ni2Error</h4>
                        <h4>Info: ni2Info</h4>
                    </div>

                    <div className={classes.labelColors__section}>
                        <h4>Label Color - Can be used for Errors and Alerts</h4>
                        <h4>Success: ni2Success1</h4>
                        <h4>Warning: ni2Warning1</h4>
                        <h4>Error: ni2Error1</h4>
                        <h4>Info: ni2Info1</h4>
                        <h4>Success: ni2Success2</h4>
                        <h4>Warning: ni2Warning2</h4>
                        <h4>Error: ni2Error2</h4>
                        <h4>Info: ni2Info2</h4>
                    </div>

                </div>

                <div className={classes.stylesUsagePage__icons}>
                    <h2><Apps />&nbsp;&nbsp;Using Icons</h2>
                    <p><span>When using icons, surf into this site <a href='https://ionicons.com/'>ionicons</a>, click on icon and copy the web component code, then paste into your component</span></p>

                    <p>The script needed for the icons to work is already embedded in the index.html file</p>
                    <p>Example: {'<ion-icon name="construct-outline"></ion-icon>'} will give you <ion-icon name="construct-outline"></ion-icon></p>
                </div>

                <div className={classes.stylesUsagePage__radius}>
                    <h2 className={classes.ni2radius24}><Apps />&nbsp;&nbsp;Using Border Radius</h2>
                    <p><span>When using border radius, you can use ni2radius18, ni2radius14, ni2radius24, ni2radius32</span></p>
                    <p>Just make sure that the tag your using this with has content, background and optional border</p>
                    <p>Example: {'<div className={ni2style.ni2radius14}>'} will give you <div className={classes.ni2radius14}>sample</div></p>
                </div>
            </div>
        </div>
    )
};

export default StylesUsageComponent;