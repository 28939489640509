import React from 'react';
import Button from '@material-ui/core/Button';
import './CustomButton.scss';

const CustomButton = ({ className, children, ...props }) => {
  return (
    <Button className={['custom-button', className].join(' ')} {...props}>
      {children}
    </Button>
  );
};

CustomButton.defaultProps = {
  className: '',
};

export default CustomButton;
