import React from 'react';
import { get } from 'lodash';
import Question from '../Question/Question';

const Cluster = ({ keyId, data, sortedAnswers, fn, notesFn }) => {
  const questions = get(data, 'questions', []);
  let cluster = null;

  if (data) {
    cluster = questions.map((question, index) => {
      const id = get(question, 'id', '');
      return (
        <div key={`questionId${id}`} style={{ paddingBottom: 5 }}>
          <div style={{ paddingBottom: 10 }}>
            <Question
              keyId={`${keyId}questionId${id}`}
              sortedQuestions={question}
              sortedAnswers={sortedAnswers}
              fn={fn}
              notesFn={notesFn}
            />
          </div>
        </div>
      );
    });
  }

  return <div>{cluster}</div>;
};

export default Cluster;
