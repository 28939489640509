import React, { useState } from 'react';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import QuestionTypes from '../QuestionTypes/QuestionTypes';
import AddIcon from '@material-ui/icons/Add';
import Loading from '../../../../../components/Loading/Loading';
import { TextField } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import GlossaryModal from '../../../../Glossary/GlossaryModal';

import { parseGlossaryShortcodes } from '../../../../../utility/helpers';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const Question = ({ keyId, sortedQuestions, sortedAnswers, fn, notesFn }) => {
  const { pathname } = useLocation();

  const maxOption = get(sortedQuestions, 'maxOptionSelection', '0') || 0;
  const questionType = get(sortedQuestions, 'questionType', '');
  const questionId = get(sortedQuestions, 'id', '');
  const answer = get(
    sortedAnswers?.find((a) => a.question_id === questionId),
    'answer',
    null
  );
  const notes = get(
    sortedAnswers?.find((a) => a.question_id === questionId),
    'notes',
    null
  );
  const [options, setOptions] = useState([0]);

  let questionTypeArea = (
    <div style={{ marginTop: 50 }}>
      <Loading size="5rem" />
    </div>
  );

  if (options) {
    questionTypeArea = (
      <>
        <div>
          {options.map((i, index) => {
            return (
              <div
                key={`${keyId}questionTypeId${questionId}-${index}`}
                style={{ margin: `10px 0px 30px` }}
              >
                <QuestionTypes
                  keyId={`${keyId}questionTypeId${questionId}-${index}`}
                  answer={answer}
                  answerOptions={
                    get(sortedQuestions, 'answerOptions', []) || []
                  }
                  label={get(sortedQuestions, 'description', '') || ''}
                  placeHolder={get(sortedQuestions, 'placeholder', '') || ''}
                  questionId={questionId}
                  questionType={questionType}
                  fn={fn}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }

  function transform(node, index) {
    if (node.name === "span" && node.attribs.class === "modal-span") {
      return (
        <GlossaryModal key={node.attribs.identifier} identifier={node.attribs.identifier} type={node.attribs.type} label={node.attribs.label} />
      );
    }

    // all links must open in a new window
    if (node.name === "a") {
      node.attribs.target = "_blank";
      return convertNodeToElement(node, index, transform);
    }
  
  }

  const parseOptions = {
    decodeEntities: true,
    transform
  };

  return !questionType?.includes('Keywords') ? (
    <div>
      <div>{ReactHtmlParser(parseGlossaryShortcodes(
            get(sortedQuestions, 'title', ''),
            `${pathname}/glossary`
          ), parseOptions)}</div>
      <div>
        {questionTypeArea}
        <div>
          {get(sortedQuestions, 'allowNotes', false) && (
            <TextField
              fullWidth
              label={'Notes'}
              variant="outlined"
              placeholder={'Notes'}
              multiline
              minRows={7}
              maxRows={7}
              value={notes}
              onChange={(e) => notesFn(e, questionId)}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default Question;
