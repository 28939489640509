import React from "react";
import classes from './brandColorComponent.module.scss';

const BrandColorComponent = () => {
  return (
    <div className={classes.brandColorPage}>
      <div className={classes.brandColorPage__title}>
        <h1>Brand Color Guide</h1>
        <p>Main colors, can be used for text or background</p>
        <p className={classes.styleInstruction}>For usage, kindly check the Styles Usage Section</p>
      </div>

      <div className={classes.brandColorPage__container}>

        <div className={classes.brandColorPage__row1}>

          <div className={classes.brandColorPage__row1col1}>
            <div className={classes.brandColorPage__row1col1__box1}></div>
            <h4>Main Color</h4>
            <p>276EF1</p>
            <h1>Sample Text</h1>
          </div>

          <div className={classes.brandColorPage__row1col2}>
            <div className={classes.brandColorPage__row1col2__box2}></div>
            <h4>Darker</h4>
            <p>1F58C1</p>
            <h1>Sample Text</h1>
          </div>

        </div>

        <div className={classes.brandColorPage__row2}>

          <div className={classes.brandColorPage__row2col1}>
            <div className={classes.brandColorPage__row2col1__box3}></div>
            <h4>Lighter</h4>
            <p>1F58C1</p>
            <h1>Sample Text</h1>
          </div>

          <div className={classes.brandColorPage__row2col2}>
            <div className={classes.brandColorPage__row2col2__box4}></div>
            <h4>Subtle</h4>
            <p>1F58C1</p>
            <h1>Sample Text</h1>
          </div>

        </div>

      </div>
    </div>
  );
};

export default BrandColorComponent;
