import React, { Fragment } from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import classes from './Header.module.scss';

import AppLogoImg from '../../assets/images/ni2-beta-logo.png';
import GuildLogo from '../../assets/images/guild-beta-logo.png';

const Header = ({
  leftFn = () => {},
  rightFn = () => {},
  icon = <AppsIcon />,
  children = [],
  style = {},
  restart = process.env.REACT_APP_RESTART
}) => (
  <Fragment>
    <div className={classes.appHeader} style={style}>
      <div className={classes.appName} onClick={leftFn}>
        <img
          src={restart === "true" ? AppLogoImg : GuildLogo}
          className={classes.appLogo}
          alt="Ni2" />
      </div>
      <div className={classes.menuIcon} onClick={rightFn}>
        {icon}
      </div>
    </div>
    {!!children.filter((child) => !!child).length && (
      <div className={classes.headerBottomSection}>{children}</div>
    )}
  </Fragment>
);

export default Header;
