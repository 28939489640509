import { v4 as uuidv4 } from 'uuid';
import { get, isEmpty, uniqBy } from 'lodash';
import { useLocation } from 'react-router-dom';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const isValidEmail = (str) => {
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(str);
};

export const checkboxMapper = (values) => {
  let obj = null;
  if (typeof values === 'object' && values !== null) {
    obj = Object.keys(values).map((i) => {
      return {
        id: uuidv4(),
        label: values[i],
        name: i,
        value: i,
        checked: false,
        isFiller: false,
      };
    });
  }
  return obj;
};

export const isHexColor = (color) => /^#[0-9A-F]{6}$/i.test(`${color}`);

export const generateRandomString = (length = 10) => {
  let min = 65,
    max = 90; //A - Z
  if (Math.round(Math.random())) {
    min = 97; //a
    max = 122; //z
  }

  return Array(length)
    .fill(null)
    .map(() =>
      String.fromCharCode(Math.floor(Math.random() * (max - min)) + min)
    )
    .join('');
};

export const mutateParent = (item) => {
  let obj = [];
  if (!isEmpty(item)) {
    obj = item.map((parent) => {
      let path = get(parent, 'path', '');
      const titleWithOutSlash = parent.title.replace('/', '');
      const titleWithDash = titleWithOutSlash.replace(' ', '-').toLowerCase();
      const isActive = false;
      if (!path) {
        path = titleWithDash === 'mission-control' || titleWithDash === 'delta-track' ? '/' : '/' + titleWithDash;
      } else {
        path = path.includes('mission-control') || path.includes('delta-track') ? '/' : path;
      }
      return {
        ...parent,
        path,
        isActive,
      };
    });
  }
  return obj;
};

export function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

export const arrangeQuestions = (arr) => {
  return uniqBy(arr, 'customSectionId')
    .map((sq) => {
      const updatedQuestion = arr.filter(
        (i) => i.customSectionId === sq.customSectionId
      );
      const clusteredQuestion = uniqBy(updatedQuestion, 'clusterId').map(
        (cq) => {
          return {
            clusterId: cq.clusterId,
            clusterDisplayOrder: cq.clusterDisplayOrder,
            questions: [
              ...updatedQuestion.filter((i) => i.clusterId === cq.clusterId),
            ].sort((a, b) => +a.displayOrder - +b.displayOrder),
          };
        }
      );
      return {
        customSectionId: sq.customSectionId,
        customSectionDisplayOrder: sq.customSectionDisplayOrder,
        clusters: clusteredQuestion.sort(
          (a, b) => +a.clusterDisplayOrder - +b.clusterDisplayOrder
        ),
      };
    })
    .sort(
      (a, b) => +a.customSectionDisplayOrder - +b.customSectionDisplayOrder
    );
};
//customSectionDisplayOrder
// clusterDisplayOrder

export const parseGlossaryShortcodes = (sourceStr, glossaryPageUrl) => {
  if (!sourceStr) {
    return sourceStr;
  }

  const _parseGlossaryShortCodes = (type, str) => {
    let regExp = /(\{\{%)\s?([a-zA-Z_0-9\-]+)(\[.+?\])(\s?\%\}\})/g;

    if (type === 'example') {
      regExp = /(\{\{\&amp;)\s?([a-zA-Z_0-9\-]+)(\[.+?\])(\s?\&amp;\}\})/g;
    }

    if (type === 'snapshot') {
      regExp = /(\{\{\#)\s?([a-zA-Z_0-9]+)(\[.+?\])(\s?\#\}\})/g;
    }

    const matches = [...str.matchAll(regExp)];

    if (!matches.length) {
      return str;
    }

    return matches.reduce((newStr, currentMatch) => {
      const matchedString = currentMatch[0];
      const identifier = currentMatch[2];
      const linkLabel = currentMatch[3].substring(
        1,
        currentMatch[3].length - 1
      ); //remove the [ and ] chars

      //disable snapshot glossary link for now...
      // const newLink = `<a href="${glossaryPageUrl}?identifier=${identifier}&type=${type}" onClick="return !('${type}' === 'snapshot')" style="text-decoration: underline">${linkLabel}</a>`;
      const newLink = `<span class="modal-span" identifier="${identifier}" type="${type}" label="${linkLabel}" style="text-decoration: underline">${linkLabel}</span>`;
      return newStr.replace(matchedString, newLink);
    }, str);
  };

  return ['example', 'regular', 'snapshot'].reduce((finalStr, glossaryType) => {
    return _parseGlossaryShortCodes(glossaryType, finalStr);
  }, sourceStr);
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});