import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from '../../components/Button//CloseButton';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#FFF',
    width: '100%',
    height: '203px',
    padding: '0 0 43px 15px',
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-end',

    '& h1': {
      fontWeight: '600',
      fontSize: '26px',
      color: '#595959',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '50%',
    right: '15px',
    transform: 'translate(-50%, -50%)',
    height: '18px',
    width: '18px',

    '& svg': {
      fontSize: '16px',
    },
  },
});

function GlossaryHeader({ children, onClose }) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <h1>{children}</h1>
      <CloseButton
        className={classes.closeButton}
        onClick={onClose}
        size="small"
      />
    </div>
  );
}

export default GlossaryHeader;
