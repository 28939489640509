import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import CustomButton from './CustomButton';
import { Button } from '@material-ui/core';
import FBIcon from '../../assets/images/facebook.png';

function FBLoginButton({ callback, children, className }) {
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FB_APP_ID}
      callback={callback}
      fields="first_name,last_name,email,picture"
      render={(renderProps) => (
        <Button
          fullWidth
          variant="contained"
          className={['fb-button', className].join(' ')}
          startIcon={<img src={FBIcon} />}
          size="large"
          onClick={renderProps.onClick}
          type="submit"
        >
          {children}
        </Button>
        // <CustomButton
        //   className={['fb-button', className].join(' ')}
        //   onClick={renderProps.onClick}
        //   fullWidth
        // >
        //   {children}
        // </CustomButton>
      )}
    />
  );
}

FBLoginButton.defaultProps = {
  className: '',
};

export default FBLoginButton;
