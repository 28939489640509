export default [
  {
    key: 'acct',
    value: 'Accounting / Auditing',
  },
  {
    key: 'adm',
    value: 'Administrative',
  },
  {
    key: 'advr',
    value: 'Advertising',
  },
  {
    key: 'anls',
    value: 'Analyst',
  },
  {
    key: 'art',
    value: 'Art / Creative',
  },
  {
    key: 'bd',
    value: 'Business Development',
  },
  {
    key: 'cnsl',
    value: 'Consulting',
  },
  {
    key: 'cust',
    value: 'Customer Service',
  },
  {
    key: 'dist',
    value: 'Distribution',
  },
  {
    key: 'dsgn',
    value: 'Design',
  },
  {
    key: 'edu',
    value: 'Education',
  },
  {
    key: 'eng',
    value: 'Engineering',
  },
  {
    key: 'fin',
    value: 'Finance',
  },
  {
    key: 'genb',
    value: 'General Business',
  },
  {
    key: 'hcpr',
    value: 'HealthCare Provider',
  },
  {
    key: 'hr',
    value: 'Human Resources',
  },
  {
    key: 'it',
    value: 'Information Technology',
  },
  {
    key: 'lgl',
    value: 'Legal',
  },
  {
    key: 'mgmt',
    value: 'Management',
  },
  {
    key: 'mnfc',
    value: 'Manufacturing',
  },
  {
    key: 'mrkt',
    value: 'Marketing',
  },
  {
    key: 'othr',
    value: 'Other',
  },
  {
    key: 'pr',
    value: 'Public Relations',
  },
  {
    key: 'prch',
    value: 'Purchasing',
  },
  {
    key: 'prdm',
    value: 'Product Management',
  },
  {
    key: 'prjm',
    value: 'Project Management',
  },
  {
    key: 'prod',
    value: 'Production',
  },
  {
    key: 'qa',
    value: 'QualityAssurance',
  },
  {
    key: 'rsch',
    value: 'Research',
  },
  {
    key: 'sale',
    value: 'Sales',
  },
  {
    key: 'sci',
    value: 'Science',
  },
  {
    key: 'stra',
    value: 'Strategy / Planning',
  },
  {
    key: 'supl',
    value: 'Supply Chain',
  },
  {
    key: 'trng',
    value: 'Training',
  },
  {
    key: 'wrt',
    value: 'Writing / Editing',
  },
];
