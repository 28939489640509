import { createAction } from '@reduxjs/toolkit';

export const clearTheme = createAction('theme/clear');

export const setTheme = createAction(
  'theme/setTheme',
  (values) => {
    return {
      payload: values,
    };
  }
);

export const setOperationStatus = createAction(
  'theme/setOperationStatus',
  (status, operation, errors) => ({
    payload: {
      status,
      operation,
      errors,
    },
  })
);

export const resetOperationStatus = createAction(
  'theme/resetOperationStatus'
);

