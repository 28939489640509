import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout, { Header } from '../../components/Layout';
import classes from './paywall.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import PremiumAccessIcon from '../../assets/images/premium-access.png';
import LockIcon from '../../assets/images/lock.png';
import RocketImg from '../../assets/images/rocket.png';
import { Button } from '@material-ui/core';

const Paywall = () => {
  const history = useHistory();
  
  return (
    <Layout>
        <div className={classes.paywallWrapper}>
            <Header icon={<CloseIcon />}
                style={{ borderBottom: 'none', background: '#FFF' }}
                rightFn={() => history.goBack()}
            ></Header>
            <div className={classes.paywallDetails}>
                <div className={classes.premiumAccess}>
                    <img src={PremiumAccessIcon} />
                    <div>PREMIUM ACCESS</div>
                </div>
                <div className={classes.rocketContainer}>
                    <img src={RocketImg} />
                    <div>
                        Get the most of Ni2 100k Accelerator by unlocking all premium features
                    </div>
                </div>
                <div className={classes.premiumDetails}>
                    <ul>
                        <li>Full access to LAUNCHPAD Hurdles 1-12 for step-by-step guide to launching new products, services, solutions fast, lean and agile.</li>
                        <li>Full access to SANDBOX Opportunity Generator to help birth new ideas  that are ready for LAUNCHPAD.</li>
                        <li>Full access to TIME MACHINE GPS Navigation and Superpower-6 to maximize your career & peak performance capabilities.</li>
                        <li>Receive Ni2 credentials </li>
                    </ul>
                </div>
                <div className={classes.submitButton__section}>
                    <Button
                        fullWidth
                        variant="contained" color='primary' className={classes.submitButton} size="large"
                        startIcon={<img src={LockIcon} style={{ marginRight: '5px' }}
                         />}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open('https://restart.ph/unlock', '_blank');
                        }}
                        style={{ color: '#fff' }}>
                        Unlock Now
                    </Button>
                </div>
            </div>
        </div>
    </Layout>
  );
}

export default Paywall;