import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import classes from './snackbarComponent.module.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Launch from '@material-ui/icons/Launch';


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const SnackBarComponent = () => {

  const [openError, setOpenError] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openCustom1, setOpenCustom1] = useState(false);
  const [openCustom2, setOpenCustom2] = useState(false);
  const [openCustom3, setOpenCustom3] = useState(false);

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleClickWarning = () => {
    setOpenWarning(true);
  };

  const handleClickInformation = () => {
    setOpenInformation(true);
  };

  const handleClickSuccess = () => {
    setOpenSuccess(true);
  };

  const handleClickCustom1 = () => {
    setOpenCustom1(true);
  };

  const handleClickCustom2 = () => {
    setOpenCustom2(true);
  };

  const handleClickCustom3 = () => {
    setOpenCustom3(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
    setOpenWarning(false);
    setOpenInformation(false);
    setOpenSuccess(false);
    setOpenCustom1(false);
    setOpenCustom2(false);
    setOpenCustom3(false);
  };

  return (
    <div className={classes.snackbarPage}>
      <div className={classes.snackbarPage__title}>
        <h1>Snackbar Style Guide</h1>
        <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/snackbars/" target="_blank">Snackbar <Launch /></a></p>
      </div>
      <div className={classes.snackbarPage__container}>

        <div className={classes.snackbarPage__container__buttonSnackbar}>
          <Button variant="outlined" onClick={handleClickError}>
            Open Error snackbar
          </Button>

          <Button variant="outlined" onClick={handleClickWarning}>
            Open Warning snackbar
          </Button>

          <Button variant="outlined" onClick={handleClickInformation}>
            Open Information snackbar
          </Button>

          <Button variant="outlined" onClick={handleClickSuccess}>
            Open Success Snackbar
          </Button>


        </div>

        <div className={classes.snackbarPage__container__snackbarAlert}>
          <Alert severity="error">This is an error message!</Alert>
          <Alert severity="warning">This is a warning message!</Alert>
          <Alert severity="info">This is an information message!</Alert>
          <Alert severity="success">This is a success message!</Alert>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={openCustom1}
            onClose={handleClose}
            message="Note archived"
            action={
              <React.Fragment>
                <Button color="secondary" size="small" onClick={handleClose}>
                  UNDO
            </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={openCustom2}
            onClose={handleClose}
            message="Note archived"
            action={
              <React.Fragment>
                <Button color="secondary" size="small" onClick={handleClose}>
                  UNDO
                 </Button>
              </React.Fragment>
            }
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={openCustom3}
            onClose={handleClose}
            message="Good start! I learned how others are using this for so many unique biz cases"
            action={
              <React.Fragment>
                <Button color="secondary" size="small" onClick={handleClose}>
                  <h6>join us on Daily Flights</h6>
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>
      </div>

      <div className='snackbarPage__container__snackbar'>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            This is an error message!
            </Alert>
        </Snackbar>

        <Snackbar open={openWarning} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning">
            This is a warning message!
            </Alert>
        </Snackbar>

        <Snackbar open={openInformation} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info">
            This is an information message!
            </Alert>
        </Snackbar>

        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            This is a success message!
            </Alert>
        </Snackbar>
      </div>

      <div className={classes.snackbarPage__CustomSnackbars}>
        <Button variant="outlined" onClick={handleClickCustom1}>
          Open Custom1 Snackbar
       </Button>
        <Button variant="outlined" onClick={handleClickCustom2}>
          Open Custom2 Snackbar
       </Button>
        <Button variant="outlined" onClick={handleClickCustom3}>
          Open Custom3 Snackbar
       </Button>
      </div>

    </div>
  );
};

export default SnackBarComponent;
