import React, { useEffect, useState, useRef } from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';
import Launch from '@material-ui/icons/Launch';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';
import PersonAdd from '@material-ui/icons/PersonAdd';
import CheckIcon from '@material-ui/icons/Check';
import classes from './progressComponent.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  fabProgress: {
    color: blue[500],
    position: 'absolute',
    top: -6,
    left: 63.5,
    zIndex: 1,
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useStylesLinearIndeterminate = makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: '250px',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStylesLinearDeterminate = makeStyles({
  root: {
    position: 'relative',
    top: '400px',
    width: '100%',
    height: '300px'
  },
});

const ProgressComponent = () => {
  const classesAddUser = useStyles();
  const classesLinearIndeterminate = useStylesLinearIndeterminate();
  const classesLinearDeterminate = useStylesLinearDeterminate();
  const [progress, setProgress] = useState(0); // for linear determinate
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();

  const buttonClassname = clsx({
    [classesAddUser.buttonSuccess]: success,
  });

  useEffect(() => {

    const timerLinearDeterminate = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearTimeout(timer.current);
      clearInterval(timerLinearDeterminate);
    };
  }, []);

  const handleAddUser = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className={classes.progressPage}>

      <div className={classes.progressPage__title}>
        <h1>Progress Style Guide</h1>
        <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/progress/" target="_blank">Progress <Launch /></a></p>
      </div>

      <div className={classes.progressPage__container}>

        <div className={classes.progressPage__container__circular}>
          <h2>Circular</h2>
          <CircularProgress color='primary' size='150px' />
          <CircularProgress color='primary' size='100px' />
          <CircularProgress color='primary' size='50px' />
        </div>

        <div className={classes.progressPage__container__interactiveIntegration}>
          <h2>Interactive Integration</h2>
          <div className={classesAddUser.wrapper}>
            <Fab
              aria-label="save"
              color="primary"
              className={buttonClassname}
              onClick={handleAddUser}
            >
              {success ? <PersonAdd /> : <CheckIcon />}
            </Fab>
            {loading && <CircularProgress size={68} className={classesAddUser.fabProgress} />}
          </div>
        </div>

      </div>

      <div className={classes.progressPage__container__linearIndeterminate}>
        <div className={classesLinearIndeterminate.root}>
          <h2>Linear Indeterminate</h2>
          <LinearProgress />
          <LinearProgress color="secondary" />
        </div>
      </div>

      <div className={classes.progressPage__container__linearDeterminate}>
        <div className={classesLinearDeterminate.root}>
          <h2>Linear Determinate</h2>
          <LinearProgress variant="determinate" value={progress} />
        </div>
      </div>

    </div>
  );
};

export default ProgressComponent;
