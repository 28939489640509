import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import Header from '../../../Header';
import { selectParentTrack } from '../../../../store/selectors';
import { BackLink, NextLink } from '../../../../components/Links';
import { Link } from 'react-router-dom';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
    emphasize,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { setPeerExchangeData, setOpenSnackbar } from '../../../../store/actions/projectActions';
import { selectSnackbar } from '../../../../store/selectors/projectSelectors'
import { createStructuredSelector } from 'reselect';
import classes from './peerExchange.module.scss';

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(104, 154, 245, 0.6);',
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const defaultTheme = createTheme();
const ToolTipTheme = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "gray"
    }
})(Tooltip);

const PeerExchangeComponent = ({
    parent,
    projectId,
    zoneId,
    hurdleId,
    substopId,
    hurdleName,
    hurdleBrow,
    routeId,
    setPeerExchangeData,
    snackbar,
    setOpenSnackbar,
    subStopInfo
}) => {

    const parentTrack = parent && parent.find(p => p.isActive);
    const path = get(parentTrack, 'path', '/');
    const parentPath = path === '/' ? '/mission-control' : path;

    const history = useHistory();
    const toolTipText = `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.`

    const [triggerRender, setTriggerRender] = useState(false);
    const [openSB, setOpenSB] = useState(snackbar.switch);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        setOpenSB(true)
    }

    return (
        <div className={classes.peerExchangePageWrapper}>
            <div className={classes.peerExchangePage__header}>
                <div className={classes.header__links}>
                    <Header>
                        <BackLink to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`}>Back</BackLink>
                        {/* <NextLink to={'#'}>Continue</NextLink> */}
                    </Header>
                </div>
                <div className={classes.header__breadcrumbs}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                        <StyledBreadcrumb component={Link} to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`} label={`${hurdleBrow} | ${hurdleName}`} className={classes.breadcrumbs__firstDegree} />
                        <StyledBreadcrumb component={Link} to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/solo_workout`} label={`${get(subStopInfo, 'name')}`} className={classes.breadcrumbs__secondDegree} />
                    </Breadcrumbs>
                </div>
                <div className={classes.header__title}>
                    <h1>Peer Exchange</h1>
                    <p>Preamble about why and how to use this pre-work exercise in order to master this hurdle.</p>
                </div>
                <div className={classes.peerExchangePage__content}>
                    <div  className={classes.content_container}>
                        <h2>GOOGLE MEET</h2>
                        <p>How, where, when, how often and why will Iggy interact with what we're building? And how does Iggy's journey with us finally fulfill their Target Delta?</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

PeerExchangeComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    snackbar: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    snackbar: selectSnackbar,
    parent: selectParentTrack,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setPeerExchangeData,
            setOpenSnackbar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PeerExchangeComponent)
