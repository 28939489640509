import React from 'react';
import PropTypes from 'prop-types';

import SplashImage from '../../assets/images/ni2-splash-screen.png';
import GuildSplashImage from '../../assets/images/guild-splash-screen.png';
import { FBIcon, LinkedInIcon, YoutubeIcon } from '../Icons';

function SigninSignupWrapper({ className, children }) {
  const splash_image = process.env.REACT_APP_RESTART === "true" ? SplashImage : GuildSplashImage;

  return (
    <div className={['signin-signup-wrapper', className].join(' ')}>
      <div className="logo-wrapper">
        <img src={splash_image} alt="" />
      </div>
      <div className="form-wrapper">{children}</div>
      <div className="copyright-section">
        <p className="copy-text">
          &copy; 2020 Ignite House. All Rights Reserved.
        </p>
        <p className="terms-links">
          <a href="https://ignitehouse.vc/privacypolicy/" target="_blank">Terms of Use</a>
          <span>|</span>
          <a href="https://ignitehouse.vc/privacypolicy/" target="_blank">Privacy Policy</a>
        </p>
      </div>
      <div className="social-media-links">
        <a href="">
          <FBIcon />
        </a>

        <a href="">
          <LinkedInIcon />
        </a>

        <a href="">
          <YoutubeIcon />
        </a>
      </div>
    </div>
  );
}

SigninSignupWrapper.defaultProps = {
  className: '',
};

SigninSignupWrapper.propTtypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SigninSignupWrapper;
