import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import classes from './titleHead.module.scss';
import LockIcon from '../../../assets/images/lock.png';
import PremiumBanner from '../PremiumBanner/PremiumBanner';
import ReactHtmlParser from 'react-html-parser';
import ShowMoreText from "react-show-more-text";

const SubHeader = ({ title, description, hideProgress, style = {}, children }) => {

  const [collapse, setCollapse] = useState(true);

  return (
    <div>
      <div className={classes.contentContainer} style={style}>
        <PremiumBanner />
        <div className={[classes.contentHead, (hideProgress ? classes.collab : '')].join(' ')} >
          {children}
          <div className={`${hideProgress ? classes.titleCollab : ''}`}>
            <div className={classes.contentTitle}> 
              <h2>{title}</h2>
            </div>
            {description && (
              <div className={[classes.contentText, (collapse ? classes.collapse : '')].join(' ')}>
                <ShowMoreText
                  lines={4}
                  more="See more"
                  less="See less"
                  className="contentCss"
                  anchorClass="myAnchor"
                  expanded={false}
                  width={0}
                  truncatedEndingComponent={"... "}
                >{ReactHtmlParser(description)}</ShowMoreText>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubHeader;
