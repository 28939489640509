import { gql } from "@apollo/client";

export const FETCH_PROJECT_DETAIL_V2 = gql`
  query v2FetchProjectDetail($projectId: ID!) {
    project: v2FetchProjectDetail(projectId: $projectId) {
      answers: v2Answer {
        notes
        questionId
        value
      }
      consensusAnswers: v2Consensus {
        notes
        questionId
        value
      }
    }
  }
`;

export const SUBMIT_ANSWERS = gql`
  mutation SubmitV2Answers($input: SubmitV2AnswersInput!) {
    submitAnswers: submitV2Answers(input: $input) {
      success
      errors
      projectDetail {
        answers: v2Answer {
          questionId
          value
          notes
        }
        consensusAnswers: v2Consensus {
          questionId
          value
          notes
        }
      }
    }
  }
`;

export const ANSWER_TYPES = {
  SOLO_WORKOUT: "SOLO_WORKOUT",
  HURDLE_CONSENSUS: "HURDLE_CONSENSUS",
};
export function createPayload(projectId, type, answers) {
  return {
    input: {
      params: {
        projectId,
        type,
        answers,
      },
    },
  };
}
