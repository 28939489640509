import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import {
  client,
  fetchGlossaryByIdentifier,
  fetchExampleByIdentifier,
} from '../../api';
import GlossaryComponent from './GlossaryComponent';
import { useQueryString } from '../../utility/helpers';

export default function GlossaryContainer() {
  const [glossaryData, setGlossaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const queryStr = useQueryString();
  const history = useHistory();

  const identifier = queryStr.get('identifier');
  const type = queryStr.get('type');

  const onClose = () => {
    history.goBack();
  };

  const getGlossaryData = () => {
    setIsLoading(true);

    client()
      .query({
        query:
          type === 'example'
            ? fetchExampleByIdentifier(identifier)
            : fetchGlossaryByIdentifier(identifier),
      })
      .then((response) => {
        const path =
          type === 'example' ? 'data.fetchExample' : 'data.fetchGlossary';

        setGlossaryData(get(response, path, {}));
      })
      .catch((error) => {
        setIsLoading();
        console.log(error);
        onClose();
      });
  };

  useEffect(() => {
    getGlossaryData();
  }, []);

  if (type === 'snapshot') {
  } else {
    return (
      <GlossaryComponent
        header={glossaryData.word}
        content={glossaryData.description}
        onClose={onClose}
      />
    );
  }
}

GlossaryContainer.propTypes = {
  identifier: PropTypes.string,
};
