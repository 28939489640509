import React, { useState, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectSoloWorkoutProjectData, selectSnackbar, selectParentTrack } from '../../../../store/selectors';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { get, isArray, isEmpty } from 'lodash';
import Layout from '../../../../components/Layout';
import HurdleConsensusComponent from './HurdleConsensusComponent';
import Loading from '../../../../components/Loading/Loading';
import SomethingWentWrong from '../../../../components/Error/SomethingWentWrong';
import {
  client,
  fetchHurdle,
  fetchHurdleConsensus,
  postHurdleConsensusDetail,
} from '../../../../api';
import { arrangeQuestions } from '../../../../utility/helpers';

const HurdleConsensusContainer = ({
  parent,
}) => {
  let { projectId, zoneId, hurdleId, substopId } = useParams();
  const history = useHistory();
  const parentTrack = parent && parent.find(p => p.isActive);
  const path = get(parentTrack, 'path', '/');
  const parentPath = path === '/' ? '/mission-control' : path;
  const [hurdleName, setHurdleName] = useState('');
  const [hurdleBrow, setHurdleBrow] = useState('');
  const [subStopInfo, setSubStopInfo] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [skipPath, setSkipPath] = useState('');
  

  const onChangeHandler = (e, id) => {
    if (!isEmpty(answers)) {
      const updatedAnswer = answers.map((a) => {
        if (a.question_id === id) {
          switch (a?.questionType) {
            case 'Horizontal Rate': {
              const aoId = (e?.target?.id).replace(`hr${id}-answerOption-`, '');
              const updatedAO = a.answer.map(i => {
                if (i.id === aoId) {
                  i.selected = true;
                } else {
                  i.selected = false;
                }
                return {
                  ...i
                }
              });
              a.answer = updatedAO;
              break;
            }
            case 'Inline Checkbox': {
              const aoId = (e?.target?.id).replace(`ic${id}-answerOption-`, '');
              const updatedAO = a.answer.map(i => {
                if (i.id === aoId) {
                  i.selected = !i.selected;
                }
                return {
                  ...i
                }
              });
              a.answer = updatedAO;
              break;
            }
            case 'Modal Checkbox':
            case 'Multi-select':
              a.answer = e.slice();
              break;
            default: {
              a.answer = e?.target?.value;
              break;
            }
          }
        }
        return a;
      });
      setAnswers(updatedAnswer);
    }
  };

  const onChangeNotesHandler = (e, id) => {
    if (!isEmpty(answers)) {
      const updatedAnswer = answers.map((a) => {
        if (a.question_id === id) {
          a.notes = e?.target?.value;
        }
        return a;
      });
      setAnswers(updatedAnswer);
    }
  };

  const buildPayload = (values) => {
    const mapper = JSON.parse(JSON.stringify(values));
    const answerDetails = mapper.map(a => {
      switch (a?.questionType) {
        case 'Horizontal Rate':
        case 'Inline Checkbox': {
          if (!isEmpty(a.answer)) {
            const filteredAO = a.answer.filter(i => i.selected);
            const updatedAO = filteredAO.map(i => i?.id);
            a.answer = updatedAO.join(',');
          }
          break;
        }
        case 'Modal Checkbox':
        case 'Multi-select':
          const updatedAO = JSON.parse(JSON.stringify(a.answer));
          a.answer = updatedAO.join(',');
          break;
        default: {
          break;
        }
      }
      return {
        hurdle_id: `${a.hurdle_id}`,
        substop_id: `${a.substop_id}`,
        question_id: `${a.question_id}`,
        answer: `${a.answer}`,
        notes: `${a.notes}`,
      }
    });
    const submitPayload = {
      projectId: projectId,
      zoneId: zoneId,
      details: answerDetails,
    };
    return submitPayload;
  }

  const submitProjectDetails = () => {
    const payload = buildPayload(answers);
    setLoaded(false);
    client()
      .mutate({
        mutation: postHurdleConsensusDetail(payload),
      })
      .then(() => {
        setLoaded(true);
        history.push(
          skipPath
          ? skipPath
          : `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`
        );
      })
      .catch((e) => {
        setErrorMessage('Error on submitting data');
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  }

  const fetchHurdleDetail = async (id, projectid) => {
    // setLoaded(false);
    await client()
      .query({
        query: fetchHurdle(id, projectid),
      })
      .then((result) => {
        if (get(result, 'networkStatus', 7) === 8) {
          setErrorMessage('Error in fetching this hurdle');
          setLoaded(true);
          setError(true);
          return;
        }
        const hurdle = get(result, 'data.fetchHurdle', null);
        const hurdleName = get(hurdle, 'name', '');
        const hurdleEyebrow = get(hurdle, 'hurdleEyebrow', '');
        const substops = get(hurdle, 'substops', []);
        setHurdleName(hurdleName);
        setHurdleBrow(hurdleEyebrow);
        if (!isEmpty(substops) && substops?.length === 1) {
          setSkipPath(parentPath);
        }
      })
      .catch((e) => {
        setErrorMessage('Error in fetching this hurdle');
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  const fetchHurdleConsensusDetail = async (projectid, hurdleid) => {
    // setLoaded(false);
    await client()
      .query({
        query: fetchHurdleConsensus(projectid, hurdleid),
      })
      .then((result) => {
        if (get(result, 'networkStatus', 7) === 8) {
          setErrorMessage('Error in fetching this substop');
          setLoaded(true);
          setError(true);
          return;
        }
        const hurdleConsensus = get(result, 'data.fetchHurdleConsensus', null);
        const updatedInfo = JSON.parse(JSON.stringify(hurdleConsensus));
        if (hurdleConsensus) {
          const questionByOrder = get(updatedInfo, 'questions', []);
          const sortedQuestions = arrangeQuestions(questionByOrder);
          const sortedAnswers = questionByOrder.map((i) => {
            let answer = '';
            const consensusAnswer = i?.hcAnswer;
            const type = get(i, 'questionType', '');
            switch (type) {
              case 'Horizontal Rate': 
              case 'Inline Checkbox': {
                const arr = (!isEmpty(consensusAnswer?.answer) && consensusAnswer?.answer?.includes(','))
                  && consensusAnswer?.answer?.split(',');

                answer = i?.answerOptions?.map(i => {
                  let selectedState = false;
                  if (consensusAnswer) {
                    selectedState = isArray(arr) ? !!arr.find(ar => ar === i.id) : consensusAnswer?.answer === i.id;
                  }
                  return {
                    ...i,
                    selected: selectedState
                  }
                })
                break;
              }
              case 'Modal Checkbox':
              case 'Multi-select': {
                const arr = (!isEmpty(consensusAnswer?.answer) && consensusAnswer?.answer?.includes(','))
                  && consensusAnswer?.answer?.split(',');
                if (!isEmpty(consensusAnswer?.answer)) {
                  answer = arr || [`${consensusAnswer?.answer}`];
                } else {
                  answer = [];
                }
                break;
              }
              case 'Radio Buttons': {
                answer = !!consensusAnswer ? consensusAnswer?.answer : [];
                break;
              }
              default: {
                answer = get(consensusAnswer, 'answer', '');
                break;
              }
            }
            return {
              ...i,
              hurdle_id: `${hurdleId}`,
              substop_id: `${substopId}`,
              hurdle_resource_id: '',
              question_id: `${get(i, 'id', '')}`,
              answer: answer,
              notes: !!consensusAnswer ? consensusAnswer?.notes : '',
              questionType: type,
              hcAnswer: answer,
            };
          });
          setAnswers(sortedAnswers);
          setQuestions(sortedQuestions);
        }
        setLoaded(true);
        setSubStopInfo(updatedInfo);
      })
      .catch((e) => {
        setErrorMessage('Error in fetching this substop');
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  useLayoutEffect(() => {
    setLoaded(false);
    const runAsync = async () => {
      await Promise.all([fetchHurdleDetail(hurdleId, projectId), fetchHurdleConsensusDetail(projectId, hurdleId)]);
    };
    runAsync();
  }, []);

  let hurdleConsensusArea = null;

  if (error) {
    hurdleConsensusArea = (
      <Layout>
        <SomethingWentWrong />
      </Layout>
    );
    return hurdleConsensusArea;
  }

  if (!loaded) {
    hurdleConsensusArea = (
      <Layout>
        <div style={{ marginTop: 50 }}>
          <Loading size="5rem" />
        </div>
      </Layout>
    );
    return hurdleConsensusArea;
  }

  hurdleConsensusArea = (
    <HurdleConsensusComponent
      projectId={projectId}
      zoneId={zoneId}
      hurdleId={hurdleId}
      substopId={substopId}
      hurdleName={hurdleName}
      hurdleBrow={hurdleBrow}
      subStopInfo={subStopInfo}
      sortedQuestions={questions}
      sortedAnswers={answers}
      fn={onChangeHandler}
      notesFn={onChangeNotesHandler}
      submitProjectDetails={submitProjectDetails}
      skipPath={skipPath}
      error={error}
      errorMessage={errorMessage}
    />
  );

  return (
    <Layout>
      {hurdleConsensusArea}
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  parent: selectParentTrack,
});

export default connect(mapStateToProps, null)(HurdleConsensusContainer)
