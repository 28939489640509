import React from "react";
import Button from '@material-ui/core/Button';
import LockOpen from '@material-ui/icons/LockOpen';
import Launch from '@material-ui/icons/Launch';
import image1 from '../../../../../assets/images/styleguide-cards-bg1.png'
import image2 from '../../../../../assets/images/styleguide-cards-bg2.png'
import image3 from '../../../../../assets/images/styleguide-cards-bg3.png'
import classes from './cardsComponent.module.scss'

const CardsComponent = () => {
  return (
    <div className={classes.cardsPage}>

      <div className={classes.cardsPage__title}>
        <h1>Cards Style Guide</h1>
        <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/cards/" target="_blank">Cards <Launch /></a></p>
      </div>

      <div className={classes.cardsPage__container}> { /* --> container*/}

        <div className={classes.cardsPage__card1}>{ /* --> card 1*/}

          <img src={image1} alt="meeting" />
          <div className={classes.gradientBg}></div>

          <div className={classes.card1__header}>
            <LockOpen className={classes.header_icon} /> <p>Unlocks XX Cards</p>
          </div>

          <div className={classes.card1__title}>
            <p>Title Text</p>
          </div>

          <div className={classes.card1__body}>
            <p className={classes.card1__content}>
              Quick brown fox jumps over the lazy dog and a quick brown fox jumps over the lazy dog.
          </p>
            <div className={classes.card1__content__ctaButton}>
              <Button variant="outlined" size='medium' className={classes.ctaButton1}>CTA Button</Button>
            </div>
          </div>

          <div className={classes.card1__actions}>
            <Button variant="outlined" size='medium' className={classes.cardType}>Card Type</Button>
            <Button variant="outlined" size='medium' className={classes.unclaimedButton}>DD:HH:MM UNCLAIMED</Button>
          </div>

        </div>{ /* --> card 1*/}

        <div className={classes.cardsPage__card2}>{ /* --> card 2*/}

          <img src={image1} alt="meeting" />
          <div className={classes.gradientBg}></div>

          <div className={classes.card2__header}>
            <LockOpen className={classes.header_icon} /> <p>Unlocks XX Cards</p>
          </div>

          <div className={classes.card2__title}>
            <h2>Quick brown fox jumps over the lazy.</h2>
          </div>

          <div className={classes.card2__body}>
            <p className={classes.card2__content}>
              Quick brown fox jumps over the lazy dog and a quick brown fox jumps over the lazy dog.
          </p>
            <div className={classes.card2__content__ctaButton}>
              <Button variant="outlined" size='medium' className={classes.ctaBUtton2}>CTA Button</Button>
            </div>
          </div>

          <div className={classes.card2__actions}>
            <Button variant="outlined" size='medium' className={classes.cardType}>Card Type</Button>
            <Button variant="outlined" size='medium' className={classes.unclaimedButton}>DD:HH:MM UNCLAIMED</Button>
          </div>

        </div>{ /* --> card 2*/}

        <div className={classes.cardsPage__card3}>{ /* --> card 3*/}

          <img src={image2} alt="meeting" />

          <div className={classes.card3__actions}>
            <Button variant="outlined" size='medium' className={classes.cardType}>Card Type</Button>
            <Button variant="outlined" size='medium' className={classes.unclaimedButton}>DD:HH:MM UNCLAIMED</Button>
          </div>

          <div className={classes.card3__title}>
            <p>Title Text</p>
          </div>

          <div className={classes.card3__body}>
            <p className={classes.card3__content}>
              Quick brown fox jumps over the lazy dog and a quick brown fox jumps over the lazy dog.
            </p>
          </div>

          <div className={classes.card3__footer}>

            <div className={classes.card3__content__ctaButton}>
              <Button variant="outlined" size='medium' className={classes.ctaButton3}>CTA Button</Button>
            </div>

            <div className={classes.card3__header}>
              <LockOpen className={classes.header_icon} /> <p>Unlocks XX Cards</p>
            </div>

          </div>

        </div>{ /* --> card 3*/}

        <div className={classes.cardsPage__card4}>{ /* --> card 4*/}

          <img src={image3} alt="meeting" />

          <div className={classes.card4__header}>
            <LockOpen className={classes.header_icon} /> <p>Unlocks XX Cards</p>
          </div>

          <div className={classes.card4__actions}>
            <Button variant="outlined" size='medium' className={classes.cardType}>Card Type</Button>
            <Button variant="outlined" size='medium' className={classes.unclaimedButton}>DD:HH:MM UNCLAIMED</Button>
          </div>

          <div className={classes.card4__title}>
            <p>Title Text</p>
          </div>

          <div className={classes.card4__body}>
            <p className={classes.card4__content}>
              Quick brown fox jumps over the lazy dog and a quick brown fox jumps over the lazy dog.
             </p>
            <div className={classes.card4__content__ctaButton}>
              <Button variant="outlined" size='medium' className={classes.ctaButton4}>CTA Button</Button>
            </div>
          </div>

        </div>{ /* --> card 4*/}

      </div>{ /* --> container*/}
    </div >
  );
};

export default CardsComponent;
