import React from "react";
import StyleGuideComponent from "./components/StyleGuideComponent";
import classes from "./styleGuide.module.scss";

const StyleGuide = () => {
  return (
    <div className={classes.styleGuidPage}>
      <StyleGuideComponent />
    </div>
  );
};

export default StyleGuide;
