import React from 'react';
import PropTypes from 'prop-types';

function Layout({ className, children }) {
  return (
    <div className={['main-layout-wrapper', className].join(' ')}>
      {children}
    </div>
  );
}

Layout.defaultProps = {
  className: '',
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
export { default as Header } from './Header';
export { default as BannerHead } from './BannerHead/BannerHead';
export { default as TitleHead } from './TitleHead/TitleHead';
export { default as PremiumBanner } from './PremiumBanner/PremiumBanner';
