import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    background: '#f8f8f8',
    height: 'calc(100vh - 110px)',
    overflow: 'none'
  },
  content: {
    paddingTop: 20,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    marginTop: 30,
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
  },
  navi: {
    color: '#276EF1',
    textDecoration: 'underline',
  }
}));

const SomethingWentWrong = ({ message = 'Please reload the page. ', link = true, url = '#' }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <h3>
              {message}
            </h3>
            {link && (
              <Link
                to={url}
                onClick={(e) => {
                  window.location = 'iggy@restart.ph';
                  e.preventDefault();
              }}
              >
                <h4>If this error persists, please contact us via {' '}<span className={classes.navi}>iggy@restart.ph</span></h4>
              </Link>
            )}
            <img
              alt="Under development"
              className={classes.image}
              src="/images/broken-robot.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SomethingWentWrong;
