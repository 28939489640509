import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
  },
});

function BackButton({ to, label }) {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link}>
      <ChevronLeftIcon />
      <div>{label}</div>
    </Link>
  );
}

BackButton.defaultProps = {
  label: 'Back',
};

BackButton.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default BackButton;
