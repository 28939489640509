import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PasswordInput from '../../../components/Textbox/PasswordInput';
import CustomButton from '../../../components/Button/CustomButton';
import FBLoginButton from '../../../components/Button/FBLoginButton';
import LoginIcon from '../../../assets/images/login.png';

function LoginComponent({
  onSubmit,
  isLoading,
  loginErrors,
  signupWithFacebook,
}) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    //add validation here maybe?
    onSubmit(email, password);
    e.preventDefault();
  };

  return (
    <form className="login-form-wrapper" onSubmit={handleSubmit}>
      <div className="form-row">
        <TextField
          value={email}
          error={!!get(loginErrors, 'email')}
          helperText={get(loginErrors, 'email')}
          id="login-email-input"
          type="email"
          variant="outlined"
          label="Email address"
          disabled={isLoading}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-row">
        <PasswordInput
          value={password}
          error={!!get(loginErrors, 'password')}
          helperText={get(loginErrors, 'password')}
          id="login-password-input"
          variant="outlined"
          label="Password"
          disabled={isLoading}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="forgot-password-link-wrapper">
          <Link to="/forgot-password">Forgot Password</Link>
        </div>
      </div>

      <div className="form-row">
        {/* <CustomButton
          className="primary"
          type="submit"
          role="login-button"
        >
          Login
        </CustomButton> */}
        <Button
          fullWidth
          type="submit"
          disabled={isLoading}
          variant="contained"
          startIcon={<img src={LoginIcon} />}
          size="large"
          style={{
            background: '#276EF1',
            color: '#fff',
            borderRadius: '8px',
            marginTop: '32px',
            marginBottom: '16px',
            boxShadow: 'none',
            height: '56px',
            textTransform: 'none'
          }}
        >
          Login
        </Button>
      </div>
      <div className="form-row">
        <FBLoginButton className="custom-button" callback={(result) => signupWithFacebook(result)}>
          Continue with Facebook
        </FBLoginButton>
      </div>

      <div className="other-options-section">
        <div className="separator">or</div>
        <div className="sign-up-link-wrapper">
          <Link to="/signup">
            <b>SIGN UP</b>
          </Link>
        </div>
      </div>

      <div className="loading-wrapper">{isLoading && <CircularProgress />}</div>
    </form>
  );
}

LoginComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  loginErrors: PropTypes.object,
  signupWithFacebook: PropTypes.func,
};

export default LoginComponent;
