import { createAction } from '@reduxjs/toolkit';

export const clearLaunchpad = createAction('launchpad/clear');
export const launchpadSetData = createAction('launchpad/launchpadSetData', values => {
  return {
    payload: values,
  };
});

export const setOperationStatus = createAction(
  'launchpad/setOperationStatus',
  (status, operation, errors) => ({
    payload: {
      status,
      operation,
      errors,
    },
  })
);

export const resetOperationStatus = createAction('launchpad/resetOperationStatus');