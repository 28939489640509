import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Modal, Fade } from '@material-ui/core';
import { Header } from '../../components/Layout';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser';

import {
  client,
  fetchGlossaryByIdentifier,
  fetchExampleByIdentifier,
} from '../../api';
import { useQueryString } from '../../utility/helpers';
import { Label, LabelImportantOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '640px',
    width: window.screen.width,
    height: '100v',
    margin: 'auto auto'
  },
  paper: {
    backgroundColor: '#FFF',
    boxShadow: theme.shadows[5],
    height: '100vh',
    maxWidth: '640px',
    width: window.screen.width,
    overflow: 'auto',
  },
  content: {
    padding: '16px',
    color: '#595959',
    fontSize: '14px',
    overflowY: 'scroll',
    marginBottom: '24px'
  },
  h1: {
    color: '#595959',
    margin: '24px 16px',
    fontSize: '26px',
    fontWeight: 600
  }
}));

export default function GlossaryModal({ identifier, type, label }) {
  const [glossaryData, setGlossaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const styles = useStyles();

  const getGlossaryData = () => {
    setIsLoading(true);

    client()
      .query({
        query:
          type === 'example'
            ? fetchExampleByIdentifier(identifier)
            : fetchGlossaryByIdentifier(identifier),
      })
      .then((response) => {
        setOpenModal(true);
        const path =
          type === 'example' ? 'data.fetchExample' : 'data.fetchGlossary';

        setGlossaryData(get(response, path, {}));
      })
      .catch((error) => {
        setIsLoading();
        console.log(error);
        setOpenModal(false);
        // onClose();
      });
  };

  if (type === 'snapshot') {
    return <span style={{ textDecoration: "underline" }}>{label}</span>;
  } else {
    return (
      <div style={{ display: "inline" }}>
        <a style={{ textDecoration: "underline" }} onClick={(e) => { getGlossaryData(); e.preventDefault(); }}>
          {label}
        </a>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={styles.modal}
          open={openModal}
          onClose={() => setOpenModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
        >
          <Fade in={openModal}>
            <div className={styles.paper}>
              <Header
                rightFn={() => setOpenModal(false)}
                icon={(<CloseIcon />)}
                style={{
                  borderBottom: 'none',
                  backgroundColor: '#FFF'
                }}
              />
              <h1 className={styles.h1}>{glossaryData.word}</h1>
              <div className={styles.content}>
                {ReactHtmlParser(glossaryData.description)}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}
