import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../components/Button/CustomButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';

import classes from './HurdleResource.module.scss';

function WorkoutResource({ parentData, resourceData }) {
  const { parentPath, projectId, zoneId, hurdleId, substopId } = parentData;
  const { copyTextArea, name } = resourceData;

  return (
    <div className={classes.workout}>
      <div className={classes.workout__header}>
        <h1>{name}</h1>
        {/* <div className={classes.chevron__forward}>
          <Link
            to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/prework/work`}
          >
            <ion-icon name="chevron-forward-sharp" />
          </Link>
        </div> */}
      </div>

      <div className={classes.workout__content}>
        <div
          className={classes.workout__copyFromApi}
          dangerouslySetInnerHTML={{ __html: copyTextArea }}
        />
        <div className={classes.workout__button}>
          <Link
            to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/prework/work`}
            className={classes.ctaButton}
          >
            {/* <CustomButton color="primary">GetStarted</CustomButton> */}
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              size="small"
              style={{
                background: '#276EF1',
                color: '#fff',
                borderRadius: '8px',
                margin: '5px 0',
                boxShadow: 'none'
              }}
            >
              GO
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WorkoutResource;
