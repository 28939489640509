import React from 'react';
import PropTypes from 'prop-types';
import classes from './LinearProgressComponent.module.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from "@material-ui/core/styles";

function LinearProgressComponent({
  type,
  className,
  totalPoints,
  earnedPoints,
  scoringRate,
  bypassSuffix,
  subText = '',
  color = '#FFF',
  bgColor = 'rgba(255, 255, 255, 0.3)'
}) {
  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: bgColor,
      borderRadius: "10px",
      height: "6px"
    },
    barColorPrimary: {
      backgroundColor: color,
      borderRadius: "10px"
    }
  })(LinearProgress);
  const score = scoringRate >= 100 ? 100 : scoringRate

  return (
    <div className={[classes.wrapper, className, classes[type]].join(' ')}>
      <div className={classes.labelWrapper} style={{ color: color, textAlign: 'right' }}>
        {earnedPoints} of {totalPoints}{bypassSuffix} {subText}
      </div>
      <StyledLinearProgress
        variant="determinate"
        value={score}
        size={totalPoints}
        classes={{ root: classes.progressPath }}
      />
    </div>
  );
}

LinearProgressComponent.defaultProps = {
  className: '',
};

LinearProgressComponent.propTypes = {
  earnedPoints: PropTypes.number,
  totalPoints: PropTypes.number,
  scoringRate: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.oneOf(['half', 'full']),
  subText: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string
};

export default LinearProgressComponent;
