import React, { useState } from "react";
import classes from './typographyComponent.module.scss'

const TypographyComponent = () => {
  const [typedText, setTypedText] = useState('');


  return (
    <div className={classes.typographyPage}>

      <div className={classes.typographyPage__titleInput}>
        <h1>Typography Guide</h1>
        <label>Input A Word: </label>
        <input type="text" autoFocus value={typedText} onChange={(e) => setTypedText(e.target.value)} />
        <p className={classes.styleInstruction}>For usage, kindly check the Styles Usage Section</p>
      </div>

      <div className={classes.typographyPage__fontsUsed}>

        <div className={classes.typographyPage__crimsonText}>
          <div className={classes.typographyPage__crimsonText__container}>

            <div className={classes.ni2heading}>
              <p>Crimson Text</p>
              <h1>{`<heading> ` + `| ` + typedText}</h1>
              <p>Line height and paragraph spacing for heading is 1.2 x font size</p>
            </div>

            <div className={classes.ni2h1}>
              <p>Crimson Regular</p>
              <h1>{`<h1> ` + `| ` + typedText}</h1>
              <p>Large title  Font-Size-52px  Line-Height-62px</p>
            </div>

            <div className={classes.ni2h2}>
              <p className={classes.h2__title}>Crimson Semibold</p>
              <h2>{`<h2> ` + `| ` + typedText}</h2>
              <p>Title 1  Font-Size-40px  Line-Height-48px</p>
            </div>

            <div className={classes.ni2h3}>
              <p>Crimson Regular</p>
              <h3>{`<h3> ` + `| ` + typedText}</h3>
              <p>Title 2  Font-Size-32px  Line-Height-38px</p>
            </div>

          </div>

        </div>

        <div className={classes.typographyPage__openSans}>
          <div className={classes.typographyPage__openSans__container}>

            <div className={classes.ni2bodyHeading}>
              <p>Open Sans</p>
              <h4>{`<body> ` + `| ` + typedText}</h4>
              <p>Line height and paragraph spacing for heading is 1.2 x font size except body 1.5</p>
            </div>

            <div className={classes.ni2h4}>
              <p>Open Sans Semibold</p>
              <h4>{`<h4> ` + `| ` + typedText}</h4>
              <p>Tile 3  Font-Size-26px  Line-Height-31px</p>
            </div>

            <div className={classes.ni2h5}>
              <p>Open Sans Regular</p>
              <h5>{`<h5> ` + `| ` + typedText}</h5>
              <p>Tile 4  Font-Size-21px  Line-Height-25px</p>
            </div>

            <div className={classes.ni2h6}>
              <p>Open Sans Semibold</p>
              <h6>{`<h6> ` + `| ` + typedText}</h6>
              <p>Headline  Font-Size-17px  Line-Height-20px</p>
            </div>

            <div className={classes.ni2body}>
              <p>Open Sans Regular</p>
              <h6>{`Body ` + `| ` + typedText}</h6>
              <p>Body  Font-Size-17px  Line-Height-25px</p>
            </div>

            <div className={classes.ni2subHeader}>
              <p>Open Sans Regular</p>
              <h6>{`Subheader ` + `| ` + typedText}</h6>
              <p>Subheader  Font-Size-14px  Line-Height-17px</p>
            </div>

            <div className={classes.ni2button}>
              <p>Open Sans Semibold</p>
              <h6>{`Button ` + `| ` + typedText}</h6>
              <p>Button  Font-Size-14px  Line-Height-17px</p>
            </div>

            <div className={classes.ni2caption1}>
              <p>Open Sans Regular</p>
              <h6>{`Caption 1 ` + `| ` + typedText}</h6>
              <p>Caption 1  Font-Size-11px  Line-Height-13px</p>
            </div>

            <div className={classes.ni2overline}>
              <p>Open Sans Regular</p>
              <h6>{`Overline ` + `| ` + typedText}</h6>
              <p>Overline  Font-Size-9px  Line-Height-11px</p>
            </div>

          </div>
        </div>

      </div>

    </div>
  );
};

export default TypographyComponent;
