import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MUModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { get } from 'lodash';

import './Modal.scss';
import CloseButton from '../Button/CloseButton';

function Modal({
  className,
  headingText,
  shouldDisplayCloseButton,
  children,
  onClose,
  ...otherProps
}) {
  return (
    <MUModal
      className={['custom-modal', className].join(' ')}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={onClose}
      {...otherProps}
    >
      <Fade in={get(otherProps, 'open')}>
        <div className="modal-inner-wrapper">
          <div className="modal-header">
            <h2>{headingText}</h2>
            {!!shouldDisplayCloseButton && (
              <CloseButton onClick={() => onClose()} />
            )}
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </Fade>
    </MUModal>
  );
}

Modal.defaultProps = {
  className: '',
};

Modal.propTypes = {
  className: PropTypes.string,
  headingText: PropTypes.string,
  shouldDisplayCloseButton: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  //otherProps -> Please refer to Material UI Modal Component
};

export default Modal;
