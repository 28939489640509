import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { get } from 'lodash';

const middleWare = new ApolloLink((operation, forward) => {
  const root = localStorage.getItem('persist:root') || null;
  const user = get(JSON.parse(root), 'user', null);
  const bearer =
    get(JSON.parse(user), 'data.authenticationToken', null) ||
    localStorage.getItem('igniteToken') ||
    null;

  operation.setContext({
    headers: {
      authorization: bearer ? 'Bearer ' + bearer : null,
    },
  });
  return forward(operation);
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL });

export const client = () => new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(middleWare, httpLink),
});
