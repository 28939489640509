import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import HeaderComponent from '../../components/Layout/Header';
import Menu from '../Menu/Menu';

import { menuSetData } from '../../store/actions/menuActions';
import { selectMenu } from '../../store/selectors/menuSelector';

const Header = ({ menu, menuSetData, children }) => {
  const history = useHistory();
  return (
    <HeaderComponent 
      leftFn={() => history.push('/')} 
      rightFn={() => menuSetData(!menu)}
    >
       {menu && <Menu />}
       {children}
    </HeaderComponent>
  );
}

const mapStateToProps = createStructuredSelector({
  menu: selectMenu,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      menuSetData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
