import { gql } from '@apollo/client';

export function fetchHurdle(id, projectId) {
  return gql`
    query {
      fetchHurdle(id: ${id}, projectId: "${projectId}") {
        id
        projectId
        name
        caption
        displayOrder
        projectHurdleStatus
        hurdleType
        hurdleEyebrow
        tierType
        totalPoints
        earnedPoints
        scoringRate
        bypassSuffix
        substops {
          id
          name
          caption
          displayOrder
          duration
          substopType
          tierType
          status
          totalPoints
          earnedPoints
          scoringRate
          goUrl
        }
      }
    }`;
}

export const nextPreviousHurdle = (id, projectId, key) => gql`
  query {
    nextPreviousHurdle (hurdleId: ${id}, projectId: ${projectId}, key: "${key}") {
      id
      projectId
      name
      caption
      displayOrder
      projectHurdleStatus
      tierType
      hurdleType
      totalPoints
      earnedPoints
      scoringRate
      substops {
        id
        name
        caption
        displayOrder
        duration
        substopType
        tierType
        status
      }
    }  
  }
`;
