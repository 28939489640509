import { gql } from '@apollo/client';
import { get } from 'lodash';

export const ProjectFragment = gql`
  fragment project on CreateProjectPayload {
    project {
      id
      parentTrackId
      name
      description
      launchpadName
      whoCanIInvite
    }
  }
`;

export const UpdateProjectFragment = gql`
  fragment project on UpdateProjectPayload {
    project{
      id
      parentTrackId
      name
      description
      launchpadName
      whoCanIInvite
    }
  }
`;

export const createProjectMutation = ({
  projectName,
  projectDescription,
  parentTrackId,
  whoCanIInvite,
}) => {
  return gql`
    mutation{
      createProject(
        input: {
          params: {
            name: "${projectName}",
            parentTrackId: "${parentTrackId}",
            description: """${projectDescription}""",
            whoCanIInvite: """${whoCanIInvite}""",
          }
        }
      ){
        errors
        ...project
        success
      }      
    }
    ${ProjectFragment}
  `;
};

export const updateProjectMutation = ({
  name,
  description,
  projectId,
  whoCanIInvite,
}) => {
  return gql`
    mutation{
      updateProject(
        input: {
          params: {
            name: "${name}",
            projectId: "${projectId}",
            description: """${description}""",
            whoCanIInvite: """${whoCanIInvite}""",
          }
        }
      ){
        errors
        ...project
        success
      }      
    }
    ${UpdateProjectFragment}
  `;
};

export const getProjectFormData = gql`
  query {
    projectFormData {
      outputTypes
      improvementAreas
    }
  }
`;

export const getProjects = (id) => gql`
  query {
    fetchProjects(parentTrackId: ${id}) {
      id
      name
      description
      startDate
      launchpadName
      totalPoints
      earnedPoints
      scoringRate
    }
  }
`;


export const projectPayloadFragment = gql`
  fragment project on UpdateActiveProjectPayload {
    project {
      name
      description
      active
      numerator
      denominator
      startDate
      launchpadName
    }
  }
`;

export const setActiveProject = (projectId) => {
  return gql`
    mutation {
      updateActiveProject (
          input: {
            projectId: "${projectId}"
          }
        ) {
          ...project
        }
      }
    ${projectPayloadFragment}  
  `;
}


//TODO
export const getProjectDetail = (id) => gql`
  query {
    fetchProjectDetail(projectId: ${id}) {
      id
      answer
      numerator
      denominator
      status
      project {
        id
        name
        description
        startDate
        launchpadName
        collaborators {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const projectDetailFragment = gql`
  fragment projectDetail on UpsertProjectDetailPayload {
    projectDetail{
      id
      answer
      numerator
      denominator
      status
      project {
        id
        name
        description
        startDate
        launchpadName
        collaborators {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const postProjectDetail = (params) => {
  const projectId = get(params, 'projectId', '');
  const zoneId = get(params, 'zoneId', '');
  const details = get(params, 'details', '');
  let formattedAttachments = '';
  details.map(item => {
    formattedAttachments += `{ hurdle_id: "${item.hurdle_id}", substop_id: "${item.substop_id}", question_id: "${item.question_id}", answer: """${item.answer}""", notes: """${item.notes}""" },`;
  });
  try {
    const query =  gql`
        mutation {
          upsertProjectDetail(
            input: {
              params: {
              projectId: ${projectId},
              answer: {
                hurdle_zone_id: ${zoneId},
                details: [${formattedAttachments}]
              }
            }
          }
        ) {
            errors
            ...projectDetail
            success
          }
        }
        ${projectDetailFragment}
      `;
    return query;
  } catch (e) {
    console.error(e);
  }
}

export const showProject = (id) => {
  return gql`
    query {
      showProject(id: ${id}) {
        id
        name
        description
        numerator
        denominator
        startDate
        parentTrackId
        launchpadName
        whoCanIInvite
        collaborators {
          firstName
          lastName
          email
        }
        registeredUsers {
          id
          email
          firstName
          lastName
          projectOwner
        }
        nonRegisteredUsers  {
          email
        }
      }
    }
  `;
}

