import React from 'react';
import { Avatar as ImgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    backgroundColor: '#595959',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  initials: {
    fontWeight: '600',
    fontSize: '17px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'capitalize',
  },
}));

export default function Avatar({ user, email = '', registered = true, className }) {
  const classes = useStyles();

  const firstName = get(user, 'firstName', ' ');
  const lastName = get(user, 'lastName', ' ');
  const displayName = `${firstName}${lastName}`;
  const avatarImg = get(user, 'avatarUrl', '');

  const avIcon = avatarImg 
      ? (<ImgIcon src={avatarImg} />) 
      : (<div className={classes.initials}>{displayName.substring(0, 2)}</div>)

  return (
    <div className={[classes.wrapper, className].join(' ')}>
      {registered 
        ? avIcon
        : (<div className={classes.initials}>{email.substring(0, 2)}</div>)
      }
    </div>
  );
}
