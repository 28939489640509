import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, isEmpty, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  selectIsUserAuthenticated,
  selectParentTrack,
} from '../../../store/selectors';
import Layout from '../../../components/Layout';
import Header from '../../Header';
import { BackLink } from '../../../components/Links';
import SubStop from '../components/SubStop';
import Loading from '../../../components/Loading/Loading';
import SomethingWentWrong from '../../../components/Error/SomethingWentWrong';
import NotFound from '../../../components/Error/NotFound';
import { LinearProgress } from '../../../components/LinearProgress';

import { client, fetchHurdle } from '../../../api';
import classes from '../hurdles.module.scss';

const getRedirectPath = ({
  parentPath,
  projectId,
  zoneId,
  hurdleId,
  substopId,
  substopType
}) => {
  switch (substopType) {
    case 'prework':
      return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/prework`;
    case 'solo_workout':
      return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/solo_workout`;
    case 'peer_exchange':
      return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/peer_exchange`;
    case 'hurdle_consensus':
      return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/hurdle_consensus`;
    default:
      return '';
  }
}

function Hurdle({ isUserAuthenticated, parent }) {

  let { hurdleId, zoneId, projectId, substopId } = useParams();
  const parentTrack = parent && parent.find((p) => p.isActive);
  const path = get(parentTrack, 'path', '/');
  const parentPath = path === '/' ? '/mission-control' : path;
  const history = useHistory();
  const [expandedSubstopIndex, setExpandedSubstopIndex] = useState(-1);
  const [hurdleInfo, setHurdleInfo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hurdleErrorMessage, setHurdleErrorMessage] = useState(undefined);

  const getHurdle = (id) => {
    client()
      .query({
        query: fetchHurdle(id, projectId),
      })
      .then((response) => {
        if (get(response, 'networkStatus', 7) === 8) {
          setHurdleErrorMessage('Error in fetching hurdles');
          setLoaded(true);
          setError(true);
          return;
        }
        const detail = get(response, 'data.fetchHurdle', null);
        if (!isEmpty(detail)) {
          setHurdleInfo(detail);
          const substops = get(detail, 'substops', []);
          if (!isEmpty(substops) && substops?.length === 1) {
            const substopType = get(substops, '[0].substopType', '');
            const substopId = get(substops, '[0].id', '');
            const redirect = getRedirectPath({ parentPath, projectId, zoneId, hurdleId, substopId, substopType });
            if (substopId && substopType && redirect) {
              history.push(redirect);
            }
          }
        }
        setLoaded(true);
      })
      .catch((e) => {
        setHurdleErrorMessage('Error in fetching hurdles');
        setLoaded(true);
        const err = JSON.parse(JSON.stringify(e)) || null;
        const message = get(err, 'message', '');
        if (message?.includes('not authorized')) {
          setErrorMessage(message);
        } else {
          setError(true);
        }
        console.error(e);
      });
  };

  useEffect(() => {
    getHurdle(hurdleId);
  }, []);

  if (!isUserAuthenticated) {
    history.push('/login');
  }

  let renderPage = null;

  if (error) {
    renderPage = (
      <Layout>
        <Header>
          <BackLink to={path} className={classes.hurdleBackLink}>Back</BackLink>
        </Header>
        <div className={classes.hurdlePageWrapper}><SomethingWentWrong /></div>
      </Layout>
    );
    return renderPage;
  }

  if (errorMessage) {
    renderPage = <NotFound message={errorMessage} url="/launchpad"/>;
    return renderPage;
  }

  if (!loaded) {
    renderPage = (
      <div style={{ marginTop: 50 }}>
        <Loading size="5rem" />
      </div>
    );
    return renderPage;
  }

  renderPage = hurdleInfo && (
    <>
      <div className={classes.hurdleDetails}>
        <div className={classes.hurdleDetailsContainer}>
          <h2>{get(hurdleInfo, 'hurdleEyebrow', '')}</h2>
          <h3>{get(hurdleInfo, 'name', '')}</h3>
          <p dangerouslySetInnerHTML={{ __html: get(hurdleInfo, 'caption', '') }}></p>
          {hurdleInfo.totalPoints !== 0 && <div className={classes.scoreSection}>
            <LinearProgress
              earnedPoints={get(hurdleInfo, 'earnedPoints', 0)}
              totalPoints={get(hurdleInfo, 'totalPoints', 0) || 100}
              scoringRate={get(hurdleInfo, 'scoringRate', 0) || 2}
              bypassSuffix={get(hurdleInfo, 'bypassSuffix', '')}
              subText="CREDS"
              color="#FFF"
              bgColor="rgba(228, 228, 228, 0.24)"
            />
          </div>}
        </div>
      </div>
      <div className={classes.subStopsWrapper}>
        {sortBy(get(hurdleInfo, 'substops', []), (item) =>
          get(item, 'displayOrder')
        ).map((substop, index) => (

          <SubStop
            hurdleName={get(hurdleInfo, 'name', '')}
            data={substop}
            hurdleId={parseInt(hurdleId, 10)}
            zoneId={parseInt(zoneId, 10)}
            projectId={parseInt(projectId, 10)}
            substopId={parseInt(substopId, 10)}
            key={`${get(substop, 'name')}-${get(substop, 'displayOrder')}`}
            expanded={expandedSubstopIndex === index}
            onExpandStop={() => {
              if (expandedSubstopIndex !== index) {
                setExpandedSubstopIndex(index);
              } else {
                setExpandedSubstopIndex(-1);
              }
            }}
          />
        ))}
      </div>
    </>
  );

  return (
    <Layout>
      <Header>
        <BackLink to={path} className={classes.hurdleBackLink}>Back</BackLink>
      </Header>
      <div className={classes.hurdlePageWrapper}>{renderPage}</div>
    </Layout>
  );
}

const mapStateToProps = createStructuredSelector({
  isUserAuthenticated: selectIsUserAuthenticated,
  parent: selectParentTrack,
});

export default connect(mapStateToProps)(Hurdle);
