import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { REQUEST_STATUS } from '../../utility/constants';

import ProjectForm from './components/ProjectForm';
import InviteCollaboratorsForm from './components/InviteCollaboratorsForm';

import Layout, { TitleHead } from '../../components/Layout';
import Header from '../Header';
import BackButton from '../../components/Button/BackButton';

// selectors
import {
  selectRequestStatus,
  selectProjectData,
  selectProjectErrors
} from '../../store/selectors/projectSelectors';
// actions
import { createProject, setProjectData, updateProject, setOperationStatus } from '../../store/actions/projectActions';

import { client, getProjectDetail, showProject } from '../../api';

import classes from './project.module.scss';
import SomethingWentWrong from '../../components/Error/SomethingWentWrong';

function CreateProject({
  requestStatus,
  createProject,
  projectData,
  parentTrackId,
  setProjectData,
  updateProject,
}) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const isSubmittingForm = useRef(false);
  const projectId = projectData?.id || '';
  const edit = !!id && pathname?.includes('project/edit');
  const title = edit ? 'Edit Launchpad' : 'Start a Launchpad';
  const backText = edit ? 'Back' : 'Cancel';
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const fetchProjectDetail = async (projectid) => {
    setLoaded(false);
    await client()
      .query({
        query: showProject(projectid),
      }) 
      .then((result) => {
        if (get(result, 'networkStatus', 7) === 8) {
          setErrorMessage('Error in fetching project');
          setLoaded(true);
          setError(true);
          return;
        }
        const details = get(result, 'data.showProject', null);
        if (details) {
          setProjectData(details);
        }
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setError(true);
      });
  };

  useLayoutEffect(() => {
    if (edit) {
      const runAsync = async () => {
        await fetchProjectDetail(id);
      };
 
      runAsync();
    }
  }, []);

  useEffect(() => {
    if (
      isSubmittingForm.current &&
      get(requestStatus, 'status') === REQUEST_STATUS.DONE &&
      projectId
    ) {
      if(edit) {
        history.push(
          `/launchpad`
        );
      } else {
        history.push(
          `/launchpad/project/${projectId}/invite-collaborators?creating-project=1`
        );
      }
    }
    if (get(requestStatus, 'status') === REQUEST_STATUS.ERROR) {
      isSubmittingForm.current = false;
    }
  }, [isSubmittingForm, requestStatus, projectId]);

  const onSubmitProjectForm = (projectData) => {
    isSubmittingForm.current = true;
    if(edit) {
      updateProject({
        name: projectData.projectName,
        description: projectData.projectDescription,
        projectId: id,
        whoCanIInvite: projectData.whoCanIInvite,
      });
    } else {
      createProject(projectData);
    }
  };

  let content = null;

  if (error) {
    content = (<SomethingWentWrong message={errorMessage} />);
  }

  if ((loaded && !error) || !edit) {
    content = (<ProjectForm
      onSubmit={onSubmitProjectForm}
      parentTrackId={parentTrackId}
      edit={edit}
    />);
  }

  return (
    <Layout>
      <Header />
      <TitleHead
        title={title}
        hideProgress="true"
        style={{ height: 'auto', backgroundColor: error ? '#f8f8f8' : '' }}
      >
        <div className={classes.topNavWrapper} style={{ marginBottom: `16px` }}>
          <BackButton to={'/launchpad'} label={backText} />
        </div>
      </TitleHead>
      {content}
    </Layout>
  );
}

CreateProject.propTypes = {};

const mapStateToProps = createStructuredSelector({
  requestStatus: selectRequestStatus('projectData'),
  projectData: selectProjectData,
  projectDataErrors: selectProjectErrors
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProject,
      updateProject,
      setProjectData,
      setOperationStatus
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
