import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import Home from '../containers/Home/Home';
import StyleGuide from '../containers/StyleGuide/StyleGuide';
import Login from '../containers/User/Login/LoginContainer';
import Logout from '../containers/User/Logout/LogoutContainer';
import Signup from '../containers/User/CreateAccount/CreateAccount';
import Profile from '../containers/User/Profile';
import ResetPassword from '../containers/User/ResetPassword/ResetPasswordContainer';
import Hurdles from '../containers/Hurdles/Hurdles';
import NotFound from '../components/Error/NotFound';
import MissionControl from '../containers/MissionControl/MissionControl';
import Launchpad from '../containers/Launchpad/Launchpad';
import CreateProject from '../containers/Projects/CreateProject';
import InviteCollaborators from '../containers/Projects/InviteCollaborators';
import AuthWrapper from '../containers/AuthWrapper/AuthWrapper';
import Glossary from '../containers/Glossary';
import Paywall from '../containers/Paywall';
import LaunchpadExample from '../containers/LaunchpadExample';

import { HOME_ROUTES } from '../utility';

const Routes = () => {
  return (
    <Switch>
      <Route path={'(.)+/glossary'}>
        <AuthWrapper>
          <Glossary />
        </AuthWrapper>
      </Route>
      <Route path="/login" component={Login} exact />
      <Route path="/forgot-password" component={Login} exact />
      <Route path="/logout" component={Logout} exact />
      <Route path="/signup" component={Signup} exact />
      <Route path="/signup/privacy-policy" component={Signup} exact />
      <Route path="/signup/terms-of-use" component={Signup} exact />
      <Route path="/reset-password/:resetToken" component={ResetPassword} />
      <Route path="/profile" component={Profile} exact />
      <Route path="/styleguide" component={StyleGuide} exact />
      <Route path="/invite-collaborators" component={Home} />
      <Route path="/hurdle-zero" component={Home} />
      <Route path="/paywall" component={Paywall} />
      <Route path="/example" component={LaunchpadExample} />

      <Route
        path="/launchpad/:id/list"
        component={() => (
          <AuthWrapper>
            <Launchpad />
          </AuthWrapper>
        )}
      />
      <Route
        path={['/launchpad/project/create', '/launchpad/project/edit/:id']}
        component={() => (
          <AuthWrapper>
            <CreateProject parentTrackId="3" />
          </AuthWrapper>
        )}
      />
      <Route
        path="/launchpad/project/:projectId/invite-collaborators"
        component={() => (
          <AuthWrapper>
            <InviteCollaborators />
          </AuthWrapper>
        )}
      />
      {HOME_ROUTES.map((item, index) => {
        return (
          <Route
            key={item + index}
            path={`${item}/:projectId/zones/:zoneId/hurdles/:hurdleId`}
            component={() => (
              <AuthWrapper>
                <Hurdles />
              </AuthWrapper>
            )}
          />
        );
      })}
      <Route
        path={HOME_ROUTES}
        component={() => (
          <AuthWrapper>
            <Home />
          </AuthWrapper>
        )}
        exact
      />
      <Route
        path="/"
        component={() => (
          <AuthWrapper>
            <Home />
          </AuthWrapper>
        )}
        exact
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
