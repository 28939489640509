import React, { useEffect, useRef, useState } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { isEmpty, get, groupBy } from "lodash";
import { parseGlossaryShortcodes } from "../../../../utility/helpers";
import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import classes from "../../../Hurdles/SubStops/SoloWorkout/soloWorkout.module.scss";
import QuestionTypes from "../../../Substops/components/Section/QuestionTypes/QuestionTypes";
import ReactHTMLParser, { convertNodeToElement } from "react-html-parser";
import { useLocation } from "react-router-dom";
import GlossaryModal from "../../../Glossary/GlossaryModal";
import { ToastContainer } from "react-toastify";
import useProjectDetails from "./useProjectDetails";
import SoloWorkoutsV2 from "./SoloWorkoutsV2";

// const ACCEPTS_MULTIPLE = ["Modal Checkbox", "Inline-Checkbox"];

const questionDetailsFragment = gql`
  fragment questionDetails on Question {
    allowNotes
    answerOptions {
      description
      displayOrder
      id
      identifier
      status
      title
      value
    }
    clusterDisplayOrder
    clusterId
    customSectionDisplayOrder
    customSectionId
    description
    displayOrder
    id
    identifier
    maxOptionSelection
    placeholder
    points
    preferedAnswer
    projectId
    questionType
    questionTypeId
    status
    title
  }
`;

const soloWorkoutDetailsFragment = gql`
  fragment soloWorkoutDetails on SoloWorkout {
    id
    name
    displayOrder
    duration
    earnedPoints
    goUrl
    name
    scoringRate
    totalPoints
    visibleToEvent
    substopType
    earnedPoints
    status
    tierType
    hurdle {
      id
      name
    }
    hurdleZone {
      id
      name
    }
    questions {
      ...questionDetails
      subQuestions {
        ...questionDetails
      }
    }
  }
`;

const FETCH_PARENT_TRACK = gql`
  query FetchParentTrack($fetchParentTrackId: ID!, $projectId: ID!) {
    parentTrack: fetchParentTrack(
      id: $fetchParentTrackId
      projectId: $projectId
    ) {
      id
      title
      sortOrder
      subscription
      imageUrl
      hurdleZones {
        id
        hurdles {
          id
          name
        }
      }
      hurdleConsensuses {
        ...soloWorkoutDetails
      }
      soloWorkouts {
        ...soloWorkoutDetails
      }
    }
  }

  ${questionDetailsFragment}
  ${soloWorkoutDetailsFragment}
`;

const projectDetailFragment = gql`
  fragment projectDetail on UpsertProjectDetailPayload {
    projectDetail {
      id
      answer
      numerator
      denominator
      status
      project {
        id
        name
        description
        startDate
        launchpadName
        isProjectOwner
        collaborators {
          id
          email
          firstName
          lastName
          __typename
        }
      }
    }
  }
`;

export const consensusProjectDetailFragment = gql`
  fragment projectDetail on SubmitHurdleConsensusPayload {
    projectDetail {
      id
      hurdleConsensus
      status
    }
  }
`;

const UPDATE_PROJECT_DETAILS = gql`
  mutation UpdateProjectDetails($input: UpsertProjectDetailInput!) {
    upsertProjectDetail(input: $input) {
      errors
      success
      ...projectDetail
    }
  }

  ${projectDetailFragment}
`;

const UPDATE_HURDLE_CONSENSUS = gql`
  mutation UpdateHurdleConsensus($input: SubmitHurdleConsensusInput!) {
    submitHurdleConsensus(input: $input) {
      errors
      success
      ...projectDetail
    }
  }

  ${consensusProjectDetailFragment}
`;

function transform(node, index) {
  if (node.name === "span" && node.attribs.class === "modal-span") {
    return (
      <GlossaryModal
        key={node.attribs.identifier}
        identifier={node.attribs.identifier}
        type={node.attribs.type}
        label={node.attribs.label}
      />
    );
  }

  // all links must open in a new window
  if (node.name === "a") {
    node.attribs.target = "_blank";
    return convertNodeToElement(node, index, transform);
  }
}

function transformAnswer(type, a, answerOptions) {
  let answer = "";
  const savedAnswer = a;
  switch (type) {
    case "Horizontal Rate":
    case "Inline Checkbox":
      answer = a?.answer;
      break;

    case "Modal Checkbox":
    case "Multi-select": {
      const arr =
        !isEmpty(savedAnswer?.answer) &&
        savedAnswer?.answer?.includes(",") &&
        savedAnswer?.answer?.split(",");
      if (!isEmpty(savedAnswer?.answer)) {
        answer = arr || [`${savedAnswer?.answer}`];
      } else {
        answer = [];
      }
      break;
    }
    case "Radio Buttons": {
      answer = !!savedAnswer ? savedAnswer?.answer : "";
      break;
    }
    default: {
      answer = get(savedAnswer, "answer", "");
      break;
    }
  }
  return {
    answer: answer,
    notes: !!savedAnswer ? (savedAnswer?.notes ?? "") : "",
  };
}
function transformAnswerForDisplay(type, a, answerOptions) {
  let answer = "";
  const savedAnswer = a;
  switch (type) {
    case "Horizontal Rate": {
      if (typeof savedAnswer?.answer !== "string") {
        answer = answerOptions.map((ans) => ({
          ...ans,
          isSelected: false,
        }));
      } else {
        answer = answerOptions.map((ans) => {
          const isSelected = savedAnswer?.answer === ans.id;
          return {
            ...ans,
            selected: isSelected,
          };
        });
      }

      break;
    }
    case "Inline Checkbox": {
      answer = answerOptions.map((ans) => {
        let selections = [];
        if (typeof savedAnswer?.answer !== "string") {
          // reset answer
          selections = [];
        } else {
          selections = savedAnswer?.answer?.split(",") ?? [];
        }
        const isSelected = selections.includes(ans.value);
        return {
          ...ans,
          selected: isSelected,
        };
      });
      break;
    }
    case "Modal Checkbox":
    case "Multi-select": {
      const arr =
        !isEmpty(savedAnswer?.answer) &&
        savedAnswer?.answer?.includes(",") &&
        savedAnswer?.answer?.split(",");
      if (!isEmpty(savedAnswer?.answer)) {
        answer = arr || [`${savedAnswer?.answer}`];
      } else {
        answer = [];
      }
      break;
    }
    case "Radio Buttons": {
      answer = !!savedAnswer ? savedAnswer?.answer : "";
      break;
    }
    default: {
      answer = get(savedAnswer, "answer", "");
      break;
    }
  }
  return {
    answer: answer,
    notes: !!savedAnswer ? (savedAnswer?.notes ?? "") : "",
  };
}

export function QuestionTitle({ title, pathname }) {
  return ReactHTMLParser(
    parseGlossaryShortcodes(title, `${pathname}/glossary`),
    {
      decodeEntities: true,
      transform,
    },
  );
}

function QuestionTypesContainer({
  q,
  initialAnswer = null,
  disabled,
  fieldName,
  readOnly,
  isConsensus,
}) {
  const [a, setAnswer] = useState(initialAnswer);

  function handleChange(e, questionId) {
    let answer = e?.target?.value;

    switch (q.questionType) {
      case "Horizontal Rate":
        answer = e?.currentTarget?.dataset?.value;
        break;
      case "Inline Checkbox":
        const currentAnswers = a.answer
          .filter((a) => a.selected)
          .map((a) => a.value);

        const selections = new Set(currentAnswers);
        if (selections.has(answer)) {
          selections.delete(answer);
        } else {
          selections.add(answer);
        }
        answer = Array.from(selections).join(",");
        break;
      case "Modal Checkbox":
      case "Multi-select":
        answer = e.slice();
        break;
      default:
        answer = e?.target?.value;
        break;
    }

    const updatedAnswer = transformAnswerForDisplay(
      q.questionType,
      { answer: answer },
      q.answerOptions,
    );
    setAnswer(updatedAnswer);
  }

  return (
    <QuestionTypes
      questionId={q.id}
      name={fieldName}
      questionType={q.questionType}
      answerOptions={q.answerOptions}
      answer={a?.answer}
      label={q.description}
      fn={handleChange}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

function SoloWorkoutFormContainer({
  id,
  hurdleId,
  hurdleZoneId,
  data,
  disabled,
  readOnly,
  isConsensus,
}) {
  const { pathname } = useLocation();
  const { questions } = data;

  return (
    <>
      {questions
        .map((q) => ({
          ...q,
          answer: isConsensus ? q.hcAnswer : q.answer,
        }))
        .map((q) => {
          const fieldName = `${id}-${hurdleId}-${q.id}`;

          const transformedInitialAnswer = transformAnswerForDisplay(
            q.questionType,
            q.answer,
            q.answerOptions,
          );

          if (q.questionType.includes("Keywords")) {
            return null;
          }
          return (
            <div
              key={q.id}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <QuestionTitle
                title={q.title}
                questionType={q.questionType}
                pathname={pathname}
              />
              {/* <div>{q.questionType}</div> */}
              <QuestionTypesContainer
                q={q}
                substopId={id}
                hurdleId={hurdleId}
                hurdleZoneId={hurdleZoneId}
                initialAnswer={transformedInitialAnswer}
                fieldName={fieldName}
                disabled={disabled}
                readOnly={readOnly}
                isConsensus={isConsensus}
              />
              {q?.allowNotes && (
                <Notes
                  fieldName={fieldName}
                  disabled={readOnly}
                  readOnly={readOnly}
                  initialValue={transformedInitialAnswer?.notes}
                  isConsensus={isConsensus}
                />
              )}
            </div>
          );
        })}
    </>
  );
}

function Notes({ fieldName, disabled, readOnly, initialValue }) {
  const [notes, setNotes] = useState(initialValue);
  function handleChange(e) {
    setNotes(e.target.value);
  }
  return (
    <TextField
      fullWidth
      label="Notes"
      variant="outlined"
      placeholder="Notes"
      multiline
      minRows={7}
      maxRows={7}
      value={notes}
      onChange={handleChange}
      name={`${fieldName}-notes`}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

function CallToActionFooter({ displayText, loading, isOwner }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        backgroundColor: "rgb(200,200,200)",
        padding: "1rem",
        alignItems: "center",
        position: "sticky",
        bottom: 0,
        zIndex: 10,
      }}
    >
      <span>{displayText}</span>
      <Button
        variant="contained"
        color="primary"
        disabled={loading || !isOwner}
        style={{
          width: "fit-content",
          marginLeft: "auto",
          //   visibility: "hidden",
        }}
        type="submit"
      >
        {loading ? (
          <>
            <CircularProgress size={12} style={{ color: "white" }} />
            <span style={{ marginLeft: "0.5rem" }}>Saving...</span>
          </>
        ) : (
          "Save"
        )}
      </Button>
      {/* <SaveAll loading={loading} /> */}
    </div>
  );
}

function ParentTrack({
  projectId,
  title,
  caption,
  soloWorkouts,
  isConsensus,
  isOwner,
}) {
  const allHurdleZones = soloWorkouts.flatMap((s) => s.hurdleZone);
  const groupedSoloWorkouts = groupBy(soloWorkouts, "hurdleZone.id");
  const hurdleZones = Object.keys(groupedSoloWorkouts).map((hurdleZoneId) =>
    allHurdleZones.find((h) => h.id === hurdleZoneId),
  );

  return (
    <div className={classes.soloWorkoutPage__content}>
      <h2>{title}</h2>
      {caption && (
        <div
          dangerouslySetInnerHTML={{ __html: caption }}
          style={{ marginBottom: "1rem" }}
        />
      )}
      {hurdleZones.map((hurdleZone) => {
        const nestedSoloWorkouts = groupedSoloWorkouts[hurdleZone.id];
        return isConsensus ? (
          <SoloWorkoutsV2
            key={`${hurdleZone.id}-consensus`}
            hurdleZone={hurdleZone}
            soloWorkouts={nestedSoloWorkouts}
            projectId={projectId}
            isOwner={isOwner}
            isConsensus
          />
        ) : (
          <SoloWorkoutsV2
            key={`${hurdleZone.id}-soloworkout`}
            hurdleZone={hurdleZone}
            soloWorkouts={nestedSoloWorkouts}
            projectId={projectId}
            isOwner={isOwner}
          />
        );
      })}
    </div>
  );
}

function SoloWorkoutsView({ projectId, parentTrackId, isConsensus, isOwner }) {
  const { data, loading } = useQuery(FETCH_PARENT_TRACK, {
    variables: {
      fetchParentTrackId: parentTrackId,
      projectId,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false,
  });

  const parentTrack = data?.parentTrack ?? {};
  const soloWorkouts = parentTrack?.soloWorkouts ?? [];
  const hurdleConsensuses = parentTrack?.hurdleConsensuses ?? [];

  return (
    <div
      className={classes.soloWorkoutPageWrapper}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        height: "auto",
      }}
    >
      {loading && (
        <div style={{ padding: "1rem" }}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <ParentTrack
          id={parentTrack.id}
          caption={parentTrack.caption}
          projectId={Number(projectId)}
          title={parentTrack.title}
          soloWorkouts={isConsensus ? hurdleConsensuses : soloWorkouts}
          isConsensus={isConsensus}
          isOwner={isOwner}
        />
      )}
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function TrackView({ projectId, parentTracks }) {
  const { projectDetails } = useProjectDetails(projectId);
  const isOwner = projectDetails?.project?.isProjectOwner;
  const [value, setIndex] = useState(0);
  const pt = parentTracks.find((p) => p.title?.toLowerCase() === "launchpad");
  return (
    <>
      <ToastContainer />
      <Box>
        <Tabs value={value}>
          <Tab label="Solo Workouts" onClick={() => setIndex(0)} />
          <Tab label="Hurdle Consensus" onClick={() => setIndex(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <SoloWorkoutsView
          projectId={projectId}
          parentTrackId={pt.id}
          isOwner={isOwner}
          key="soloworkouts"
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SoloWorkoutsView
          projectId={projectId}
          parentTrackId={pt.id}
          isConsensus
          isOwner={isOwner}
          key="consensus"
        />
      </CustomTabPanel>
    </>
  );
}

function SaveAll({ loading }) {
  function handleClick() {
    const allButtons = document.querySelectorAll('button[type="submit"]');
    allButtons.forEach((button) => {
      button.click();
    });
  }

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={handleClick}
    >
      {loading ? (
        <>
          <CircularProgress size={12} style={{ color: "white" }} />
          <span style={{ marginLeft: "0.5rem" }}>Saving...</span>
        </>
      ) : (
        "Save"
      )}
    </Button>
  );
}

export default TrackView;
