import React, { useState, useLayoutEffect } from 'react';
import { get } from 'lodash';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import classes from './premiumBanner.module.scss';
import LockIcon from '../../../assets/images/lock.png';

import { client, fetchUserSubscription } from '../../../api';

const subscriptionLevel = (subscription) => {
  switch (subscription) {
    case 'premium': {
      return 1; 
    }
    case 'premium_plus': {
      return 2;
    }
    default: {
      return 0;
    }
  }
}

const PremiumBanner = () => {
  const history = useHistory();
  const [hidePremium, setHidePremium] = useState(false);
  const [subscription, setSubscription] = useState(0);

  useLayoutEffect(() => {
    client()
      .query({
        query: fetchUserSubscription(),
      })
      .then(response => {
        const sub = get(response, 'data.fetchUserSubscription.subscription', 'free');
        const level = subscriptionLevel(sub);
        setSubscription(level);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  return subscription <= 0 
    ? (
      <div>
        {!hidePremium && <div className={classes.premiumContainer}>
          <Button
            variant="contained"
            startIcon={<img src={LockIcon} />}
            size="small"
            style={{
              background: '#1F58C1',
              color: '#FFF',
              borderRadius: '8px',
              boxShadow: 'none',
              height: '30px',
              textTransform: 'none'
            }}
            onClick={e => {
              e.stopPropagation();
              history.push('/paywall');
              // OPEN PREMIUM MODAL
            }}
          >
            Unlock my Premium Access
          </Button> 
          <div className={classes.premiumCollapse} onClick={e => {
              setHidePremium(true);
            }}>
            <ArrowForwardIosIcon fontSize="inherit" />
          </div> 
        </div>}
        {hidePremium && <div className={classes.collapseContainer}>
          <div className={classes.collapse} onClick={e => {
              setHidePremium(false);
            }}>
            <ArrowBackIosIcon fontSize="inherit" />
          </div>
        </div>}
      </div>
    ) : null;
};
export default PremiumBanner;
