import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    marginTop: 50,
    maxWidth: '100%',
    display: 'flex',
    width: '100%',
  },
  navi: {
    color: '#276EF1',
    textDecoration: 'underline',
  }
}));

const NotFound = ({
  message = '404: The page you are looking for isn’t here',
  content = `You either tried some shady route or you came here by mistake.
Whichever it is, try using the navigation`,
  url = '/' 
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <h3>
              {message}
            </h3>
            <p>
              {content}
            </p>
            <Link to={url}><span className={classes.navi}>Click Here</span></Link>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/undraw_page_not_found_su7k.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
