import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import InviteCollaboratorsForm from './components/InviteCollaboratorsForm';
import CollaboratorList from './components/CollaboratorList';
import Layout, { TitleHead } from '../../components/Layout';
import Header from '../Header';
import BackButton from '../../components/Button/BackButton';
import { NextLink } from '../../components/Links';
import Loading from '../../components/Loading/Loading';
import SomethingWentWrong from '../../components/Error/SomethingWentWrong';

import { client, getCollaborators, inviteCollaborators } from '../../api';

import classes from './project.module.scss';

function InviteCollaborators({}) {
  const { projectId } = useParams();
  const urlQueryParams = new URLSearchParams(useLocation().search);
  const isCreatingProject = urlQueryParams.get('creating-project') !== null;
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [nonRegisteredUsers, setNonRegisteredUsers] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const fetchCollaborators = async (id) => {
    await client()
      .query({
        query: getCollaborators(id),
      })
      .then(result => {
        if (get(result, 'networkStatus', 7) === 8) {
          setErrorMessage('Error in fetching collaborators');
          setLoaded(true);
          setError(true);
          return;
        }
        const collaborators = get(result, 'data.fetchCollabs', null);
        if (collaborators) {
          const regUsers = get(collaborators, 'registeredUsers', []);
          const nonRegUsers = get(collaborators, 'nonRegisteredUsers', []);
          setRegisteredUsers(regUsers);
          setNonRegisteredUsers(nonRegUsers);
        }
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  const sendInvites = (projectId, emails) => {
    setLoaded(false);
    client()
      .mutate({
        mutation: inviteCollaborators(projectId, emails),
      })
      .then(response => {
        const inviteCollabs = get(response, 'data.inviteCollabs', null);
        if (inviteCollabs) {
          const regUsers = get(inviteCollabs, 'registeredUsers', []);
          const nonRegUsers = get(inviteCollabs, 'nonRegisteredUsers', []);
          setRegisteredUsers(regUsers);
          setNonRegisteredUsers(nonRegUsers);
        }
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  }

  useEffect(() => {
    setLoaded(false);
    const runAsync = async () => {
      await fetchCollaborators(projectId);
    };
    runAsync();
  }, []);

  const onSubmitEmailList = (emails) => {
    if (emails.length) {
      sendInvites(projectId, emails);
    }
  };

  let inviteCollaboratorsArea = null;

  if (error) {
    inviteCollaboratorsArea = (
      // <Layout>
        <SomethingWentWrong message='' />
      // </Layout>
    );
    // return inviteCollaboratorsArea;
  }

  if (!loaded) {
    inviteCollaboratorsArea = (
      // <Layout>
        <div style={{ marginTop: 50 }}>
          <Loading size="5rem" />
        </div>
      // </Layout>
    );
    // return inviteCollaboratorsArea;
  }

  if (!error && loaded) {
    inviteCollaboratorsArea = (
      <><InviteCollaboratorsForm onSubmitEmailList={onSubmitEmailList} /><CollaboratorList collaborators={registeredUsers} nonRegisteredUsers={nonRegisteredUsers} /></>
    );
  }

  return (
    <Layout>
      <Header />
      <TitleHead
        title="Invite Collaborators"
        description={error ? '' : '2-6 people I want to team up with for this Launchpad'}
        hideProgress={true}
        style={{ height: `auto`, backgroundColor: error ? '#f8f8f8' : '' }}
      >
        <div className={classes.topNavWrapper} style={{ marginBottom: `16px`, textAlign: `right` }}>
          { !isCreatingProject && <BackButton to={'/launchpad'} label="Back" /> }
          { isCreatingProject && <NextLink to={'/launchpad'}>Continue</NextLink> }
        </div>
      </TitleHead>
      {inviteCollaboratorsArea}
    </Layout>
  );
}

export default InviteCollaborators;
