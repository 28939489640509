import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { login, signupWithFacebook, resetOperationStatus } from '../../../store/actions/userActions';
import {
  selectIsLoading,
  selectLoginErrors,
  selectIsUserAuthenticated,
} from '../../../store/selectors/userSelectors';

import SigninSignupWrapper from '../../../components/User/SigninSignupWrapper';
import LoginComponent from './LoginComponent';
import ForgotPasswordContainer from '../ForgotPassword/ForgotPasswordContainer';

import '../UserModuleStyles.scss';

function LoginContainer({
  login,
  isLoading,
  loginErrors,
  isAuthenticated,
  signupWithFacebook,
  resetOperationStatus
}) {
  const location = useLocation();
  const history = useHistory();
  const currentPath = get(location, 'pathname');

  useLayoutEffect(() => {
    resetOperationStatus();
  }, []);

  const onSubmit = (email, password) => {
    login(email, password);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);
  return (
    <SigninSignupWrapper>
      { currentPath === '/login' && <LoginComponent
        onSubmit={onSubmit}
        isLoading={isLoading}
        loginErrors={loginErrors}
        signupWithFacebook={signupWithFacebook}
      /> }
      { currentPath === '/forgot-password' && <ForgotPasswordContainer
        isVisible={get(location, 'pathname') === '/forgot-password'}
        onClose={() => history.push('/login')}
        onSubmit={onSubmit}
      /> }
      {/* { currentPath === '/forgot-password' && <ForgotPasswordContainer
        isVisible={get(location, 'pathname') === '/forgot-password'}
        onClose={() => history.push('/login')}
      /> } */}
    </SigninSignupWrapper>
  );
}

LoginContainer.propTypes = {
  login: PropTypes.func,
  isLoading: PropTypes.bool,
  loginErrors: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading('login'),
  loginErrors: selectLoginErrors,
  isAuthenticated: selectIsUserAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      signupWithFacebook,
      resetOperationStatus
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
