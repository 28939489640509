import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import MultiEmailInput from '../../../components/MultiEmailInput';
import CopyLinkButton from '../../../components/Button/CopyLinkButton';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formWrapper: {
    padding: '0 17px 20px 17px',
    backgroundColor: '#fff'
  },
  formRow: {
    paddingBottom: '17px',
  },
}));

function InviteCollaboratorsForm({ onSubmitEmailList }) {
  const classes = useStyles();

  return (
    <div className={classes.formWrapper}>
      <div className={classes.formRow}>
        <MultiEmailInput
          placeholder="Separated by commas"
          buttonLabel="Send Invite"
          onSubmit={onSubmitEmailList}
        />
      </div>
    </div>
  );
}

InviteCollaboratorsForm.propTypes = {
  onSubmitEmailList: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteCollaboratorsForm);
