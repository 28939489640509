import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  selectIsLoading,
  selectForgotPasswordErrors,
  selectRequestStatus,
} from '../../../store/selectors/userSelectors';
import { REQUEST_STATUS } from '../../../utility/constants';

import { forgotPassword, resetOperationStatus } from '../../../store/actions/userActions';
import ForgotPasswordComponent from './ForgotPasswordComponent';
import './ForgotPassword.scss';

function ForgotPasswordContainer({
  isVisible,
  onClose,
  isLoading,
  formErrors,
  sendForgotPassword,
  requestStatus,
  resetOperationStatus
}) {
  const history = useHistory();

  useLayoutEffect(() => {
    resetOperationStatus();
  }, []);

  useEffect(() => {
    if (get(requestStatus, 'status') === REQUEST_STATUS.DONE && get(requestStatus, 'operation') === 'forgotPassword') {
      setTimeout(() => {
        history.push('/logout');
      }, 3000);
    }
  }, [requestStatus]);

  const onSubmit = ({ email }) => {
    sendForgotPassword(email);
  };

  return (
    <ForgotPasswordComponent
      isVisible={isVisible}
      onClose={onClose}
      isLoading={isLoading}
      formErrors={formErrors}
      onSubmit={onSubmit}
      shouldShowSuccessToaster={
        get(requestStatus, 'status') === REQUEST_STATUS.DONE
      }
    />
  );
}

ForgotPasswordContainer.propTypes = {
  isVisible: PropTypes.bool,
  formErrors: PropTypes.object,
  requestStatus: PropTypes.object,
  sendForgotPassword: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading('forgotPassword'),
  formErrors: selectForgotPasswordErrors,
  requestStatus: selectRequestStatus('forgotPassword'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendForgotPassword: forgotPassword,
      resetOperationStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
  