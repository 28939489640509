import { createSelector } from 'reselect';
import { get } from 'lodash';
import { REQUEST_STATUS } from '../../utility/constants';

export const selectProjectSubstate = (state) => ({ ...get(state, 'project') });

export const selectProjectData = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'data') })
);

export const selectRequestStatus = (operation) =>
  createSelector([selectProjectSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus')
      : {}
  );

export const selectIsLoading = (operation) =>
  createSelector([selectProjectSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.status') === REQUEST_STATUS.PENDING
      : false
  );

export const selectOperationErrors = (operation) =>
  createSelector([selectProjectSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.errors')
      : null
  );

export const selectProjectErrors = createSelector(
  [selectOperationErrors('projectData')],
  (operationErrors) => operationErrors
);

export const selectWorkSubStopData = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'workSubStopData') })
);

export const selectSoloWorkoutProjectData = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'substops') })
);

export const selectPeerExchangeSubStopData = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'workSubStopData') })
);

export const selectHurdleConsensusSubStopData = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'substops') })
);

export const selectFormData = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'formData') })
);

export const selectSnackbar = createSelector(
  [selectProjectSubstate],
  (substate) => ({ ...get(substate, 'snackbar') })
);

export const selectCollaborators = createSelector(
  [selectProjectSubstate],
  (substate) =>
    get(substate, 'collaborators', []).map((collaborator) => {
      let displayName = get(collaborator, 'email');

      if (get(collaborator, 'firstName') || get(collaborator, 'lastName')) {
        displayName = `${get(collaborator, 'firstName', '')} ${get(
          collaborator,
          'lastName',
          ''
        )}`;
      }

      return {
        ...collaborator,
        displayName,
      };
    })
);

export const selectNonRegisteredUsers = createSelector(
  [selectProjectSubstate],
  (substate) => (get(substate, 'nonRegisteredUsers', []))
);
