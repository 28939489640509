import { takeLatest, all, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { REQUEST_STATUS } from '../../utility/constants';

import {
  login,
  setUserData,
  setOperationStatus,
  signup,
  forgotPassword,
  resetPassword,
  signupWithFacebook,
  logout,
  fetchUserProfile,
  setUserProfile,
  updateUserProfile,
} from '../actions/userActions';

import {
  loginMutation,
  signupMutation,
  forgotPasswordMutation,
  signinWithFBMutation,
  resetPasswordMutation,
  getUserProfile,
  updateProfile,
} from '../../api/Users';

import { isValidEmail } from '../../utility/helpers';

function* doLogin(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'login'));

  try {
    const result = yield apolloClient.mutate({
      mutation: loginMutation(payload.email, payload.password),
    });

    if (Object.keys(get(result, 'data.signIn.errors', {})).length) {
      yield put(
        setOperationStatus(
          REQUEST_STATUS.ERROR,
          'login',
          get(result, 'data.signIn.errors')
        )
      );
    } else {
      yield put(setOperationStatus(REQUEST_STATUS.DONE, 'login'));
      yield localStorage.setItem(
        'igniteToken',
        get(result, 'data.signIn.user.authenticationToken', null)
      );
      yield put(fetchUserProfile());
      yield put(setUserData(get(result, 'data.signIn.user')));
    }
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'forgotPassword', {
        EXCEPTION: e,
      })
    );
  }
}

function* doSignUp(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'signup'));
  const { userData, agreeToTerms } = payload;

  if (!agreeToTerms) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'signup', {
        terms: 'You must agree to the terms before you can sign up.',
      })
    );
  } else {
    try {
      const result = yield apolloClient.mutate({
        mutation: signupMutation(userData || {}),
      });

      if (Object.keys(get(result, 'data.signUp.errors', {})).length) {
        yield put(
          setOperationStatus(
            REQUEST_STATUS.ERROR,
            'signup',
            get(result, 'data.signUp.errors')
          )
        );
      } else {
        yield put(setOperationStatus(REQUEST_STATUS.DONE, 'signup'));
        yield localStorage.setItem(
          'igniteToken',
          get(result, 'data.signUp.user.authenticationToken', null)
        );
        yield put(fetchUserProfile());
        yield put(setUserData(get(result, 'data.signUp.user')));
      }
    } catch (e) {
      yield put(
        setOperationStatus(REQUEST_STATUS.ERROR, 'forgotPassword', {
          EXCEPTION: e,
        })
      );
    }
  }
}

function* doForgotPassword(apolloClient, { payload }) {
  if (!isValidEmail(payload)) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'forgotPassword', {
        email: 'Please enter a valid email',
      })
    );
  } else {
    yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'forgotPassword'));

    try {
      const result = yield apolloClient.mutate({
        mutation: forgotPasswordMutation({ email: payload }),
      });
      if (Object.keys(get(result, 'data.forgotPassword.errors', {})).length) {
        yield put(
          setOperationStatus(
            REQUEST_STATUS.ERROR,
            'forgotPassword',
            get(result, 'data.forgotPassword.errors', {})
          )
        );
      } else {
        yield put(
          setOperationStatus(
            REQUEST_STATUS.DONE,
            'forgotPassword',
            {}
          )
        );
        // yield put(setOperationStatus(REQUEST_STATUS.DONE, 'forgotPassword'));
        yield put(setUserData(get(result, 'data.forgotPassword.user')));
      }
    } catch (e) {
      yield put(
        setOperationStatus(REQUEST_STATUS.ERROR, 'forgotPassword', {
          EXCEPTION: e,
        })
      );
    }
  }
}

function* doSignupWithFacebook(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'signup'));

  try {
    const result = yield apolloClient.mutate({
      mutation: signinWithFBMutation(
        payload.email,
        payload.id,
        payload.first_name,
        payload.last_name,
        get(payload, 'picture.data.url')
      ),
    });

    if (Object.keys(get(result, 'data.facebookSignIn.errors', {})).length) {
      yield put(
        setOperationStatus(
          REQUEST_STATUS.ERROR,
          'signup',
          get(result, 'data.facebookSignIn.errors')
        )
      );
    } else {
      yield put(setOperationStatus(REQUEST_STATUS.DONE, 'signup'));
      yield localStorage.setItem(
        'igniteToken',
        get(result, 'data.facebookSignIn.user.authenticationToken', null)
      );
      yield put(fetchUserProfile());
      yield put(setUserData(get(result, 'data.facebookSignIn.user')));
    }
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'signup', {
        EXCEPTION: e,
      })
    );
  }
}

function* doResetPassword(apolloClient, { payload }) {
  const {
    password,
    passwordConfirmation,
    resetPasswordToken,
    setCookie,
  } = payload;

  if (!password) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'resetPassword', {
        password: 'Please enter a password',
      })
    );

    return;
  } else if (passwordConfirmation !== password) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'resetPassword', {
        passwordConfirmation: 'This must match the given password',
      })
    );

    return;
  }

  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'resetPassword'));

  try {
    const result = yield apolloClient.mutate({
      mutation: resetPasswordMutation({
        password,
        passwordConfirmation,
        resetPasswordToken,
      }),
    });
    if (get(result, 'errors', []).length) {
      yield put(
        setOperationStatus(
          REQUEST_STATUS.ERROR,
          'resetPassword',
          get(result, 'errors', []).pop()
        )
      );
    } else {
      yield put(setOperationStatus(REQUEST_STATUS.DONE, 'resetPassword'));
      yield put(setUserData(get(result, 'data.resetPassword.user')));
      yield localStorage.setItem(
        'igniteToken',
        get(result, 'data.resetPassword.user.authenticationToken', null)
      );
      yield window.location.replace(`${window.location.origin}/login`);
      yield setCookie(
        'AUTH_TOKEN',
        get(result, 'data.resetPassword.user.authenticationToken'),
        { path: '/' }
      );
    }
  } catch (e) {
    const err = JSON.parse(JSON.stringify(e));
    const message = get(err, 'message', e)
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'resetPassword', {
        EXCEPTION: message,
      })
    );
  }
}

function* doLogout() {
  yield localStorage.removeItem('igniteToken');
}

function* doFetchUserProfile(apolloClient) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'fetchProfile'));

  try {
    const result = yield apolloClient.query({
      query: getUserProfile(),
    });
    yield put(setUserProfile(get(result, 'data.showProfile')));
    yield put(setOperationStatus(REQUEST_STATUS.DONE, 'fetchProfile'));
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'fetchProfile', {
        EXCEPTION: e,
      })
    );
  }
}

function* doUpdateUserProfile(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'updateProfile'));
  try {
    const result = yield apolloClient.mutate({
      mutation: updateProfile(payload),
    });

    yield put(setUserProfile(get(result, 'data.updateProfile.profile')));
    yield put(setOperationStatus(REQUEST_STATUS.DONE, 'updateProfile'));
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'updateProfile', {
        EXCEPTION: e,
      })
    );
  }
}

export default function* userSaga(apolloClient) {
  yield all([
    takeLatest(login, doLogin, apolloClient),
    takeLatest(signup, doSignUp, apolloClient),
    takeLatest(forgotPassword, doForgotPassword, apolloClient),
    takeLatest(signupWithFacebook, doSignupWithFacebook, apolloClient),
    takeLatest(resetPassword, doResetPassword, apolloClient),
    takeLatest(fetchUserProfile, doFetchUserProfile, apolloClient),
    takeLatest(updateUserProfile, doUpdateUserProfile, apolloClient),
    takeLatest(logout, doLogout),
  ]);
}
