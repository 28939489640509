import React from 'react';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

import classes from './Link.module.scss';

export default function BackLink({ className, children, to }) {
  return (
    <Link
      to={to}
      className={[classes.link, classes.nextLink, className].join(' ')}
    >
      {children}
      <ChevronRightIcon />
    </Link>
  );
}

BackLink.defaultProps = {
  className: '',
};

BackLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
};
