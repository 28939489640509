import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import classes from './projectCard.module.scss';

const ProjectCard = ({ item, id }) => {
  let caption = get(item, 'description', '');
  const history = useHistory();
  const registeredUsers = get(item, 'collaborators.registeredUsers', []);
  const nonRegisteredUsers = get(item, 'collaborators.nonRegisteredUsers', []);
  const launchpadName = get(item, 'launchpadName', '');
  const avatars =
    registeredUsers.length &&
    registeredUsers.map((c, index) => {
      const avatarImg = get(c, 'avatarUrl', '');
      let initials = get(c, 'firstName[0]', '') + get(c, 'lastName[0]', '');
      const coordinates = index * -15;
      if (index > 4) {
        return null;
      }
      if (index === 4 && registeredUsers.length > 5) {
        initials = `+${registeredUsers.length - 5}`;
      }
      return (
        <Avatar
          key={`project-card-${initials}`}
          className={classes.cardAvatar}
          style={{
            backgroundColor: `#595959`,
            left: `${coordinates}px`,
          }}
          src={avatarImg ? avatarImg : null}
        >
          {initials}
        </Avatar>
      );
    });

  const toggleText = () => {
    var dots = document.getElementById('dots');
    var moreText = document.getElementById('more');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      moreText.style.display = 'inline';
    }
  };

  const checkStrLength = () => {
    console.log('CAPTION: ', caption);
    let strLess = caption.slice(0, 80);
    let strMore = caption.slice(80, caption.length);

    return (
      '<div class="trix-content">\n ' +
      `
        ${strLess}<span id="dots">... <span>See More</span></span>
        <span id="more">${strMore} <span>See Less</span></span>
        ` +
      '\n</div>\n'
    );
  };

  useEffect(() => {
    if (caption.length > 80) {
      document.getElementById('dots').addEventListener('click', toggleText);
      document.getElementById('more').addEventListener('click', toggleText);
      document.getElementById('more').style.display = 'none';
    }
  }, []);

  caption = caption.replace(/(\r\n|\n|\r)/gm, "<br>");

  return (
    <div className={classes.cardPageWrapper}>
      <div>
        <div
          className={classes.cardDetails}
          style={{
            backgroundColor: `#FFFFFF`
          }}
          onClick={() => {}}
        >
          <div className={classes.cardSection}>
            <div>
              <div
                className={classes.cardCrumb}
                style={{
                  backgroundColor: `#276EF1`,
                  borderRadius: 15,
                  width: 81,
                  height: 27,
                }}
              >
                {launchpadName}
              </div>
              <div className={classes.titleContainer}>
                <h3 className={classes.title}>
                  {get(item, 'name', '')}
                </h3>
                <EditIcon
                  color="inherit"
                  style={{ fontSize: 20, paddingLeft: 10, opacity: `0.7`, cursor: `pointer` }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/launchpad/project/edit/${id}`);
                  }}
                />
              </div>
              
            </div>
          </div>
          <div
            className={classes.cardOverflow}
            style={{
              color: `#595959`,
              height: `auto`,
              padding: `16px 0`
            }}
          >
            {caption.length < 50 ? (
              <h5>{ReactHtmlParser(get(item, 'description', ''))}</h5>
            ) : (
              <h5
                dangerouslySetInnerHTML={{
                  __html: checkStrLength(),
                }}
              ></h5>
            )}
          </div>
          <div
            className={classes.cardSection}
            style={{ paddingBottom: `15px` }}
          >
            <div
              style={{
                display: 'flex',
                marginTop: 2,
              }}
            >
              <div className={classes.cardDay}>{`Started: ${get(
                item,
                'startDate',
                ''
              )}`}</div>
            </div>
            <br />
          </div>
          <div className={classes.cardSection} style={{ paddingTop: `5px` }}>
            <div
              style={{
                display: 'flex',
                marginTop: 2,
              }}
            >
              <div style={{ display: 'flex' }}>
                {avatars}
                <Avatar
                  className={classes.cardAvatar}
                  style={{
                    backgroundColor: `#276EF1`,
                    color: `#FFFFFF`,
                    marginLeft: registeredUsers?.length > 1 ? 0 : 8,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/launchpad/project/${get(
                        item,
                        'id'
                      )}/invite-collaborators`
                    );
                  }}
                >
                  <PersonAddIcon />
                </Avatar>
              </div>
            </div>
            <div style={{ display: 'flex', padding: `0px 15px` }}>
              <div
                style={{
                  marginTop: 2,
                  display: 'flex',
                  alignItems: 'center',
                  color: item?.collapse ? `#FFFFFF` : `#595959`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  // add redirect if needed
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
