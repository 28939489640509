import React, { useState } from 'react';
import { get } from 'lodash';
import SomethingWentWrong from '../../../../components/Error/SomethingWentWrong';
import Loading from '../../../../components/Loading/Loading';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { client, setActiveProject } from '../../../../api';

import classes from './launchpadList.module.scss';

const LaunchpadList = ({ projects, history }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const selectProject = (projectid) => {
    setLoaded(false);
    client()
      .mutate({
        mutation: setActiveProject(projectid),
      })
      .then(response => {
        const updatedProject = get(response, 'data.updateActiveProject', null);
        setError(false);
        setLoaded(true);
        if (updatedProject) {
          history.push('/launchpad');
        }
      })
      .catch(e => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  }

  let list = null;

  if (error) {
    list = (
      <SomethingWentWrong />
    );
  }

  if (!loaded) {
    list = (
      <Loading />
    );
  }

  list = projects?.map((p, index) => {
    const fraction = `${get(p, 'numberator', 0)}/${get(p, 'denominator', 0)}`;
    const trailString = fraction === `0/0` ? `` : `| ${fraction} Hurdles`;
    const launchpadName = get(p, 'launchpadName', '');
    const score = `${get(p, 'earnedPoints', 0)}/${get(p, 'totalPoints', 0)}`;
    return (
      <div 
        key={p.id}
        onClick={e => {
          e.stopPropagation();
          selectProject(p.id)
        }}
      >
        <div className={classes.listDetails} 
        style={{
          backgroundColor: index === 0 ? '#689AF5' : ''
        }}>
          <div style={{ textAlign: 'left' }}>
            <div>
              <h5 style={{ color: index === 0 ? '#fff' : '' }}>{launchpadName} {get(p, 'totalPoints', 0) !== 0  ? ` | ${score} Creds` : ''}</h5>
            </div>
            <div>
              <h3 style={{ color: index === 0 ? '#fff' : '' }}>{p?.name}</h3>
            </div>
          </div>
          <div className={classes.btnContainer} style={{ backgroundColor: index === 0 ? '#fff' : '', color: index === 0 ? '#689AF5' : '' }}>
            <NavigateNextIcon />
          </div>
        </div>
        {(projects?.length) && <div style={{
          backgroundColor: 'transparent',
          margin: `0px 8px`,
        }}>
        </div>}
      </div>
    )
  });

  return (
    <div className={classes.listPageWrapper}>
      {list}
    </div>
  );
}

export default LaunchpadList;