import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SubStopStatus from './SubStopStatus';
import { connect } from 'react-redux';
import { setOpenSnackbar } from '../../../store/actions/projectActions';
import { createStructuredSelector } from 'reselect';
import { selectSnackbar, selectParentTrack } from '../../../store/selectors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import classes from './SubStop.module.scss';

function SubStop({
  data,
  expanded,
  onExpandStop,
  hurdleId,
  zoneId,
  projectId,
  snackbar,
  parent,
}) {
  const parentTrack = parent && parent.find(p => p.isActive);
  const path = get(parentTrack, 'path', '/');
  const parentPath = path === '/' ? '/mission-control' : path;

  const status = get(data, 'status', '');
  const substopType = get(data, 'substopType', '');
  const substopId = get(data, 'id', '');

  const switchRoutes = (substopType, hurdleId, zoneId, projectId, substopId) => {
    switch (substopType) {
      case 'prework':
        return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/prework`;
      case 'solo_workout':
        return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/solo_workout`;
      case 'peer_exchange':
        return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/peer_exchange`;
      case 'hurdle_consensus':
        return `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/hurdle_consensus`;
      default:
        return;
    }
  };

  const ActionLink = ({ status, substopType, hurdleId, zoneId, projectId, substopId }) => {
    switch (status) {
      // case 'active':
      //   return (
      //     <Link
      //       to={() => switchRoutes(substopType, hurdleId, zoneId, projectId, substopId)}
      //     >
      //       Continue <ChevronRightIcon />
      //     </Link>
      //   );
      case 'completed':
        return (
          <Link
            to={() => switchRoutes(substopType, hurdleId, zoneId, projectId, substopId)}
          >
            Review <ChevronRightIcon />
          </Link>
        );
      case 'pending':
        return (
          <Link
            to={() => switchRoutes(substopType, hurdleId, zoneId, projectId, substopId)}
          >
            Review <ChevronRightIcon />
          </Link>
        );
      default:
        return <div></div>;
    }
  }
  const [openSB, setOpenSB] = useState(snackbar.switch);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSB(!openSB);
    setOpenSnackbar({
      snackbar: {
        subStop: '',
        switch: false,
      },
    });
  };

  const content = () => {
    const width = get(data, 'totalPoints', 0) !== 0 ? get(data, 'scoringRate', 0) : 0;
    return (<div
      className={`${classes.substop} ${expanded ? classes.expanded : ''}`}
    //onClick={onExpandStop}
      style={{ background: `linear-gradient(90deg, #D1E2FF ${width}%, #fff 0%)` }}
    >
      {snackbar.subStop === data.substopType ? (
        <Snackbar
          open={openSB}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            You have successfully submitted the form
        </Alert>
        </Snackbar>
      ) : null}

      <div className={classes.basicInfoRow}>
        <h4 className={classes.substopName}>{get(data, 'name', '')}</h4>
        <div className={classes.status}>
          <SubStopStatus value={get(data, 'status', '')} data={data} />
        </div>
      </div>
      {expanded && (
        <div className={classes.detailsRow}>
          <p className={classes.caption}>
            {get(data, 'caption', '')}
          </p>
          <div className={classes.actionLinkWrapper}>
            <ActionLink
              status={get(data, 'status', '')}
              substopType={get(data, 'substopType', '')}
              hurdleId={hurdleId}
              zoneId={zoneId}
              projectId={projectId}
              substopId={get(data, 'id', '')}
            />
          </div>
        </div>
      )}
    </div>
  )};

  if(get(data, 'goUrl', '') !== ''){
    return <a href={get(data, 'goUrl', '')} target="_blank">
      {content()}
    </a>
  }

  return (
    <Link to={() => switchRoutes(substopType, hurdleId, zoneId, projectId, substopId)}>
      {content()}
    </Link>
  );
}

SubStop.propTypes = {
  data: PropTypes.shape({
    caption: PropTypes.string,
    displayOrder: PropTypes.number,
    duration: PropTypes.string,
    name: PropTypes.string,
    points: PropTypes.number,
    pointsEarned: PropTypes.number,
    status: PropTypes.string,
    substopType: PropTypes.string,
  }),
  expanded: PropTypes.bool,
  onExpandStop: PropTypes.func,
  hurdleId: PropTypes.number,
  zoneId: PropTypes.number,
  projectId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  snackbar: selectSnackbar,
  parent: selectParentTrack,
});

export default connect(mapStateToProps)(SubStop);
