import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Checkbox from '@material-ui/core/Checkbox';
import { generateRandomString } from '../../utility/helpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid rgba(0, 0, 0, 0.36)',
    borderRadius: '8px',
    position: 'relative',
    minHeight: '47px',
    padding: '5px 15px',
  },
  label: {
    position: 'absolute',
    top: '-8px',
    left: '7px',
    backgroundColor: 'white',
    display: 'inline-block',
    padding: '0 8px',
    fontSize: '13px',
  },
  inputRoot: {
    minHeight: '47px',
  },
  selectRoot: {
    height: '100%',
    backgroundColor: 'white !important',
  },
  selectMenu: {
    whiteSpace: 'normal',
    textAlign: 'left',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function MultiSelect({
  label,
  options,
  selectedItems,
  onChange,
}) {
  const [elementRandomId, setElementRandomId] = useState();
  const classes = useStyles();
  const selectedItemsSet = new Set(selectedItems);

  const optionsMap = options.reduce(
    (accumulator, item) => ({ ...accumulator, [item.key]: item.value }),
    {}
  );

  useEffect(() => {
    setElementRandomId(generateRandomString());
  }, []);

  return (
    <div className={classes.wrapper}>
      <InputLabel className={classes.label} id={`${elementRandomId}-label`}>
        {label}
      </InputLabel>
      <Select
        classes={{
          root: classes.selectRoot,
          selectMenu: classes.selectMenu,
        }}
        labelId={`${elementRandomId}-label`}
        id={`${elementRandomId}-select`}
        multiple
        value={selectedItems}
        renderValue={(items) =>
          items.map((item) => optionsMap[item]).join(', ')
        }
        onChange={(e) => onChange(e.target.value)}
        input={
          <Input
            disableUnderline={true}
            classes={{ root: classes.inputRoot }}
          />
        }
        MenuProps={MenuProps}
        fullWidth
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            <Fragment>
              <Checkbox
                checked={selectedItemsSet.has(option.key)}
                color="primary"
              />
              {option.value}
            </Fragment>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

MultiSelect.defaultProps = {
  label: '',
  options: [],
  selectedItems: [],
};

MultiSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
