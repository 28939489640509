import { gql } from '@apollo/client';

export function fetchSubstop(id, projectId) {
  return gql`
    query {
      fetchSubstop(id: ${id}, projectId: "${projectId}") {
        id
        projectId
        name
        caption
        displayOrder
        duration
        substopType
        status
        tierType
        hurdleResources{
            id
            name
            hurdleResourceType
            copyTextArea
            mustReadUrl
            postcardUrl
            imageUrl
            tepUrl
            externalLink
            externalLinkButtonText
            youtubeUrl
            googleMeetUrl
            googleMeetButtonText
            questions{
                id
                title
                description
                displayOrder
                questionTypeId
                questionType
                allowNotes
                placeholder
                maxOptionSelection
                customSectionId
                clusterId
                customSectionDisplayOrder
                clusterDisplayOrder
                answer
                preferedAnswer
                hcAnswer
                subQuestions{
                    id
                    title
                    description
                    displayOrder
                    questionTypeId
                    questionType
                    allowNotes
                    placeholder
                    status
                    maxOptionSelection
                    customSectionId
                    clusterId
                }
                answerOptions{
                    id
                    title
                    value
                    description
                    displayOrder
                    status
                }
            }
        }
        questions{
            id
            title
            description
            displayOrder
            questionTypeId
            questionType
            allowNotes
            placeholder
            maxOptionSelection
            customSectionId
            clusterId
            customSectionDisplayOrder
            clusterDisplayOrder
            answer
            preferedAnswer
            hcAnswer
            subQuestions{
                id
                title
                description
                displayOrder
                questionTypeId
                questionType
                allowNotes
                placeholder
                status
                maxOptionSelection
                customSectionId
                clusterId
            }
            answerOptions{
                id
                title
                value
                description
                displayOrder
                status
            }
        }
      }
    }`;
}

export const UpdateSoloWorkoutPayload = gql`
  fragment project on UpdateSoloWorkoutPayload {
    substops {
      id
      status
      questions
    }
  }
`;

export function updateSoloWorkout({ id, status, questions }) {
  console.log(status, questions);

  const soloWorkoutQuestions = questions.map((question) => {
    if (question.id === 2) {
      return question;
    }
  });
  //console.log('this is from SubStop.js -- updateSoloWorkout', soloWorkoutQuestions)
  return gql`
    mutation UpdateSoloWorkout{
      updateSoloWorkout(
        input: {
          params: {
            status: "${status}",
            questions: "${soloWorkoutQuestions}"            
          }
        }
      ) {
        ...soloWorkout
        success
        errors        
      }
    }
    ${UpdateSoloWorkoutPayload}
  `;
}

export const UpdatePeerExchangePayload = gql`
  fragment project on UpdatePeerExchangePayload {
    substops {
      id
      status
      questions
    }
  }
`;

export function updatePeerExchange({ id, status, questions }) {
  const peerExchangeQuestions = questions.map((question) => {
    if (question.id === 2) {
      return question;
    }
  });
  console.log(
    'this is from SubStop.js -- updatePeerExchange',
    peerExchangeQuestions
  );
  return gql`
    mutation UpdatePeerExchange{
      updatePeerExchange(
        input: {
          params: {
            status: "${status}",
            questions: "${peerExchangeQuestions}"            
          }
        }
      ) {
        ...peerExchange
        success
        errors        
      }
    }
    ${UpdatePeerExchangePayload}
  `;
}
