import { createReducer } from '@reduxjs/toolkit';
import {
  setUserData,
  clear,
  setOperationStatus,
  logout,
  resetOperationStatus,
  setUserProfile,
} from '../actions/userActions';

import { REQUEST_STATUS } from '../../utility/constants';

const initialState = {
  isLoading: false,
  requestStatus: {
    status: REQUEST_STATUS.NOT_STARTED,
    operation: '',
    errors: null,
  },
  data: null,
  profile: null,
};

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUserData, (state, action) => ({
    ...state,
    data: { ...action.payload },
  }));
  builder.addCase(clear, () => initialState);
  builder.addCase(setOperationStatus, (state, action) => ({
    ...state,
    requestStatus: {
      status: action.payload.status,
      operation: action.payload.operation,
      errors: action.payload.errors || null,
    },
  }));
  builder.addCase(resetOperationStatus, (state) => ({
    ...state,
    requestStatus: { ...initialState.requestStatus },
  }));
  builder.addCase(setUserProfile, (state, action) => ({
    ...state,
    profile: { ...action.payload.profile },
  }));

  builder.addCase(logout, () => initialState);
});

export default userReducer;
