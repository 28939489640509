import React from 'react';

export function FBIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 0C4.5 0 0 4.49 0 10.02c0 5 3.66 9.15 8.44 9.9v-7H5.9v-2.9h2.54V7.81c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 008.44-9.9C20 4.49 15.5 0 10 0z"
        fill="#595959"
      />
    </svg>
  );
}

export function LinkedInIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.6.4A9.6 9.6 0 000 10a9.6 9.6 0 009.6 9.6 9.6 9.6 0 009.6-9.6A9.6 9.6 0 009.6.4zM7.25 13.979H5.306V7.723H7.25v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.414v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z"
        fill="#595959"
      />
    </svg>
  );
}

export function YoutubeIcon(props) {
  return (
    <svg
      width={28}
      height={20}
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.43 3.144a3.595 3.595 0 00-.903-1.595 3.49 3.49 0 00-1.562-.925C22.775.01 14.01 0 14.01 0S5.246-.01 3.054.577a3.567 3.567 0 00-1.56.939 3.672 3.672 0 00-.91 1.598C.005 5.35 0 9.986 0 9.986s-.006 4.659.568 6.871a3.542 3.542 0 002.466 2.52C5.248 19.99 13.99 20 13.99 20s8.764.01 10.955-.575a3.504 3.504 0 001.564-.924c.434-.44.747-.99.908-1.593.58-2.233.584-6.868.584-6.868s.028-4.66-.57-6.896zM11.206 14.281l.007-8.564 7.285 4.289-7.292 4.275z"
        fill="#595959"
      />
    </svg>
  );
}
