import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// selectors
import { selectRequestStatus } from '../../../store/selectors/projectSelectors';
// actions
import { createProject } from '../../../store/actions/projectActions';

import ProjectForm from '../../Projects/components/ProjectForm';

const HurdleZero = ({ requestStatus, createProject, parentTrackId }) => {
  const onSubmitProjectForm = (projectData) => {
    createProject(projectData);
  };

  return (
    <div>
      <ProjectForm
        onSubmit={onSubmitProjectForm}
        parentTrackId={parentTrackId}
      />
    </div>
  );
};

HurdleZero.defaultProps = {
  parentTrackId: '3', // launchpad
};

HurdleZero.propTypes = {};

const mapStateToProps = createStructuredSelector({
  requestStatus: selectRequestStatus('projectData'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HurdleZero);
