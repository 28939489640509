import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../store/actions/userActions';

import { selectIsUserAuthenticated } from '../../../store/selectors/userSelectors';

function LogoutContainer({ isAuthenticated, logout }) {
  const history = useHistory();

  logout();

  if (!isAuthenticated) {
    history.push('/login');
  }

  return null;
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsUserAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
