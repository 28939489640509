import { gql } from '@apollo/client';

export const fetchGlossaryByIdentifier = (identifier) => {
  return gql`
    query {
      fetchGlossary(identifier: "${identifier}") {
        id
        word
        description
        identifier
        parentTrackId
      }
    }
  `;
};

export const fetchExampleByIdentifier = (identifier) => {
  return gql`
    query {
      fetchExample(identifier: "${identifier}") {
        id
        word
        description
        identifier
        parentTrackId
      }
    }
  `;
};
