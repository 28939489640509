import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../Header';
import { BackLink, NextLink } from '../../../../components/Links';
import Button from '@material-ui/core/Button';
import { Link, BrowserRouter } from 'react-router-dom';
import { withStyles, emphasize } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { setHurdleConsensusData, setOpenSnackbar } from '../../../../store/actions/projectActions';
import { selectHurdleConsensusSubStopData } from '../../../../store/selectors/projectSelectors';
import Section from '../../../Substops/components/Section/Section';
import { selectSnackbar, selectParentTrack } from '../../../../store/selectors';
import classes from './hurdleConsensus.module.scss';
import SomethingWentWrong from '../../../../components/Error/SomethingWentWrong';

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(104, 154, 245, 0.6);',
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const ToolTipTheme = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "red"
    }
})(Tooltip);


const HurdleConsensusComponent = ({
    parent,
    snackbar,
    projectId,
    zoneId,
    hurdleId,
    substopId,
    hurdleName,
    hurdleBrow,
    setHurdleConsensusData,
    routeId,
    projectDataFromPeerExchange,
    subStopInfo,
    sortedQuestions,
    sortedAnswers,
    fn,
    notesFn,
    submitProjectDetails,
    skipPath,
    error,
    errorMessage,
}) => {
    const parentTrack = parent && parent.find(p => p.isActive);
    const path = get(parentTrack, 'path', '/');
    const parentPath = path === '/' ? '/mission-control' : path;
    const history = useHistory();
    const toolTipText = `Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis.`

    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    const handleClick = () => {
      submitProjectDetails();
    }

    return (

        <div className={classes.hurdleConsensusPageWrapper}>
            <div className={classes.hurdleConsensusPage__header}>
                <div className={classes.header__links}>
                    <Header>
                        <BackLink to={
                          skipPath
                            ? skipPath
                            : `${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`
                          }
                        >
                          Back
                        </BackLink>
                        {/* <NextLink to={'#'}>Continue</NextLink> */}
                    </Header>
                </div>
                {!error && (<>
                    <div className={classes.header__breadcrumbs}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                            <StyledBreadcrumb component={Link} to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}`} label={`${hurdleBrow} | ${hurdleName}`} className={classes.breadcrumbs__firstDegree} />
                            <StyledBreadcrumb component={Link} to={`${parentPath}/${projectId}/zones/${zoneId}/hurdles/${hurdleId}/substop/${substopId}/hurdle_consensus`} label={`${get(subStopInfo, 'name')}`} className={classes.breadcrumbs__secondDegree} />
                        </Breadcrumbs>
                    </div>
                    <div className={classes.header__title}>
                        <h1>{`${get(subStopInfo, 'name', '')}`}</h1>
                        <p>{`${get(subStopInfo, 'caption', '')}`}</p>
                        {/* <h1>Hurdle Consensus</h1> */}
                        {/* <p>Preamble about why and how to use this pre-work exercise in order to master this hurdle.</p> */}
                    </div>
                </>)
                }
            </div>

            <div className={classes.hurdleConsensusPage__content}>
                {!error ? (
                  <form className={classes.formContainer} onSubmit={handleFormSubmit}>
                    <div className={classes.questionnaire}>
                        {sortedQuestions.map((question, index) => {
                            const idKey = get(question, 'customSectionId', '');
                            return (
                                <div key={`csId${idKey}`} style={{ backgroundColor: `#FFFFFF`, margin: `8px 0px` }}>
                                    <Section
                                        keyId={`csId${idKey}`}
                                        sortedQuestions={question}
                                        sortedAnswers={sortedAnswers}
                                        fn={fn}
                                        notesFn={notesFn}
                                    />
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className={classes.submitButton__section}>
                        <Button 
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.submitButton}
                          onClick={handleClick}
                        >
                          Submit
                        </Button>
                    </div>
                    </form>
                  ) : (<SomethingWentWrong message={errorMessage} link={false} />)
                }

            </div>
        </div >


    )
}

HurdleConsensusComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    projectDataFromPeerExchange: selectHurdleConsensusSubStopData,
    snackbar: selectSnackbar,
    parent: selectParentTrack,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setOpenSnackbar
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HurdleConsensusComponent)
