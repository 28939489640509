import React from 'react';
import { useHistory } from 'react-router-dom';
import Layout, { Header } from '../../components/Layout';
import CloseIcon from '@material-ui/icons/Close';
import classes from './launchpadExample.module.scss';

const LaunchpadExample = () => {
  const history = useHistory();

  return (
    <Layout>
      <div className={classes.exampleWrapper}>
          <Header icon={<CloseIcon />}
              style={{ borderBottom: 'none', background: '#FFF' }}
              rightFn={() => history.goBack()}
          ></Header>
          <div className={classes.exampleDetails}>
            <div>
              Build an app-based service that helps 100s of colleges help 1000s of students build up their resumes, OJT experience, professional network and overall employability starting freshman year so that by senior year, every graduate will have multiple job offers. 
            </div>
            <div>
              Build a platform to help everyday citizens launch innovations and boost their career and income opportunities in 100-day cycles.
            </div>
            <div>
              Develop sales & marketing campaign consisting of content, materials, processes, channel partnerships and other essentials in order to sign up 500,000 work-from-home professionals to a new product offering. 
            </div>
            <div>
              Re-design a supply chain and customer service model that works when service is done by remote providers online.
            </div>
            <div>
              Build an app to that proactively recommends healthcare solutions to busy professionals who are taking care of elderly family at home. 
            </div>
            <div>
              Pivot our salesforce performance management system to power more frequent appraisals that drive higher closing rates based on updated market strategies (since market dynamics keep changing).
            </div>
          </div>
      </div>
    </Layout>
  );
};

export default LaunchpadExample;
