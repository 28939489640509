import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isValidEmail } from '../../utility/helpers';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(() => ({
  root: {
    '& >div': {
      borderRadius: '8px',
      // backgroundColor: '#276EF1',
      paddingRight: '0',
      overflow: 'hidden',
    },

    '& input': {
      backgroundColor: 'white',
    },
  },
  adornment: {
    maxWidth: '125px',
    height: '100%',
    '& button': {
      textTransform: 'capitalize',
      color: '#276EF1',
      fontWeight: '600',
    },
  },
}));

const DEFAULT_TEXTFIELD_PROPS = {
  fullWidth: true,
};

function MultiEmailInput({
  placeholder,
  buttonLabel,
  onSubmit,
  textFieldProps,
}) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [invalidEmails, setInvalidEmails] = useState([]);

  const onClickCTA = () => {
    const emails = value.split(',').reduce(
      (accumulator, current) => {
        const currentEmail = current.trim();
        if (currentEmail === '') {
          return accumulator;
        }

        if (isValidEmail(currentEmail)) {
          return {
            ...accumulator,
            valid: [...accumulator.valid, currentEmail],
          };
        } else {
          return {
            ...accumulator,
            invalid: [...accumulator.invalid, currentEmail],
          };
        }
      },
      { valid: [], invalid: [] }
    );

    if (emails.valid.length) {
      onSubmit(emails.valid.slice());
    }

    setInvalidEmails(emails.invalid.slice());
    setValue(emails.invalid.join(', '));
  };

  const muiTextFieldProps = { ...DEFAULT_TEXTFIELD_PROPS, ...textFieldProps };

  return (
    <TextField
      {...muiTextFieldProps}
      error={!!invalidEmails.length}
      helperText={
        !!invalidEmails.length ? 'Please enter valid email addresses' : ''
      }
      placeholder={placeholder}
      autoComplete="off"
      className={classes.root}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      variant="outlined"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClickCTA();
        }
      }}
      label="Email address"
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className={classes.adornment}>
            <Button
              aria-label="toggle password visibility"
              onClick={onClickCTA}
              onMouseDown={onClickCTA}
            >
              <SendIcon />
              {/* {buttonLabel} */}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}

MultiEmailInput.defaultProps = {
  textFieldProps: {},
};

MultiEmailInput.propTypes = {
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  textFieldProps: PropTypes.object,
};

export default MultiEmailInput;
