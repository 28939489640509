import React from "react";
import Button from '@material-ui/core/Button';
import classes from './imageSpacingComponent.module.scss';
import image1 from '../../../../../assets/images/image1.png';
import image2 from '../../../../../assets/images/image2.png';

const ImageSpacingComponent = () => {
  return (
    <div className={classes.imageSpacingPage}>
      <div className={classes.imageSpacingPage__title}>
        <h1>Image Spacing Style Guide</h1>
      </div>
      <div className={classes.imageSpacingPage__container}>

        <div className={classes.imageSpacingPage__image1}>
          <img src={image2} alt="boy" />

          <div className={classes.imageSpacingPage__heading1}>
            <div className={classes.imageSpacingPage__heading1__blank}>&nbsp;</div>
            <div className={classes.imageSpacingPage__heading1__content}>
              <div className={classes.heading1__title}>Heading Text</div>
              <div className={classes.heading1__body}>Quick brown fox jumps over the lazy dog.</div>
              <div className={classes.heading1__ctaButton}>
                <Button variant="outlined" size='small'>CTA Button</Button>
              </div>
            </div>
          </div>

          <div className={classes.imageSpacingPage__tile1}>
            <div className={classes.tile1__title}>Title Text</div>
            <div className={classes.tile1__body}>Quick brown fox jumps over the lazy dog.</div>
            <div className={classes.tile1__ctaButton}>
              <Button variant="outlined" size='small'>CTA Button</Button>
            </div>
          </div>

        </div>

        <div className={classes.imageSpacingPage__image2}>
          <img src={image1} alt="girl" />

          <div className={classes.imageSpacingPage__tile2}>
            <div className={classes.tile1__title}>Title Text</div>
            <div className={classes.tile1__body}>Quick brown fox jumps over the lazy dog.</div>
            <div className={classes.tile1__ctaButton}>
              <Button variant="outlined" size='small'>CTA Button</Button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ImageSpacingComponent;
