import { gql } from '@apollo/client';

export const getParentTrack = gql`
  query {
    fetchParentTracks {
      id
      title
      caption
      zoneLevelView
      hurdleLevelView
      projectLevelView
      cssConfig
      imageUrl
      alterName
      path
      renderMode
    }
  }
`;

export const getParentTrackDetail = (id, projectId) => gql`
  query {
    fetchParentTrack(id: ${id}, projectId: "${projectId}") {
      id
      projectId
      title
      caption
      zoneLevelView
      hurdleLevelView
      projectLevelView
      cssConfig
      imageUrl
      alterName
      sortOrder
      numerator
      denominator
      collaborators
      projectNumbers
      subscription
      totalPoints
      earnedPoints
      scoringRate
      bypassSuffix
      hurdleZones {
        id
        name
        caption
        displayOrder
        displayZonegraphs
        icon
        sortOrder
        numerator
        denominator
        urlType
        goUrl
        goUrlButtonText
        parentTrackId
        displayZonegraphs
        zoneEyebrow
        bgImageUrl
        tierType
        projectHurdleZoneStatus
        totalPoints
        earnedPoints
        scoringRate
        bypassSuffix
      }
      hurdles {
        id
        name
        caption
        displayOrder
        projectHurdleStatus
        hurdleType
        sortOrder
        hurdleEyebrow
        urlType
        goUrl
        goUrlButtonText
        skipSubstopPage
        tierType
        hurdleZoneId
        totalPoints
        earnedPoints
        scoringRate
        bypassSuffix
      }
    }
  }
`;
