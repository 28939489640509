import { createAction } from '@reduxjs/toolkit';

export const clearMissionControl = createAction('missioncontrol/clear');
export const missionControlSetData = createAction('missioncontrol/missionControlSetData', values => {
  return {
    payload: values,
  };
});

export const setOperationStatus = createAction(
  'missioncontrol/setOperationStatus',
  (status, operation, errors) => ({
    payload: {
      status,
      operation,
      errors,
    },
  })
);

export const resetOperationStatus = createAction('missioncontrol/resetOperationStatus');