import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Loading from "../../components/Loading/Loading";
import SomethingWentWrong from "../../components/Error/SomethingWentWrong";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import HurdleSet from "../Hurdles/HurdleSet/HurdleSet";
import { LinearProgress } from "../../components/LinearProgress";

import { selectTheme, selectParentTrack } from "../../store/selectors";

import { get } from "lodash";

import classes from "./zones.module.scss";

const Zones = ({ theme, parent, projectId, tracks, data, fn }) => {
  const history = useHistory();
  const [zones, setZones] = useState(null);
  const [details, setDetails] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const localRef = localStorage.getItem("igniteRef");
  const hurdleRef = localStorage.getItem("hurdleSet");
  const zoneRefs = useRef([]);
  const parentTrack = parent && parent.find((p) => p.isActive);
  const path = get(parentTrack, "path", "/");
  const parentPath = path === "/" ? "/mission-control" : path;
  let zoneArea = null;

  /**
   * Hack!! For some reason, useRef is not working on componentDidMount
   * TODO: Apply useRef  --- use this one instead next time
   */
  const scrollRefHandler = (idx) => {
    const ref = zoneRefs.current[idx];
    if (ref) {
      ref?.scrollIntoView({ behavior: "smooth" });
    }
  };

  /**
   * Hack!! For some reason, useRef is not working on componentDidMount
   * TODO: Apply useRef
   */
  const scrollDocHandler = (id, hurdleRefId) => {
    const element = document?.querySelector(`#zone__${id}`); //__hurdle__${id}
    const element2 = document?.querySelector(`#hurdle__${hurdleRefId}`);
    element && element?.scrollIntoView({ behavior: "smooth" });
    element2 && element2?.scrollIntoView({ behavior: "smooth" });
  };

  const navigateHandler = (item) => {
    const goURL = get(item, "goUrl", "");
    if (!projectId) {
      return;
    }
    if (goURL) {
      if (goURL.startsWith("/")) {
        history.push(get(item, "goUrl", ""));
      } else {
        window.open(goURL, "_blank");
      }
    } else {
      const hurdleString = JSON.stringify(item);
      localStorage.setItem("hurdleSet", hurdleString);
      history.push(
        `${parentPath}/${projectId}/zones/${item.hurdleZoneId}/hurdles/${item.id}`,
      );
    }
  };

  const openSection = (id) => {
    const updatedZones = zones?.map((z) => {
      let collapse = z.collapse;
      if (z.id === id) {
        collapse = !z.collapse;
        const zoneString = JSON.stringify(z);
        if (collapse) {
          localStorage.setItem("igniteRef", zoneString);
        } else {
          localStorage.removeItem("igniteRef");
        }
      } else {
        collapse = false;
      }
      return {
        ...z,
        collapse,
      };
    });
    setZones(updatedZones);
  };

  const executeFn = () => {
    fn &&
      fn
        .execute()
        .then((response) => {
          const detail = get(response, "data.fetchParentTrack", null);
          if (detail) {
            setDetails(detail);
            setSubscription(get(detail, "subscription", ""));
            const hurdleZones = get(detail, "hurdleZones", []);
            const hurdle = get(detail, "hurdles", []);
            const zones = hurdleZones
              .map((z) => {
                const hurdles = hurdle
                  .filter((h) => h.hurdleZoneId === z.id)
                  .sort((a, b) => a.displayOrder - b.displayOrder);
                const collapse = !detail.zoneLevelView;
                return {
                  ...z,
                  collapse,
                  hurdles,
                };
              })
              .sort((a, b) => a.displayOrder - b.displayOrder);
            setZones(zones);
          }
          setLoaded(true);
        })
        .catch((e) => {
          setError(true);
          console.error(e);
        });
  };

  useEffect(() => {
    executeFn();
  }, []);

  useEffect(() => {
    executeFn();
  }, [fn]);

  /**
   * Hack!! For some reason, useRef is not working on componentDidMount
   * TODO: Apply useRef
   */
  useLayoutEffect(() => {
    const refId = localRef && get(JSON.parse(localRef), "id", null);
    const hurdleRefId = hurdleRef && get(JSON.parse(hurdleRef), "id", null);
    const parentTrackId =
      localRef && get(JSON.parse(localRef), "parentTrackId", null);
    const trackId = get(data, "id", null);
    if (trackId !== parentTrackId) {
      localStorage.removeItem("igniteRef");
      if (localStorage.getItem("hurdleSet") !== null) {
        localStorage.removeItem("hurdleSet");
      }
      return;
    }
    if (refId) {
      const currentZones = zones && zones.find((i) => i.id === refId);
      const collapse = get(currentZones, "collapse", true);
      const hurdles = get(currentZones, "hurdles", []);
      if (!collapse && hurdles?.length > 1) {
        openSection(refId);
      }
      /**
       * Hsck!! imperative approach via querySelector and timeout
       * TODO: apply declarative approach next time
       */
      setTimeout(() => scrollDocHandler(refId, hurdleRefId), 500);
    }
  }, [zones]);

  if (error) {
    zoneArea = <SomethingWentWrong />;
    return zoneArea;
  }

  if (!loaded) {
    zoneArea = (
      <div style={{ marginTop: 50 }}>
        <Loading size="5rem" />
      </div>
    );
    return zoneArea;
  }

  zoneArea = zones?.map((item, index) => {
    return (
      <Zone
        key={item.id}
        item={item}
        zoneRefs={zoneRefs}
        details={details}
        projectId={projectId}
        subscription={subscription}
        index={index}
        theme={theme}
        navigateHandler={navigateHandler}
        openSection={openSection}
      />
    );
  });

  return (
    <div className={classes.zonePageWrapper}>
      {details.totalPoints !== 0 && (
        <div
          className={classes.scoreSection}
          style={{ padding: `0 16px 16px 16px` }}
        >
          <LinearProgress
            earnedPoints={get(details, "earnedPoints", 0) || 0}
            totalPoints={get(details, "totalPoints", 0) || 100}
            scoringRate={get(details, "scoringRate", 0) || 2}
            bypassSuffix={get(details, "bypassSuffix", "")}
            subText="CREDS"
            color="#276EF1"
            bgColor="#E4E4E4"
          />
        </div>
      )}
      {zoneArea}
    </div>
  );
};

function Zone({
  item,
  zoneRefs,
  details,
  projectId,
  subscription,
  index,
  navigateHandler,
  openSection,
  theme,
}) {
  const zoneBackground = (item) => {
    const base = `linear-gradient(256.23deg, ${get(theme, `light`, `#99B7EE`)} 1.55%, ${get(theme, `dark`, `#99B7EE`)} 100%)`;
    const bg = get(item, "bgImageUrl", "");

    if (bg) {
      return `url(${item.bgImageUrl}), ${base} no-repeat`;
    }

    return base;
  };

  const statusItem = (stats) => {
    switch (stats) {
      case "Done": {
        return {
          proceedingText: "Review",
          statusColor: "#689AF5",
          statusIcon: (
            <div style={{ fontSize: 17, paddingRight: 5 }}>
              <CheckCircleOutlineRoundedIcon fontSize="inherit" />
            </div>
          ),
        };
      }
      case "Ongoing": {
        return {
          proceedingText: "Continue",
          statusColor: "#FFA000",
          statusIcon: (
            <div style={{ fontSize: 17, paddingRight: 5 }}>
              <RemoveCircleOutlineIcon fontSize="inherit" />
            </div>
          ),
        };
      }
      default: {
        return {
          proceedingText: "",
          statusColor: "#FFFFFF",
          statusIcon: <div></div>,
        };
      }
    }
  };

  return (
    <div
      key={item.id}
      ref={(el) => (zoneRefs.current[index] = el)}
      id={`zone__${item.id}`}
    >
      {details?.zoneLevelView && (
        <div
          className={classes.zoneDetails}
          style={{
            background: zoneBackground(item),
            backgroundSize: `cover`,
            backgroundPosition: `bottom`,
            borderRadius: `10px`,
            margin: item?.hurdles?.length ? `10px 15px 0px` : `10px 15px`,
          }}
          onClick={() => {
            openSection(item?.id);
            const hurdles = get(item, "hurdles", []);
            if (hurdles?.length === 1) {
              navigateHandler(hurdles[0]);
            }
          }}
        >
          <div
            className={classes.zoneSection}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  padding: `2px 0`,
                }}
              >
                {get(item, "zoneEyebrow", "") && (
                  <span className={classes.zoneHurdleCount}>
                    {get(item, "zoneEyebrow", "")}
                  </span>
                )}
              </div>

              <h3
                style={{
                  color: `#FFFFFF`, //get(theme, `main`, `#276EF1`),
                }}
              >
                {item.name}
              </h3>

              <div
                className={classes.zoneOverflow}
                style={{
                  color: `#FFFFFF`,
                }}
              >
                <p
                  className={classes.zoneCaption}
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{ __html: item?.caption }}
                ></p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            className={classes.zoneSection}
          >
            {item.totalPoints !== 0 && (
              <div className={classes.scoreSection}>
                <LinearProgress
                  earnedPoints={get(item, "earnedPoints", 0) || 0}
                  totalPoints={get(item, "totalPoints", 0) || 100}
                  scoringRate={get(item, "scoringRate", 0) || 2}
                  bypassSuffix={get(item, "bypassSuffix", "")}
                  subText="CREDS"
                  color="#FFF"
                  bgColor="rgba(228, 228, 228, 0.32)"
                />
              </div>
            )}
            <div
              style={{
                color: item?.collapse
                  ? `#FFFFFF`
                  : get(
                      statusItem(item?.projectHurdleZoneStatus),
                      "statusColor",
                      null,
                    ),
                display: "flex",
                marginTop: item.totalPoints !== 0 ? 0 : "24px",
              }}
            >
              {get(
                statusItem(item?.projectHurdleZoneStatus),
                "statusIcon",
                null,
              )}
              <div>{get(item, "projectHurdleZoneStatus", "")}</div>
            </div>
            <div style={{ display: "flex", padding: `0px 15px` }}>
              <div
                className={classes.zoneLinkWrapper}
                style={{ color: "#fff" }}
              >
                {get(
                  statusItem(item?.projectHurdleZoneStatus),
                  "proceedingText",
                  null,
                )}
                {item?.collapse ? (
                  <KeyboardArrowUpIcon
                    size="inherit"
                    color="inherit"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    size="inherit"
                    color="inherit"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {item?.collapse && details?.hurdleLevelView && (
        <HurdleSet
          projectId={projectId}
          data={item?.hurdles}
          subscription={subscription}
        />
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  theme: selectTheme,
  parent: selectParentTrack,
});

export default connect(mapStateToProps, null)(Zones);
