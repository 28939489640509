import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import SigninSignupWrapper from '../../../components/User/SigninSignupWrapper';
import SignupComponent from './SignupComponent';
import { REQUEST_STATUS } from '../../../utility/constants';
import {
  selectIsLoading,
  selectSignupErrors,
  selectIsUserAuthenticated,
  selectRequestStatus,
} from '../../../store/selectors/userSelectors';

import {
  signup,
  signupWithFacebook,
  resetOperationStatus,
} from '../../../store/actions/userActions';

import TermsOfUseContainer from '../../TermsOfUse';
import PrivacyPolicyContainer from '../../PrivacyPolicy';

import classes from './CreateAccount.module.scss';

const CreateAccount = ({
  signup,
  signupWithFacebook,
  isLoading,
  isAuthenticated,
  signupErrors,
  requestStatus,
  resetOperationStatus,
}) => {
  const location = useLocation();
  const history = useHistory();

  useLayoutEffect(() => {
    resetOperationStatus();
  }, []);

  const onSubmit = (userData, agreeToTerms) => {
    signup(userData, agreeToTerms);
  };

  const shouldShowSuccessToaster =
    get(requestStatus, 'status') === REQUEST_STATUS.DONE;

  if (isAuthenticated && !shouldShowSuccessToaster) {
    history.push('/');
  }

  const onCloseSuccessToaster = () => {
    resetOperationStatus();
  };

  return (
    <SigninSignupWrapper className={classes.signupPageWrapper}>
      <SignupComponent
        onSubmit={onSubmit}
        isLoading={isLoading}
        signupErrors={signupErrors}
        signupWithFacebook={signupWithFacebook}
        shouldShowSuccessToaster={shouldShowSuccessToaster}
        onCloseSuccessToaster={onCloseSuccessToaster}
      />
      <TermsOfUseContainer
        isVisible={get(location, 'pathname') === '/signup/terms-of-use'}
        onClose={() => history.push('/signup')}
      />
      <PrivacyPolicyContainer
        isVisible={get(location, 'pathname') === '/signup/privacy-policy'}
        onClose={() => history.push('/signup')}
      />
    </SigninSignupWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading('signup'),
  requestStatus: selectRequestStatus('signup'),
  signupErrors: selectSignupErrors,
  isAuthenticated: selectIsUserAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signup,
      signupWithFacebook,
      resetOperationStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
