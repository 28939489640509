import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { get, cloneDeep, isEmpty } from "lodash";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Layout from "../../components/Layout";
import TitleHead from "../../components/Layout/TitleHead/TitleHead";
import Loading from "../../components/Loading/Loading";
import SomethingWentWrong from "../../components/Error/SomethingWentWrong";
import LaunchpadBanner from "../Launchpad/components/LaunchpadBanner/LaunchpadBanner";
import ZeroState from "../Launchpad/components/ZeroState/ZeroState";
import Zones from "../../containers/Zones/Zones";
import ProjectCard from "../Projects/components/ProjectCard/ProjectCard";
import Header from "../Header";
import {
  client,
  getParentTrack,
  getParentTrackDetail,
  getProjects,
  getProjectDetail,
  getCollaborators,
} from "../../api";
import {
  clearParent,
  setParent,
  clearTheme,
  setTheme,
} from "../../store/actions";
import { selectParentTrack, selectTheme } from "../../store/selectors";
import { isHexColor, mutateParent } from "../../utility/helpers";
import TrackView from "../Launchpad/components/LaunchpadList/TrackView";

const igniteTheme = createTheme({
  palette: {
    primary: {
      main: "#276EF1",
    },
  },
});

const BannerHeader = ({ startPage, isLaunchpad }) => {
  const imageUrl = startPage.imageUrl;
  if (isLaunchpad) {
    return (
      <LaunchpadBanner
        id={startPage.id}
        style={{
          // background: `url(${imageUrl}) no-repeat`,
          // backgroundSize: `100% 100%`
          backgroundColor: "#fff",
        }}
      />
    );
  }
  return (
    <TitleHead
      title={startPage?.title}
      description={startPage?.caption}
      style={{
        // background: `url(${imageUrl}) no-repeat`,
        // backgroundSize: `100% 100%`
        backgroundColor: "#fff",
      }}
    />
  );
};

const Home = ({
  parent,
  clearParent,
  setParent,
  theme,
  clearTheme,
  setTheme,
}) => {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const [parentTracks, setParentTracks] = useState(null);
  const [initPage, setInitPage] = useState("/"); // Mission Control
  const [project, setProject] = useState(null);
  const [projectid, setProjectid] = useState("");
  const [startPage, setStartPage] = useState(null);
  const [fn, setFn] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [appTheme, setAppTheme] = useState(igniteTheme);

  const fetchProjectDetail = (id) => {
    client()
      .query({
        query: getProjectDetail(id),
      })
      .then((response) => {
        const detail = get(response, "data.fetchProjectDetail", null);
        if (detail) {
          // dispatch project detail here
        }
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  const fetchProjects = (item) => {
    client()
      .query({
        query: getProjects(item.id),
      })
      .then((response) => {
        if (get(response, "networkStatus", 7) === 8) {
          setErrorMessage("Error in fetching projects");
          setLoaded(true);
          setError(true);
          return;
        }
        const projects = get(response, "data.fetchProjects", null);
        if (projects && projects.length) {
          const projectToRender =
            projectId && projects.find((i) => i.id === `${projectId}`);
          const projWithCollabs = projectToRender || projects[0];
          const pId = projectId || get(projects, "0.id", "") || "";
          setFn({
            execute: () =>
              client().query({
                query: getParentTrackDetail(item.id, pId),
              }),
          });
          setProjectid(get(projWithCollabs, "id", ""));
          projWithCollabs && fetchCollaborators(projWithCollabs);
        } else {
          setLoaded(true);
        }
      })
      .catch((e) => {
        setErrorMessage("Error in fetching projects");
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  const fetchCollaborators = (projWithCollabs) => {
    let updatedProject = cloneDeep(projWithCollabs);
    client()
      .query({
        query: getCollaborators(projWithCollabs.id),
      })
      .then((response) => {
        if (get(response, "networkStatus", 7) === 8) {
          setErrorMessage("Error in fetching collaborators");
          setLoaded(true);
          setError(true);
          return;
        }
        updatedProject["projectIndex"] = 1;
        const collabs = get(response, "data.fetchCollabs", null);
        if (collabs) {
          updatedProject["collaborators"] = collabs;
        }
        setProject(updatedProject);
        setLoaded(true);
      })
      .catch((e) => {
        setProject(updatedProject);
        setErrorMessage("Error in fetching collaborators");
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  const fetchParentTrack = () => {
    setLoaded(false);
    client()
      .query({
        query: getParentTrack,
      })
      .then((result) => {
        if (get(result, "networkStatus", 7) === 8) {
          setErrorMessage("Error in fetching tracks");
          setLoaded(true);
          setError(true);
          return;
        }
        const parentTracks = get(result, "data.fetchParentTracks", null);
        if (parentTracks && parentTracks.length) {
          setParentTracks(parentTracks);
          const mutatedParent = mutateParent(parentTracks);
          let toShowPage = initPage;
          if (pathname) {
            const keyword = pathname.replace("/", "");
            if (keyword) {
              toShowPage = keyword;
              if (toShowPage.endsWith("/")) {
                toShowPage = toShowPage.substring(0, toShowPage.length - 1);
              }
            }
            setInitPage(toShowPage);
          }
          // added this to match path in parentTrackList
          if (toShowPage !== "/") {
            toShowPage = "/" + toShowPage;
          }
          // added this to address /path/projectId
          if (projectId) {
            toShowPage = toShowPage.replace(`/${projectId}`, ``);
          }

          let parentToFind = toShowPage.toLowerCase();

          if (toShowPage === "/mission-control") {
            parentToFind = "/";
          }
          const currentPage = mutatedParent.find(
            (i) => i.path === parentToFind,
          );

          if (!currentPage) {
            window.location.replace(`${window?.location?.origin}/not-found`);
          }

          const updatedParent = mutatedParent.map((i) => {
            const isActive = !!(i.path === parentToFind);
            return {
              ...i,
              isActive,
            };
          });
          setParent(updatedParent);
          if (currentPage) {
            currentPage && setStartPage(currentPage);
            // setFn({
            //   execute: () =>
            //     client().query({
            //       query: getParentTrackDetail(currentPage.id, ''),
            //     }),
            // });
            fetchProjects(currentPage);
            // TODO - refactor next time
            if (currentPage.title.toLowerCase() !== "launchpad") {
              setLoaded(true);
            }
          }
        }
      })
      .catch((e) => {
        setErrorMessage("Error in fetching tracks");
        setLoaded(true);
        setError(true);
        console.error(e);
      });
  };

  useEffect(() => {
    fetchParentTrack();
  }, []);

  useEffect(() => {
    fetchParentTrack();
  }, [pathname]);

  useLayoutEffect(() => {
    const colorTheme = get(startPage, "cssConfig", null);
    colorTheme && setTheme(colorTheme);
    const mainColor = get(colorTheme, "main", "#276EF1");
    const updatedTheme = createTheme({
      palette: {
        primary: {
          main: isHexColor(mainColor) ? mainColor : "#276EF1",
        },
      },
    });
    setAppTheme(updatedTheme);
  }, [startPage]);

  let renderPage = null;

  if (error) {
    renderPage = (
      <ThemeProvider theme={appTheme}>
        <Layout>
          <div>
            <Header />
          </div>
          <div>
            <SomethingWentWrong message={errorMessage} link={true} />
          </div>
        </Layout>
      </ThemeProvider>
    );
    return renderPage;
  }

  if (!loaded) {
    renderPage = (
      <div style={{ marginTop: 50 }}>
        <Loading size="5rem" />
      </div>
    );
    return renderPage;
  }

  const isLaunchpad = initPage === "launchpad";
  const hasProject = Boolean(project);
  const isZeroState = isLaunchpad && !hasProject;
  const isTrackView = startPage?.renderMode === "flat";

  return (
    <ThemeProvider theme={appTheme}>
      <Layout>
        <div>
          <Header />
        </div>
        <div>
          {isZeroState ? (
            <ZeroState />
          ) : Boolean(startPage) ? (
            <div>
              <BannerHeader
                isLaunchpad={
                  initPage && initPage.toLowerCase().includes("launchpad")
                }
                startPage={startPage}
              />
              {!isEmpty(project) && startPage?.projectLevelView && (
                <ProjectCard item={project} id={projectid} />
              )}
              {startPage?.zoneLevelView && (
                <>
                  {!isTrackView && (
                    <Zones
                      projectId={project?.id}
                      tracks={parentTracks}
                      data={startPage}
                      fn={fn}
                    />
                  )}
                  {isTrackView && (
                    <TrackView
                      projectId={project?.id}
                      parentTracks={parentTracks}
                    />
                  )}
                </>
              )}
            </div>
          ) : null}
        </div>
      </Layout>
    </ThemeProvider>
  );
};

const mapStateToProps = createStructuredSelector({
  theme: selectTheme,
  parent: selectParentTrack,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearParent,
      setParent,
      clearTheme,
      setTheme,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
