import { takeLatest, all, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { REQUEST_STATUS } from '../../utility/constants';

import {
  setProjectData,
  createProject,
  updateProject,
  setOperationStatus,
  sendCollaboratorInvites,
  setProjectCollaborators,
  fetchCollaborators,
  setSoloWorkoutData,
  setPeerExchangeData,
  setProjectNonRegisteredUsers,
} from '../actions/projectActions';

import { createProjectMutation, updateProjectMutation } from '../../api/Projects';
import { updateSoloWorkout, updatePeerExchange } from '../../api/SubStops'
import { inviteCollaborators, getCollaborators } from '../../api/Collaborator';

function* doSetProject(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'projectData'));
  try {
    const result = yield apolloClient.mutate({
      mutation: createProjectMutation(payload),
    });

    if (Object.keys(get(result, 'data.createProject.errors', {})).length) {
      yield put(
        setOperationStatus(
          REQUEST_STATUS.ERROR,
          'projectData',
          get(result, 'data.createProject.errors')
        )
      );
    } else {
      yield put(setOperationStatus(REQUEST_STATUS.DONE, 'projectData'));
      yield put(setProjectData(get(result, 'data.createProject.project')));
    }
  } catch (e) {
    console.log(e);
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'projectData', {
        EXCEPTION: e,
      })
    );
  }
}

function* doUpdateProject(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'projectData'));
  try {
    const result = yield apolloClient.mutate({
      mutation: updateProjectMutation(payload),
    });

    if (Object.keys(get(result, 'data.updateProject.errors', {})).length) {
      yield put(
        setOperationStatus(
          REQUEST_STATUS.ERROR,
          'projectData',
          get(result, 'data.updateProject.errors')
        )
      );
    } else {
      yield put(setOperationStatus(REQUEST_STATUS.DONE, 'projectData'));
      yield put(setProjectData(get(result, 'data.updateProject.project')));
    }
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'projectData', {
        EXCEPTION: e,
      })
    );
  }
}

function* doSetSoloWorkout(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'soloWorkoutData'));

  try {
    const result = yield apolloClient.mutate({
      mutation: updateSoloWorkout(payload),
    });
    console.log(result)
  } catch (e) {
    console.log(e);
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'soloWorkoutData', {
        EXCEPTION: e,
      })
    );
  }
}

function* doSetPeerExchange(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'peerExchangeData'));
  try {
    const result = yield apolloClient.mutate({
      mutation: updatePeerExchange(payload),
    });
    console.log(result)
  } catch (e) {
    console.log(e);
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'peerExchangeData', {
        EXCEPTION: e,
      })
    );
  }
}

function* doSendCollaboratorInvites(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'inviteCollaborators'));

  try {
    const result = yield apolloClient.query({
      query: inviteCollaborators(payload.projectId, payload.emails),
    });
    yield put(setProjectNonRegisteredUsers(get(result, 'data.inviteCollabs.nonRegisteredUsers', [])));
    yield put(fetchCollaborators(payload.projectId));
    
    yield put(setOperationStatus(REQUEST_STATUS.DONE, 'inviteCollaborators'));
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'inviteCollaborators', { e })
    );
  }
}

function* doFetchCollaborators(apolloClient, { payload }) {
  yield put(setOperationStatus(REQUEST_STATUS.PENDING, 'inviteCollaborators'));
  try {
    const updatedCollaborators = yield apolloClient.query({
      query: getCollaborators(payload.projectId),
    });

    const collabs = get(updatedCollaborators, 'data.fetchCollabs', []);
    yield put(setProjectCollaborators(collabs));
    yield put(setOperationStatus(REQUEST_STATUS.DONE, 'inviteCollaborators'));
  } catch (e) {
    yield put(
      setOperationStatus(REQUEST_STATUS.ERROR, 'inviteCollaborators', { e })
    );
  }
}

export default function* projectSaga(apolloClient) {
  yield all([
    takeLatest(createProject, doSetProject, apolloClient),
    takeLatest(updateProject, doUpdateProject, apolloClient),
    takeLatest(
      sendCollaboratorInvites,
      doSendCollaboratorInvites,
      apolloClient
    ),
    takeLatest(fetchCollaborators, doFetchCollaborators, apolloClient),
    takeLatest(setSoloWorkoutData, doSetSoloWorkout, apolloClient),
    takeLatest(setPeerExchangeData, doSetPeerExchange, apolloClient)
  ]);
}
