import React, { useState } from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import Launch from '@material-ui/icons/Launch';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import classes from './selectionControlsComponent.module.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
}));

const SelectionControlsComponent = () => {
  const [value, setValue] = useState('female');
  const classesCheckbox = useStyles();
  const [stateCheckbox, setStateCheckbox] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const [switchstate, setSwitchState] = useState({
    checkedA: true,
    checkedB: true,
  });


  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { gilad, jason, antoine } = stateCheckbox;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  return (
    <div className={classes.selectionControlsPage}>
      <div className={classes.selectionControlsPage__title}>
        <h1>Selection Controls Style Guide</h1>
        <p className={classes.styleInstruction}>For usage, kindly refer to the Material UI <a href="https://material-ui.com/components/progress/" target="_blank">Selection Controls <Launch /></a></p>
      </div>
      <div className={classes.selectionControlsPage__container}>

        <div className={classes.selectionControlsPage__radioButtons}>
          <h2>Radio Buttons</h2>
          <div className={classes.selectionControlsPage__radioButtons__section1}>
            <div className={classes.radio__set1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeRadio}>
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                  <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.radio__set2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeRadio}>
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                  <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
                </RadioGroup>
              </FormControl>
            </div>

          </div>
          <div className={classes.selectionControlsPage__radioButtons__section2}>
            <div className={classes.radio__set1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">labelPlacement</FormLabel>
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value="top"
                    control={<Radio color="primary" />}
                    label="Top"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label="Start"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="bottom"
                    control={<Radio color="primary" />}
                    label="Bottom"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel value="end" control={<Radio color="primary" />} label="End" />
                </RadioGroup>
              </FormControl>
              <div className={classes.radio__set2}>
                <FormGroup row>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChangeCheck}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Primary"
                  />
                  <FormControlLabel control={<Checkbox name="checkedC" />} label="Uncontrolled" />
                  <FormControlLabel disabled control={<Checkbox name="checkedD" />} label="Disabled" />
                  <FormControlLabel disabled control={<Checkbox checked name="checkedE" />} label="Disabled" />
                  <FormControlLabel
                    control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />}
                    label="Custom icon"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="checkedI"
                      />
                    }
                    label="Custom size"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedF}
                        onChange={handleChangeCheck}
                        name="checkedF"
                        indeterminate
                      />
                    }
                    label="Indeterminate"
                  />
                </FormGroup>
              </div>
              <div className={classes.radio__set3}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={switchstate.checkedB}
                        onChange={handleChangeSwitch}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Primary"
                  />
                  <FormControlLabel control={<Switch />} label="Uncontrolled" />
                  <FormControlLabel disabled control={<Switch />} label="Disabled" />
                  <FormControlLabel disabled control={<Switch checked />} label="Disabled" />
                </FormGroup>
              </div>

            </div>
          </div>
        </div>

        <div className={classes.selectionControlsPage__checkBoxes}>
          <h2>Check Boxes with FormGroup</h2>
          <div className={classes.selectionControlsPage__checkBoxes__section1}>

            <FormControl component="fieldset" className={classesCheckbox.formControl}>
              <FormLabel component="legend">Assign responsibility</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={gilad} onChange={handleChangeCheck} name="gilad" />}
                  label="Gilad Gray"
                />
                <FormControlLabel
                  control={<Checkbox checked={jason} onChange={handleChangeCheck} name="jason" />}
                  label="Jason Killian"
                />
                <FormControlLabel
                  control={<Checkbox checked={antoine} onChange={handleChangeCheck} name="antoine" />}
                  label="Antoine Llorca"
                />
              </FormGroup>
              <FormHelperText>Be careful</FormHelperText>
            </FormControl>
            <FormControl required error={error} component="fieldset" className={classesCheckbox.formControl}>
              <FormLabel component="legend">Pick two</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={gilad} onChange={handleChangeCheck} name="gilad" />}
                  label="Gilad Gray"
                />
                <FormControlLabel
                  control={<Checkbox checked={jason} onChange={handleChangeCheck} name="jason" />}
                  label="Jason Killian"
                />
                <FormControlLabel
                  control={<Checkbox checked={antoine} onChange={handleChangeCheck} name="antoine" />}
                  label="Antoine Llorca"
                />
              </FormGroup>
              <FormHelperText>You can display an error</FormHelperText>
            </FormControl>
          </div>
          <div className={classes.selectionControlsPage__checkBoxes__section2}>
            <h2>Switches with FormGroup</h2>
            <FormControl component="fieldset">
              <FormLabel component="legend">Assign responsibility</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={stateCheckbox.gilad} onChange={handleChangeSwitch} name="gilad" />}
                  label="Gilad Gray"
                />
                <FormControlLabel
                  control={<Switch checked={stateCheckbox.jason} onChange={handleChangeSwitch} name="jason" />}
                  label="Jason Killian"
                />
                <FormControlLabel
                  control={<Switch checked={stateCheckbox.antoine} onChange={handleChangeSwitch} name="antoine" />}
                  label="Antoine Llorca"
                />
              </FormGroup>
              <FormHelperText>Be careful</FormHelperText>
            </FormControl>
          </div>
        </div>

      </div>
    </div>
  );
};


export default SelectionControlsComponent;
