export const REQUEST_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  DONE: 'DONE',
};

export const HOME_ROUTES = [
  '/launchpad',
  '/launchpad/:projectId',
  '/time-machine/',
  '/time-machine/:projectId',
  '/daily-flights',
  '/daily-flights/:projectId',
  '/peakx-lounge',
  '/peakx-lounge/:projectId',
  '/mission-control',
  '/mission-control/:projectId',
  '/sandbox',
  '/sandbox/:projectId',
  '/dayone',
  '/dayone/:projectId',
  '/agile-sprints',
  '/agile-sprints/:projectId',
  '/open-market',
  '/open-market/:projectId',
  '/gps',
  '/gps/:projectId',
  '/superpower-6',
  '/superpower-6/:projectId',
  '/future-of-work-now',
  '/future-of-work-now/:projectId',
  '/jumpstart',
  '/jumpstart/:projectId',
];
