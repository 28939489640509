import { createReducer } from '@reduxjs/toolkit';
import {
  clearTheme,
  resetOperationStatus,
  setOperationStatus,
  setTheme,
} from '../actions/themeActions';

import { REQUEST_STATUS } from '../../utility/constants';

const initialState = {
  isLoading: false,
  requestStatus: {
    status: REQUEST_STATUS.NOT_STARTED,
    operation: '',
    errors: null,
  },
  data: null,
};

const themeReducer = createReducer(initialState, (builder) => {
  builder.addCase(setTheme, (state, action) => ({
    ...state,
    data: { ...action.payload },
  }));
  builder.addCase(clearTheme, () => initialState);
  builder.addCase(setOperationStatus, (state, action) => ({
    ...state,
    requestStatus: {
      status: action.payload.status,
      operation: action.payload.operation,
      errors: action.payload.errors || null,
    },
  }));
  builder.addCase(resetOperationStatus, (state, action) => ({
    ...state,
    requestStatus: { ...initialState.requestStatus },
  }));
});

export default themeReducer;
