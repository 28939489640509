import React from "react";
import { MemoryRouter, Route } from 'react-router';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import classes from './textColorComponent.module.scss'


const useStyles1 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#595959"
    },
    "& .MuiPaginationItem-root:hover": {
      background: "black"
    },
    "& .Mui-selected": {
      background: "gray"
    },
    "& .Mui-disabled": {
      background: "red"
    }
  }
}));

const useStyles2 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#bcbcbc"
    },
    "& .MuiPaginationItem-root:hover": {
      background: "black"
    },
    "& .Mui-selected": {
      background: "gray"
    },
    "& .Mui-disabled": {
      background: "red"
    }
  }
}));

const useStyles3 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#cbcbcb"
    },
    "& .MuiPaginationItem-root:hover": {
      background: "black"
    },
    "& .Mui-selected": {
      background: "gray"
    },
    "& .Mui-disabled": {
      background: "red"
    }
  }
}));

const TextColorComponent = () => {

  const classes_1 = useStyles1();
  const classes_2 = useStyles2();
  const classes_3 = useStyles3();

  return (
    <div className={classes.textColorPage}>
      <div className={classes.textColorPage__title}>
        <h1>Text Color Guide</h1>
        <p>Used for body texts, this color will be used to go against the background color</p>
        <p className={classes.styleInstruction}>For usage, kindly check the Styles Usage Section</p>
      </div>

      <div className={classes.textColorPage__container}>

        <div className={classes.textColorPage__row1}>

          <div className={classes.textColorPage__col1}>
            <div className={classes.textColorPage__box1}></div>
            <h4>Main</h4>
            <p>595959</p>
            <h1>Text Sample</h1>
            <div className={classes.textColorPage__pagination}>
              <Pagination
                classes={{ ul: classes_1.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.textColorPage__col2}>
            <div className={classes.textColorPage__box2}></div>
            <h4>Color</h4>
            <p>BCBCBC</p>
            <h1>Text Sample</h1>
            <div className={classes.textColorPage__pagination}>
              <Pagination
                classes={{ ul: classes_2.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.textColorPage__col3}>
            <div className={classes.textColorPage__box3}></div>
            <h4>Color</h4>
            <p>CBCBCB</p>
            <h1>Text Sample</h1>
            <div className={classes.textColorPage__pagination}>
              <Pagination
                classes={{ ul: classes_3.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

        </div>

      </div>


    </div>
  );
};

export default TextColorComponent;
