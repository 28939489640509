import { createSelector } from 'reselect';
import { get } from 'lodash';
import { REQUEST_STATUS } from '../../utility/constants';

export const selectThemeSubstate = (state) => ({ ...get(state, 'theme') });

export const selectTheme = createSelector(
  [selectThemeSubstate],
  (substate) => ({ ...get(substate, 'data') })
);

export const selectRequestStatus = (operation) =>
  createSelector([selectThemeSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus')
      : {}
  );

export const selectIsLoading = (operation) =>
  createSelector([selectThemeSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.status') === REQUEST_STATUS.PENDING
      : false
  );

export const selectOperationErrors = (operation) =>
  createSelector([selectThemeSubstate], (substate) =>
    get(substate, 'requestStatus.operation') === operation
      ? get(substate, 'requestStatus.errors')
      : null
  );

