import React from 'react';
import PropTypes from 'prop-types';
import ExternalResource from './ExternalResource';
import TepResource from './TepResource';
import WorkoutResource from './WorkoutResource';
import ImageResource from './ImageResource';

function HurdleResource({ type, data, parentData }) {
  switch (type) {
    case 'external_link':
    case 'must_read':
      return <ExternalResource resourceData={data} parentData={parentData} />;
    case 'image':
      return <ImageResource resourceData={data} parentData={parentData} />;
    case 'tep':
      return <TepResource resourceData={data} parentData={parentData} />;
    case 'workout':
      return <WorkoutResource resourceData={data} parentData={parentData} />;
    case 'google_sheet':
      return <ExternalResource resourceData={data} parentData={parentData} />;
    default:
      return null;
  }
}

HurdleResource.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  parentData: PropTypes.object,
};

export default HurdleResource;
