import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import Toaster from '../../../components/Toaster/Toaster';
import { BackLink } from '../../../components/Links';
import Modal from '../../../components/Modal/Modal';
import CustomButton from '../../../components/Button/CustomButton';

import LockIcon from '../../../assets/images/lock.png';
import './ForgotPassword.scss';

import {
  TextField,
  Button,
  CircularProgress,
  Fade,
  Backdrop,
} from '@material-ui/core';

function ForgotPasswordComponent({
  isVisible,
  onClose,
  onSubmit,
  formErrors,
  isLoading,
  shouldShowSuccessToaster,
}) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const headingText = 'Forgot Password?';

  const handleSubmit = (e) => {
    onSubmit({ email });
    e.preventDefault();
  };

  return (
    <div>
      {/* {shouldShowSuccessToaster && (
        <Toaster
          className="success-toaster"
          message="Password successfully sent to your email."
          autoCloseDuration={5000}
          displayCloseButton
          onClose={onClose}
        />
      )} */}
      <form className="forgot-pw-form-wrapper" onSubmit={handleSubmit}>
        <div className="forgot-pw-title">
          <BackLink to="/login">
            FORGOT PASSWORD
          </BackLink>
        </div>
        <div className="form-row">
          <TextField
            value={email}
            error={!!get(formErrors, 'email')}
            helperText={get(formErrors, 'email')}
            id="signup-email-input"
            type="email"
            variant="outlined"
            label="Email address"
            disabled={isLoading}
            placeholder="email@email.com"
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </div>

        <div>
          {isLoading && <CircularProgress />}
        </div>

        <div className="form-row">
          {/* <CustomButton
            className="primary"
            type="submit"
            disabled={isLoading}
            fullWidth
          >
            Send My Password
          </CustomButton> */}
          <Button
            fullWidth
            type="submit"
            disabled={isLoading}
            variant="contained"
            startIcon={<img src={LockIcon} />}
            size="large"
            style={{
              background: '#276EF1',
              color: '#fff',
              borderRadius: '8px',
              marginTop: '32px',
              boxShadow: 'none',
              height: '56px',
              textTransform: 'none'
            }}
          >
            Send Email Instructions
          </Button>
        </div>

        {shouldShowSuccessToaster && (<div className="desc">
          Password reset instructions have been successfully sent to your email.
        </div>)}

      </form>
    </div>
  );
}

ForgotPasswordComponent.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  formErrors: PropTypes.object,
  isLoading: PropTypes.bool,
  shouldShowSuccessToaster: PropTypes.bool,
};

export default ForgotPasswordComponent;
