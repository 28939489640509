import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import Toaster from '../../../components/Toaster/Toaster';
import Modal from '../../../components/Modal/Modal';
import CustomButton from '../../../components/Button/CustomButton';
import PasswordInput from '../../../components/Textbox/PasswordInput';
import LockIcon from '../../../assets/images/lock.png';

function ResetPasswordComponent({
  isVisible,
  onClose,
  formErrors,
  isLoading,
  shouldShowSuccessToaster,
  onSubmit,
}) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const headingText = 'RESET PASSWORD';

  useEffect(() => {
    shouldShowSuccessToaster = false;
  }, []);

  const handleSubmit = (e) => {
    onSubmit(password, confirmPassword);
    e.preventDefault();
  };

  return (
    <div>
      {/* {shouldShowSuccessToaster && (
        <Toaster
          className="success-toaster"
          message="Password successfully reset."
          autoCloseDuration={5000}
          displayCloseButton
          onClose={onClose}
        />
      )} */}

      <form className="reset-pw-form-wrapper" onSubmit={handleSubmit}>
        <div className="reset-pw-title">{headingText}</div>

        <div className="form-row">
          <PasswordInput
            value={password}
            error={!!get(formErrors, 'password')}
            helperText={get(formErrors, 'password')}
            id="password-input"
            variant="outlined"
            label="Password"
            disabled={isLoading}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </div>

        <div className="form-row">
          <PasswordInput
            value={confirmPassword}
            error={!!get(formErrors, 'passwordConfirmation')}
            helperText={get(formErrors, 'passwordConfirmation')}
            id="confirm-password-input"
            variant="outlined"
            label="Confirm Password"
            disabled={isLoading}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
          />
        </div>

        {!isEmpty(formErrors) && 
          (<div className="form-row text-red">
            <span>{formErrors.EXCEPTION}</span>
          </div>)
        }

        <div className="form-row">
          {/* <CustomButton
            className="primary"
            type="submit"
            disabled={isLoading}
            fullWidth
          >
            Next
          </CustomButton> */}
          <Button
            fullWidth
            type="submit"
            disabled={isLoading}
            variant="contained"
            startIcon={<img src={LockIcon} />}
            size="large"
            style={{
              background: '#276EF1',
              color: '#fff',
              borderRadius: '8px',
              marginTop: '16px',
              boxShadow: 'none',
              height: '56px',
              textTransform: 'none'
            }}
          >
            Reset Password
          </Button>
        </div>

        {shouldShowSuccessToaster && <div className="desc">
          <div>Password has been successfully reset.</div>
          <div style={{ marginTop: '16px' }}>Sending you back to login page...</div>
        </div>}

        <div className="loading-wrapper">
          {isLoading && <CircularProgress />}
        </div>
      </form>
    </div>
  );
}

ResetPasswordComponent.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  formErrors: PropTypes.object,
  isLoading: PropTypes.bool,
  shouldShowSuccessToaster: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ResetPasswordComponent;
