import React from 'react';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Cluster from './Cluster/Cluster';
import Question from './Question/Question';
import QuestionTypes from './QuestionTypes/QuestionTypes';
import { TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Section = ({ keyId, sortedQuestions, sortedAnswers, fn, notesFn }) => {
  const clusters = get(sortedQuestions, 'clusters', []);

  const clusterArea = (cluster, sortedAnswers, fn, uid) => {
    const questions = get(cluster, 'questions', []);
    let area = null;

    if (cluster) {
      area = questions.map((question, index) => {
        return (
          <div key={uid} style={{ paddingBottom: 20 }}>
            <div style={{ paddingBottom: 20 }}>
              <Question
                sortedQuestions={question}
                sortedAnswers={sortedAnswers}
                fn={fn}
              />
            </div>
            {index !== questions.length - 1 && (
              <div style={{ borderBottom: `2px dashed #BCBCBC` }} />
            )}
          </div>
        );
      });
    }
    return <div>{area}</div>;
  };

  return (
    <div style={{ margin: `0px 16px` }}>
      <div style={{ marginTop: 25 }} />
      {clusters.map((cluster, index) => {
        const ckeyId = `${keyId}cId${cluster?.clusterId}`;
        return (
          <div key={ckeyId}>
            {/* {clusterArea(cluster, sortedAnswers, fn, ())} */}
            <Cluster
              keyId={ckeyId}
              data={cluster}
              sortedAnswers={sortedAnswers}
              fn={fn}
              notesFn={notesFn}
            />
            {index !== clusters.length - 1 && (
              <div
                style={{ borderBottom: `2px dashed #BCBCBC`, marginBottom: 25 }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Section;
