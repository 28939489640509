import React, { useState, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectSnackbar, selectParentTrack } from '../../../../store/selectors';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { get, isEmpty } from 'lodash';
import Layout from '../../../../components/Layout';
import PeerExchangeComponent from './PeerExchangeComponent';
import Loading from '../../../../components/Loading/Loading';
import SomethingWentWrong from '../../../../components/Error/SomethingWentWrong';
import {
    client,
    fetchHurdle,
    fetchSubstop,
    getProjectDetail,
    postProjectDetail,
} from '../../../../api';
import { arrangeQuestions } from '../../../../utility/helpers';

const PeerExchangeContainer = ({
    parent,
}) => {
    let { projectId, zoneId, hurdleId, substopId } = useParams();
    const history = useHistory();
    const parentTrack = parent && parent.find(p => p.isActive);
    const path = get(parentTrack, 'path', '/');
    const parentPath = path === '/' ? '/mission-control' : path;
    const [hurdleName, setHurdleName] = useState('');
    const [hurdleBrow, setHurdleBrow] = useState('');
    const [subStopInfo, setSubStopInfo] = useState({});
    const [questions, setQuestions] = useState([]);
    const [projectDetails, setProjectDetails] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const fetchHurdleDetail = (id, projectid) => {
        setLoaded(false);
        client()
            .query({
                query: fetchHurdle(id, projectid),
            })
            .then((result) => {
                if (get(result, 'networkStatus', 7) === 8) {
                    setErrorMessage('Error in fetching hurdle');
                    setLoaded(true);
                    setError(true);
                    return;
                }
                const hurdle = get(result, 'data.fetchHurdle', null);
                const hurdleName = get(hurdle, 'name', '');
                const hurdleEyebrow = get(hurdle, 'hurdleEyebrow', '');
                setHurdleName(hurdleName);
                setHurdleBrow(hurdleEyebrow);
            })
            .catch((e) => {
                setLoaded(true);
                setError(true);
                console.error(e);
            });
    };

    const fetchProjectDetail = async (projectid) => {
        setLoaded(false);
        await client()
            .query({
                query: getProjectDetail(projectid),
            })
            .then((result) => {
                if (get(result, 'networkStatus', 7) === 8) {
                    setErrorMessage('Error in fetching hurdle');
                    setLoaded(true);
                    setError(true);
                    return;
                }
                const details = get(result, 'data.fetchProjectDetail', null);
                if (details) {
                    setProjectDetails(details);

                }
            })
            .catch((e) => {
                setLoaded(true);
                const errorResult = JSON.parse(JSON.stringify(e));
                const message = get(errorResult, 'message', '');
                if (message === 'Project detail does not exists') {
                    setProjectDetails(null);
                    setError(false);
                } else {
                    setError(true);
                    console.error(e);
                }
            });
    };

    useLayoutEffect(() => {
        fetchProjectDetail(projectId);
        fetchHurdleDetail(hurdleId, projectId);
    }, []);

    // Hack: I removed the setLoaded true in fetchHurdle and added it here to make sure everything renders first before
    // removing the loading screen
    useLayoutEffect(() => {
        setLoaded(true);
    }, [questions]);

    // TODO: Add data fetching for submitted answers
    useLayoutEffect(() => {
        if (!isEmpty(projectDetails)) {
            const hurdleZones = get(projectDetails, 'answer.hurdle_zones', []);
            const currentZone = hurdleZones.filter(i => i.id === zoneId);
            const hurdles = get(currentZone, 'hurdles', []);
        }
    }, [projectDetails]);

    let peerExchangeArea = null;

    if (error) {
        peerExchangeArea = (
            <Layout>
                <SomethingWentWrong message={errorMessage} />
            </Layout>
        );
        return peerExchangeArea;
    }

    peerExchangeArea = (
        <PeerExchangeComponent
            projectId={projectId}
            zoneId={zoneId}
            hurdleId={hurdleId}
            substopId={substopId}
            hurdleName={hurdleName}
            hurdleBrow={hurdleBrow}
        />
    )

    return (
        <Layout>
            {peerExchangeArea}
        </Layout>
    )
}

const mapStateToProps = createStructuredSelector({
    parent: selectParentTrack,
});

export default connect(mapStateToProps, null)(PeerExchangeContainer)
