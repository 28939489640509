import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes/routes';

import './App.scss';

const igniteTheme = createTheme({
  palette: {
    primary: {
      main: '#276EF1',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={igniteTheme}>
      <div className="App">
        <Routes />
      </div>
    </ThemeProvider>
  );
}

export default App;
