import React, { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import CloseButton from '../Button/CloseButton';
import './toaster.scss';

function Toaster({
  message,
  className,
  onClose,
  autoCloseDuration,
  displayCloseButton,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const doClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div className={['toaster-wrapper', className].join(' ')}>
      <Fade
        in={isVisible}
        timeout={500}
        exit
        unmountOnExit
        onEntered={() => {
          if (autoCloseDuration) {
            setTimeout(() => {
              doClose();
            }, autoCloseDuration);
          }
        }}
      >
        <div className="toaster-inner">
          <div className="message">{message}</div>
          {displayCloseButton && (
            <CloseButton
              className="toaster-close-btn"
              onClick={() => doClose()}
            />
          )}
        </div>
      </Fade>
    </div>
  );
}

Toaster.defaultProps = {
  className: '',
};

Toaster.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  autoCloseDuration: PropTypes.number,
  displayCloseButton: PropTypes.bool,
};

export default Toaster;
