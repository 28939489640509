import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '../../components/Layout';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GlossaryHeader from './GlossaryHeader';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  wrapper: {
    height: '100vh',
    maxWidth: '640px',
    backgroundColor: '#FFF',
    position: 'fixed',
    zIndex: '9999',
    margin: 'auto auto',
    textAlign: 'left',

    '& h1': {
      fontWeight: '600',
      fontSize: '26px',
      color: '#595959',
      margin: '24px 16px'
    },
  },
  body: {
    backgroundColor: 'white',
    padding: '16px',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 280px)',
    fontSize: '14px',
    color: '#595959',
  },
});
export default function GlossaryComponent({ header, content, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  
  return (
    <div className={classes.wrapper}>
      <Header icon={<CloseIcon />}
          style={{ borderBottom: 'none', background: '#FFF' }}
          rightFn={onClose}
      ></Header>
      <h1>{header}</h1>
      <div
        className={classes.body}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

GlossaryComponent.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
};
