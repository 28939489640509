import React from "react";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import classes from './auxilliaryColorComponent.module.scss';

const useStyles1 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#43a047"
    },
    "& .Mui-selected": {
      background: "gray"
    }
  }
}));

const useStyles2 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa000"
    },
    "& .Mui-selected": {
      background: "gray"
    }
  }
}));

const useStyles3 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#b00020"
    },
    "& .Mui-selected": {
      background: "gray"
    }
  }
}));

const useStyles4 = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#1976d2"
    },
    "& .Mui-selected": {
      background: "gray"
    }
  }
}));

const AuxilliaryColorComponent = () => {
  const classes_1 = useStyles1();
  const classes_2 = useStyles2();
  const classes_3 = useStyles3();
  const classes_4 = useStyles4();
  return (
    <div className={classes.auxColorPage}>
      <div className={classes.auxColorPage__title}>
        <h1>Auxilliary Color Guide</h1>
        <p>For errors and alerts like success, warning, info, and danger</p>
        <p className={classes.styleInstruction}>For usage, kindly check the Styles Usage Section</p>
      </div>

      <div className={classes.auxColorPage__container}>

        <div className={classes.auxColorPage__row1}>

          <div className={classes.auxColorPage__ro1col1}>
            <div className={classes.auxColorPage__box1}><h1>Success</h1></div>
            <h4>Success</h4>
            <p>43A047</p>
            <div className='page'>
              <Pagination
                classes={{ ul: classes_1.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.auxColorPage__row1col2}>
            <div className={classes.auxColorPage__box2}><h1>Warning</h1></div>
            <h4>Warning</h4>
            <p>FFA000</p>
            <div className='page'>
              <Pagination
                classes={{ ul: classes_2.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

        </div>

        <div className={classes.auxColorPage__row2}>

          <div className={classes.auxColorPage__row2col1}>
            <div className={classes.auxColorPage__box3}><h1>Error</h1></div>
            <h4>Error</h4>
            <p>B00020</p>
            <div className='page'>
              <Pagination
                classes={{ ul: classes_3.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.auxColorPage__row2col2}>
            <div className={classes.auxColorPage__box4}><h1>Info</h1></div>
            <h4>Info</h4>
            <p>1976D2</p>
            <div className='page'>
              <Pagination
                classes={{ ul: classes_4.ul }}
                count={3}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                  />
                )}
              />
            </div>
          </div>

        </div>

      </div>
    </div >
  );
};

export default AuxilliaryColorComponent;
