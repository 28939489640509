import { createAction } from '@reduxjs/toolkit';

export const setUserData = createAction('user/set_user_data');
export const clear = createAction('user/clear');
export const fetchUserProfile = createAction('user/fetch_user_profile');
export const updateUserProfile = createAction('user/update_user_profile');

export const setUserProfile = createAction(
  'user/set_user_profile',
  (profile) => ({
    payload: {
      profile,
    },
  })
);
export const login = createAction('user/login', (email, password) => {
  return {
    payload: { email, password },
  };
});

export const logout = createAction('user/logout');
export const signup = createAction('user/signup', (userData, agreeToTerms) => ({
  payload: {
    userData,
    agreeToTerms,
  },
}));

export const forgotPassword = createAction('user/forgotPassword');
export const resetPassword = createAction(
  'user/resetPassword',
  (password, passwordConfirmation, resetPasswordToken, setCookie) => ({
    payload: {
      password,
      passwordConfirmation,
      resetPasswordToken,
      setCookie,
    },
  })
);

export const setOperationStatus = createAction(
  'user/setOperationStatus',
  (status, operation, errors) => ({
    payload: {
      status,
      operation,
      errors,
    },
  })
);

export const signupWithFacebook = createAction(
  'user/signupWithFacebook',
  (fbData) => ({
    payload: { ...fbData },
  })
);

export const resetOperationStatus = createAction('user/resetOperationStatus');
