import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PremiumLockIcon from '../../../assets/images/premium-lock.png';
import PremiumPlusIcon from '../../../assets/images/premium-plus.png';
import { Button, IconButton } from '@material-ui/core';
import { LinearProgress } from '../../../components/LinearProgress';

import { selectTheme, selectParentTrack } from '../../../store/selectors';

import classes from './hurdleSet.module.scss';

const subscriptionLevel = (subscription) => {
  switch (subscription) {
    case 'premium': {
      return 1; 
    }
    case 'premium_plus': {
      return 2;
    }
    default: {
      return 0;
    }
  }
}

const HurdleSet = ({ theme, parent, projectId, data, subscription }) => {
  const [details, setDetails] = useState(data);
  const history = useHistory();
  const parentTrack = parent && parent.find((p) => p.isActive);
  const path = get(parentTrack, 'path', '/');
  const parentPath = path === '/' ? '/mission-control' : path;
  let hurdleSetArea = null;
  const level = subscriptionLevel(subscription);

  const navigateHandler = (item) => {
    const goURL = get(item, 'goUrl', '');
    if (!projectId) {
      return;
    }
    if (goURL) {
      if (goURL.startsWith('/')) {
        history.push(get(item, 'goUrl', ''));
      } else {
        window.open(goURL, '_blank');
      }
    } else {
      const hurdleString = JSON.stringify(item);
      localStorage.setItem('hurdleSet', hurdleString)
      history.push(
        `${parentPath}/${projectId}/zones/${item.hurdleZoneId}/hurdles/${item.id}`
      );
    }
  }

  useEffect(() => {
    const updatedDetails = details?.map((x) => {
      return {
        ...x,
        preview: false,
      };
    });
    setDetails(updatedDetails);
  }, []);

  const renderTier = (item) => {
    const tier = get(item, 'tierType', 'free');
    const buttonArea = (
      <Button
        variant="contained"
        startIcon={<ArrowForwardIcon />}
        size="small"
        style={{
          background: '#276EF1',
          color: '#fff',
          borderRadius: '8px',
          margin: '5px 0',
          boxShadow: 'none'
        }}
        onClick={(e) => {
          e.stopPropagation();
          navigateHandler(item);
        }}
      >
        GO
      </Button>
    );

    switch (tier) {
      case 'premium': {
        return level > 0
          ? buttonArea
          : (
            <div className={classes.premiumLock} onClick={e => {
              e.stopPropagation();
              history.push('/paywall');
            }}>
              <img src={PremiumLockIcon} />
            </div>
          );
      }
      case 'premium_plus': {
        return level > 1
          ? buttonArea
          : (
            <div className={classes.premiumPlus}>
              <img src={PremiumPlusIcon} style={{ marginRight: '5px' }} />Premium+
            </div>
          );
      }
      default: {
        return buttonArea;
      }
    }
  }

  const preview = (id) => {
    const updatedDetails = details?.map((x) => {
      let preview = x.preview;
      if (x.id === id) {
        preview = !x.preview;
      }
      return {
        ...x,
        preview,
      };
    });
    setDetails(updatedDetails);
  };

  const statusItem = (stats) => {
    switch (stats) {
      case 'Done': {
        return {
          proceedingText: 'Review',
          statusColor: '#689AF5',
          statusIcon: (
            <div style={{ fontSize: 17, paddingRight: 5 }}>
              <CheckCircleOutlineRoundedIcon fontSize="inherit" />
            </div>
          ),
        };
      }
      case 'Ongoing': {
        return {
          proceedingText: 'Continue',
          statusColor: '#FFA000',
          statusIcon: (
            <div style={{ fontSize: 17, paddingRight: 5 }}>
              <RemoveCircleOutlineIcon fontSize="inherit" />
            </div>
          ),
        };
      }
      default: {
        return {
          proceedingText: 'Get Started',
          statusColor: '#FFFFFF',
          statusIcon: <div></div>,
        };
      }
    }
  };

  hurdleSetArea = details?.map((item, index) => {
    const proceedingLabel =
      get(item, 'goUrlButtonText', '') ||
      get(statusItem(item?.projectHurdleStatus), 'proceedingText', null);

    return (
      <div className={classes.hsContainer} key={item.id}>
        <div
          className={classes.hsDetails}
          id={`hurdle__${item.id}`}
          onClick={(e) => {
            e.stopPropagation();
            preview(item.id);
          }}
        >
          <div
            className={classes.hsSection}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ width: '100%' }}>
              <div className={classes.hsHurdleCount}>
                {get(item, 'hurdleEyebrow', '')}
              </div>
              <h3
                style={{
                  color: `#595959`,
                  marginBottom: '16px'
                }}
              >
                {item.name}
              </h3>
              {item?.preview && <div className={[classes.hsOverflow, (item?.caption ? '' : classes.hsOverflowAuto)].join(' ')} >
                <p dangerouslySetInnerHTML={{ __html: item?.caption }}></p>
              </div>}
            </div>
            {item.totalPoints !== 0 && <div className={classes.scoreSection}>
              <LinearProgress
                earnedPoints={get(item, 'earnedPoints', 0) || 0}
                totalPoints={get(item, 'totalPoints', 0) || 100}
                scoringRate={get(item, 'scoringRate', 0) || 2}
                bypassSuffix={get(item, 'bypassSuffix', '')}
                subText="CREDS"
                color="#276EF1"
                bgColor="#E4E4E4"
              />
            </div>}
            <div
              className={classes.hsSection}
              style={{
                padding: '5px 0',
                // borderTop: '1px solid #e4e4e4'
              }}
            >
              {item?.caption !== '' ? <div style={{ color: '#689AF5', display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                <VisibilityIcon style={{ marginRight: '10px' }} /> Preview
              </div> : <div></div>}
              <div style={{ fontSize: '13px' }}>
                {renderTier(item)}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  });

  return <div className={classes.hsPageWrapper}>{hurdleSetArea}</div>;
};

const mapStateToProps = createStructuredSelector({
  theme: selectTheme,
  parent: selectParentTrack,
});

export default connect(mapStateToProps)(HurdleSet);
