import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../components/Button/CustomButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Button } from '@material-ui/core';

import classes from './HurdleResource.module.scss';

function TepResource({ resourceData }) {
  const { copyTextArea, name, tepUrl } = resourceData;

  return (
    <div className={classes.tep}>
      <div className={classes.tep__header}>
        <h1>{name}</h1>
        {/* <div className={classes.chevron__forward}>
          <a href={tepUrl} target="_blank">
            <ion-icon name="chevron-forward-sharp" />
          </a>
        </div> */}
      </div>

      <div className={classes.tep__content}>
        <div
          className={classes.tep__copyFromApi}
          dangerouslySetInnerHTML={{ __html: copyTextArea }}
        />
        <div className={classes.tep__button}>
          <a href={tepUrl} className={classes.ctaButton} target="_blank">
            {/* <CustomButton color="primary">Get Started</CustomButton> */}
            <Button
              variant="contained"
              startIcon={<OpenInNewIcon />}
              size="small"
              style={{
                background: '#276EF1',
                color: '#fff',
                borderRadius: '8px',
                margin: '5px 0',
                boxShadow: 'none'
              }}
            >
              GO
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default TepResource;
